<div class="total-table">
  <div class="table single">
    <div class="table-segment">
      <div class="row gbp">
        <div class="property">Vehicle Purchase Price</div>
        <div class="value">
          @if (editingPrice && fcfData) {
            <mat-form-field>
              <input matInput
                     type="number"
                     step="50"
                     min="0"
                     [(ngModel)]="fcfData.priceGBP">
              <mat-hint>GBP, incl VAT.</mat-hint>
              <button mat-icon-button
                      matSuffix
                      color="primary"
                      (click)="toggleEditPrice()"
                      matTooltip="Done editing the price">
                <mat-icon>check</mat-icon>
              </button>
            </mat-form-field>

          } @else {
            {{ fcfData?.priceGBP | currency: "GBP":"symbol":"1.0-0" : "en" }}
            <mat-icon color="primary"
                      (click)="toggleEditPrice()"
                      matTooltip="Modify purchase price. Updates customs and VAT.">
              edit
            </mat-icon>
          }
        </div>
      </div>
      <div class="row gbp">
        <div class="property">Zero Rated Vehicle Purchase Price</div>
        <div class="value">
          @if (editingPriceZero && fcfData) {
            <mat-form-field>
              <input matInput
                     type="number"
                     step="50"
                     min="0"
                     [value]="priceGBPExVat"
                     (change)="updateZeroRatedPrice($event)">
              <mat-hint>GBP, net VAT.</mat-hint>
              <button mat-icon-button
                      matSuffix
                      color="primary"
                      (click)="toggleEditPriceZero()"
                      matTooltip="Done editing the price">
                <mat-icon>check</mat-icon>
              </button>
            </mat-form-field>

          } @else {
            {{ priceGBPExVat | currency: "GBP":"symbol":"1.0-0" : "en" }}
            <mat-icon color="primary"
                      (click)="toggleEditPriceZero()"
                      matTooltip="Modify purchase price. Updates customs and VAT.">
              edit
            </mat-icon>
          }
        </div>
      </div>
      <div class="row gbp">
        <div class="property">Importation Fee</div>
        <div class="value">
          {{ importationFee | currency: "GBP":"symbol":"1.0-0" : "en" }}
          <mat-icon *ngIf="fcfData" color="primary"
                    (click)="editImportFee()"
                    matTooltip="Modify Importation Fee.">
            edit
          </mat-icon>
        </div>
      </div>
      <div class="row gbp">
        <div class="property">Delivery / shipping</div>
        <div class="value">
          {{ shippingGBP | currency: "GBP":"symbol":"1.0-0" : "en" }}
          <mat-icon *ngIf="fcfData" color="primary"
                    (click)="editShipping()"
                    matTooltip="Modify Delivery / Shipping cost.">
            edit
          </mat-icon>
        </div>
      </div>
      <div class="row gbp">
        <div class="property">Total Vehicle Export Price GBP</div>
        <div class="value">{{ totalExportPriceGBP | currency: "GBP":"symbol":"1.0-0" : "en" }}</div>
      </div>
      <div class="row newSection eur">
        <div class="property">Total Vehicle Export Price EUR</div>
        <div
          class="value">{{ totalExportPriceEUR | currency: "EUR":"symbol":"1.0-0" : "en" }}
        </div>
      </div>
      <div class="row eur">
        <div class="property">Custom's duty<ng-container *ngIf="customsRateAboutToChange">*</ng-container> </div>
        <div class="value">{{ customsDuty | currency: "EUR":"symbol":"1.0-0" : "en" }}</div>
      </div>
      <div class="row eur">
        <div class="property">VAT at Import</div>
        <div class="value">{{ importVAT | currency: "EUR":"symbol":"1.0-0" : "en" }}</div>
      </div>
      <div class="row eur">
        <div class="property">Cost of Vehicle, Import Fee, Shipping, Customs & VAT at Import</div>
        <div class="value">{{ costAtImport | currency: "EUR":"symbol":"1.0-0" : "en" }}</div>
      </div>
      <div class="row eur">
        <div class="property">VRT <span *ngIf="fcfData?.omsp_current" class="vrtDetails">(COMSP {{fcfData?.omsp_current | currency: "EUR":"symbol":"1.0-0" : "en" }} at {{fcfData?.unmodifiedVRTAmount | percent: '1.1-1'}} + NOx)</span></div>
        <div class="value">{{ fcfData?.vrtEuro | currency: "EUR":"symbol":"1.0-0" : "en" }}</div>
      </div>
      <div class="row eur">
        <div class="property">House Charge</div>
        <div class="value">
          {{ fcfData?.houseMiscCharge | currency: "EUR":"symbol":"1.0-0" : "en" }}
          <mat-icon *ngIf="fcfData" color="primary"
                    (click)="editHouseCharge()"
                    matTooltip="Edit House Charge">
            edit
          </mat-icon>
        </div>
      </div>
      <div class="row newSection total">
        <div class="property">Total Landed Cost of Car</div>
        <div class="value">
          @if (editingFinalCost && fcfData) {
            <mat-form-field>
              <input matInput
                     type="number"
                     step="50"
                     min="0"
                     [value]="total"
                    (change)="updateTotalCost($event)">
              <mat-hint>EUR, incl VAT</mat-hint>
              <button mat-icon-button
                      matSuffix
                      color="primary"
                      (click)="toggleEditTotalCost()"
                      matTooltip="Done editing the price">
                <mat-icon>check</mat-icon>
              </button>
              <button mat-icon-button
                      matSuffix
                      color="primary"
                      (click)="resetTotalCost()"
                      matTooltip="Reset the price">
                <mat-icon>restart_alt</mat-icon>
              </button>
            </mat-form-field>

          } @else {
            {{ total | currency: "EUR":"symbol":"1.0-0" : "en" }}
            <mat-icon color="primary"
                      (click)="toggleEditTotalCost()"
                      matTooltip="Modify total cost.">
              edit
            </mat-icon>
          }
        </div>
      </div>

    </div>
  </div>
  <div class="footnote" *ngIf="customsRateAboutToChange">* The car was first registered in the UK on {{fcfData?.monthOfFirstRegistration | date: 'mediumDate' }}. The qualification for returned goods may expire within the next 6 months.</div>
</div>
