<section class="header">
  <h1>
    <span>An overpriced car with a ‘for sale’ sticker is still an overpriced car.</span>
    <span>You know that.</span>
    <span>We know Forex.</span>
  </h1>
  <h2>
    <span>Stop paying retail Forex rates – </span>
    <span>TheCarSite gives you rates the Banks would die for.</span>
  </h2>
  <h2>Let’s level the playing field and play fair.</h2>
</section>
<section class="hero forexCalc">
  <div class="c2a prodCard">
    <h3>Sign up for Forex For Less now</h3>
    <button mat-raised-button color="primary" class="c2aLarge" (click)="goto('corporate')">Corporate</button>
    <button mat-raised-button color="primary" class="c2aLarge" (click)="goto('individual')">Individual</button>
  </div>
  <div class="c2a login">
    <h3>Login To Your Forex For Less Account</h3>
    <a mat-raised-button color="primary" class="c2aLarge" href="https://tcs.paydirect.io/login"
       target="_blank">Login</a>
  </div>
  <div class="prodCard">
    <h3>Estimate Your Savings on Forex<br> using our simple calculator</h3>
    <div class="calc" [formGroup]="fflForm">
      <div class="tableHead" role="rowheader">
        Purchase currency
      </div>
      <mat-form-field appearance="outline">
        <mat-select #fromCurrency>
          @for (currency of currencyService.currencies; track currency.short) {
            @if (['GBP', 'JPY'].indexOf(currency.three) > -1) {
              <mat-option [value]="currency">{{ currency.symbol }} - {{ currency.three }}</mat-option>
            }
          }
        </mat-select>
      </mat-form-field>


      <div class="tableHead" role="rowheader">
        Average purchase price per car
      </div>
      <mat-form-field appearance="outline">
        @if (fromCurrency.value) {
          <span matTextPrefix>{{ fromCurrency.value.symbol }} &nbsp;</span>
        }
        <input matInput type="number" step="1000" min="0" formControlName="avgPrice" #avgPrice>
      </mat-form-field>


      <div class="tableHead" role="rowheader">
        Average cars purchased per month
      </div>
      <mat-form-field appearance="outline">
        <input matInput type="number" step="1" min="1" formControlName="avgCars" #avgCars>
      </mat-form-field>


      <div class="tableHead" [ngClass]="{hidden: !userService.admin}" role="rowheader">
        Current forex provider
      </div>
      <mat-form-field [ngClass]="{hidden: !userService.admin}" appearance="outline">
        <mat-select #curProvider value="High Street Banks">
          <mat-option value="High Street Banks">High Street Banks</mat-option>
          <mat-option value="Wise">Wise</mat-option>
          <mat-option value="TransferMate">TransferMate</mat-option>
          <mat-option value="Bank of Ireland">Bank of Ireland Global Markets</mat-option>
        </mat-select>
      </mat-form-field>
      <div class="tableHead" [ngClass]="{hidden: !userService.admin}" role="rowheader">
        Date
      </div>
      <mat-form-field [ngClass]="{hidden: !userService.admin}" appearance="outline">
        <!--        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>-->
        <input matInput [matDatepicker]="picker" [formControl]="datePicker" (focus)="picker.open()" [min]="minDate"
               [max]="maxDate" (dateChange)="dateChange($event)">
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>

    </div>
    @if (fromCurrency.value && avgPrice.value && avgCars.value && curProvider.value) {

      <div class="savings" role="table">

        <div class="tableHead" role="rowheader">Forex required per month</div>
        <div role="cell"
             class="cell">{{ avgVolume | currency: fromCurrency.value.three : 'symbol': '1.0-0' : 'en-IE' }}
        </div>

        <div class="tableHead" role="rowheader">Estimated cost using {{ curProvider.value }}</div>
        <div role="cell"
             class="cell">{{ currencyService.convert(fromCurrency.value, currencyService.eur, providerCost(curProvider.value, avgVolume)) | currency: 'EUR' : 'symbol': '1.0-0' : 'en-IE' }}
        </div>

        <div class="tableHead" role="rowheader">Estimated cost using theCarSite.com</div>
        <div role="cell"
             class="cell">{{ currencyService.convert(fromCurrency.value, currencyService.eur, avgVolume * 1.0025) | currency: 'EUR' : 'symbol': '1.0-0' : 'en-IE' }}
        </div>

        <div class="tableHead " role="rowheader">Estimated savings per month using TCS</div>
        <div role="cell"
             class="cell ">{{ currencyService.convert(fromCurrency.value, currencyService.eur, providerCost(curProvider.value, avgVolume) - (avgVolume * 1.0025)) | currency: 'EUR' : 'symbol': '1.0-0' : 'en-IE' }}
        </div>
        <div class="tableHead statement" role="rowheader">Estimated savings per year using TCS</div>
        <div role="cell"
             class="cell statement">{{ currencyService.convert(fromCurrency.value, currencyService.eur, (providerCost(curProvider.value, avgVolume) - (avgVolume) * 1.0025) * 12) | currency: 'EUR' : 'symbol': '1.0-0' : 'en-IE' }}
        </div>


        <div class="footnote"><sup>*</sup>Currency exchange rate
          {{ fromCurrency.value.three }}
          /{{ currencyService.eur.three }} {{ currencyService.getRate(fromCurrency.value) / currencyService.getRate(currencyService.eur) | number: "1.3-3" : "en" }}
          , last
          updated {{ currencyService.rates.ts | date: 'medium' : undefined : 'en-IE' }}
        </div>

      </div>
    }
  </div>

</section>
