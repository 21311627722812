import {Component, Inject, OnInit} from "@angular/core";
import {
  MAT_DIALOG_DATA, MatDialog, MatDialogContent, MatDialogModule,
} from "@angular/material/dialog";
import {MatButtonModule} from "@angular/material/button";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {RouterLink} from "@angular/router";
import {EquivCarsDialog} from "../equiv-cars/equiv-cars";
import {VehicleDataComponent} from "../../tables/vehicle-data/vehicle-data.component";
import {VrtDataComponent} from "../../tables/vrt-data/vrt-data.component";
import {ApiService} from "../../../services/api.service";
import {UserService} from "../../../services/user.service";
import {FcfService, VRT} from "../../../services/fcf.service";
import {MatAccordion, MatExpansionModule} from "@angular/material/expansion";
import {MatMenu, MatMenuItem, MatMenuTrigger} from "@angular/material/menu";
import {ImportationFeeDialog} from "../vat-adjustment-dialog/importation-fee-dialog.component";
import {HouseMiscChargeDialogComponent} from "../house-misc-charge-dialog/house-misc-charge-dialog.component";
import {QuoteComponent} from "../quote/quote.component";
import {FcfEditSalespriceComponent} from "../fcf-edit-salesprice/fcf-edit-salesprice.component";
import { ShippingCostDialogComponent } from "../shipping-cost-dialog/shipping-cost-dialog.component";
import {AdditionalCostsService} from "../../../services/additional-costs.service";
import {MatIcon} from "@angular/material/icon";

@Component({
  selector: "dialog-car-details",
  templateUrl: "./dialog-car-details.html",
  styleUrl: "./dialog-car-details.scss",
  standalone: true,
  imports: [MatDialogContent, MatButtonModule, MatDialogModule, MatTooltipModule, MatSnackBarModule, RouterLink, VehicleDataComponent, VrtDataComponent, MatAccordion, MatExpansionModule, MatMenu, MatMenuTrigger, MatMenuItem, MatIcon,],
})
export class FCFCarDetailsDialog implements OnInit {
  protected readonly Number = Number;
  protected readonly FcfService = FcfService;
  protected largeDisplay = true; // Whether the display is large enough to show expanded

  constructor(@Inject(MAT_DIALOG_DATA) protected data: VRT, private apiService: ApiService, protected dialog: MatDialog, protected userService: UserService, private fcfService: FcfService, protected additionalCostsService: AdditionalCostsService) {
    // console.log(this.data);
    this.largeDisplay = window.innerWidth >= 700;
  }

  async ngOnInit() {
    const detailsResponse = await this.apiService.getDetails("uk", this.data?.id);
    Object.assign(this.data, detailsResponse.data);
  }

  async showEquivs() {
    this.dialog.open(EquivCarsDialog, {
      data: {
        data: this.data,
      },
    });
  }

  async maskEntry(data: any) {
    await this.apiService.maskEntry(data.id);
    data.masked = data.masked ? null : "masked";
    this.dialog.closeAll();
  }


  openQuoteModalDialog(): void {
    // Open the dialog
    this.dialog.open(QuoteComponent, {
      data: {fcfData: this.data}
    });

  }

  protected readonly importationFeeDialog = ImportationFeeDialog;
  protected readonly HouseMiscChargeDialogComponent = HouseMiscChargeDialogComponent;
  protected readonly ShippingCostDialogComponent = ShippingCostDialogComponent;
  protected readonly FcfEditSalespriceComponent = FcfEditSalespriceComponent;
}
