import {Component, Inject, OnInit, PLATFORM_ID} from "@angular/core";
import {isPlatformBrowser} from "@angular/common";
import { MatButton } from "@angular/material/button";
import { MatError, MatFormField, MatLabel } from "@angular/material/form-field";
import { MatInput } from "@angular/material/input";
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { MatCheckbox } from "@angular/material/checkbox";
import { UserService } from "../../services/user.service";
import { User } from "@angular/fire/auth";
import { Router } from "@angular/router";
import { NgxTurnstileModule } from "ngx-turnstile";
import { ApiService } from "../../services/api.service";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: "app-register-form",
  standalone: true,
  imports: [
    MatButton,
    MatFormField,
    MatInput,
    ReactiveFormsModule,
    MatLabel,
    MatError,
    MatCheckbox,
    NgxTurnstileModule,
  ],
  templateUrl: "./register-form.component.html",
  styleUrl: "./register-form.component.scss",
})
export class RegisterFormComponent implements OnInit {
  public registerForm: FormGroup;
  private token: string | null = null;

  constructor(
    private user: UserService,
    private router: Router,
    private apiService: ApiService,
    private snackBar: MatSnackBar,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {
    this.registerForm = new FormGroup({
      email: new FormControl("", [Validators.required, Validators.email]),
      pwd1: new FormControl("", [Validators.required, Validators.minLength(5)]),
      pwd2: new FormControl("", [Validators.required, this.matchPwd()]),
      comms: new FormControl(""),
    });

    this.user.loginEvent.subscribe((user: User) => {
      // The user completed step 1
      console.log("The user completed step 1", user);
      this.router.navigate(["/register-details"]);
    });
  }

  ngOnInit(): void {
    if (this.user.isLoggedIn()) {
      // User is already logged in
      this.router.navigate(["/register-details"]);
    }
  }

  async register(mode: "pwd" | "google" | "ms") {
    // Check valid turnstile
    const r = await this.apiService.checkToken(this.token);
    if (!r.success) {
      this.snackBar.open(r.msg, undefined, {
        panelClass: "snack-error",
        duration: 5000,
        verticalPosition: "top",
      });
      return;
    }
    switch (mode) {
      case "pwd":
        if (this.registerForm.touched && this.registerForm.valid) {
          await this.user.register(
            this.registerForm.get(["email"])?.value,
            this.registerForm.get(["pwd1"])?.value
          );
        }
        break;
      case "google":
        this.user.signinGoogle();
        break;
      case "ms":
        this.user.signinMicrosoft();
        break;
    }
  }

  private matchPwd(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const formGroup = control.parent;
      if (formGroup && formGroup?.get("pwd1")?.value == formGroup?.get("pwd2")?.value) {
        return null;
      }
      return ["Passwords must match"];
    };
  }

  sendCaptchaResponse(token: string | null) {
    this.token = token;
  }
  get isBrowser(): boolean {
   return isPlatformBrowser(this.platformId);
  }
}
