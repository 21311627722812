import {Component, OnInit} from "@angular/core";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatSelectModule} from "@angular/material/select";
import {DatePipe, KeyValuePipe, NgForOf, NgIf} from "@angular/common";
import {ApiResponse, ApiService} from "../../services/api.service";
import {MatOptionModule} from "@angular/material/core";
import { ReactiveFormsModule} from "@angular/forms";
import {MatSlider, MatSliderRangeThumb} from "@angular/material/slider";
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatHeaderCell, MatHeaderCellDef,
  MatHeaderRow, MatHeaderRowDef, MatRow, MatRowDef,
  MatTable,
  MatTableDataSource
} from "@angular/material/table";
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {MatSort, MatSortHeader} from "@angular/material/sort";
import {MatIcon} from "@angular/material/icon";
import {MatDialog} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {FcfService} from "../../services/fcf.service";
import {MatButton} from "@angular/material/button";
import {FormatNotificationsPipe} from "../../pipes/format-notifications.pipe";
import {FcfFiltersComponent} from "../dialogs/fcf-filters/fcf-filters.component";

@Component({
  selector: "app-notifications",
  standalone: true,
  imports: [MatFormFieldModule, MatInputModule, MatSelectModule, MatOptionModule, NgForOf, KeyValuePipe, ReactiveFormsModule, MatSlider, MatSliderRangeThumb, MatTable, DatePipe, MatCell, MatCellDef, MatColumnDef, MatHeaderCell, MatHeaderRow, MatHeaderRowDef, MatProgressSpinner, MatRow, MatRowDef, MatSort, MatSortHeader, NgIf, MatHeaderCellDef, MatIcon, MatButton, FormatNotificationsPipe],
  templateUrl: "./notifications.component.html",
  styleUrl: "./notifications.component.scss"
})
export class NotificationsComponent implements OnInit {
  loaded = false;
  notifications = new MatTableDataSource<any>([]);
  displayedColumns = ["ts","notifications","actions"];
  constructor(private apiService: ApiService, private dialog: MatDialog, private snackBar: MatSnackBar, private fcfService: FcfService) {

  }

  async ngOnInit() {
    const res = <ApiResponse>await this.apiService.getNotifications()
      .catch(e => console.error(e));
    if (res?.success) {
      this.notifications = new MatTableDataSource(res.data);
    }
    this.loaded = true;
  }

  protected edit(element: any) {
    const formGroup = FcfService.initForm();
    const values = JSON.parse(element.notifications);
    if (!values["models"]) {
      values["models"] = [];
    }
    formGroup.patchValue(values);
    const dialog = this.dialog.open(FcfFiltersComponent,{
      data: {
        filtersFormGroup: formGroup,
        mode: "notifications"
      },
      id: "fcf-filters",
    });
    const saveSubscription = dialog.componentInstance.savedNotification.subscribe(() => {
      this.loaded = false;
      this.ngOnInit();
    });
    dialog.afterClosed().subscribe(() => {
      saveSubscription.unsubscribe();
    });
  }

  async delete(element: any) {
    this.loaded = false;
    const res = <ApiResponse> await this.apiService.deleteNotification(element.id);
    if (res?.success) {
      this.snackBar.open("Deleted notification", undefined, {duration: 5000});
    } else {
      this.snackBar.open("Could not delete the notification", undefined, {duration: 5000});
    }
    this.ngOnInit();
  }
}
