import {CommonModule, DecimalPipe} from "@angular/common";
import {Component, Inject, OnInit} from "@angular/core";
import {MatAnchor} from "@angular/material/button";
import {
  MatDialogActions, MatDialog, MAT_DIALOG_DATA, MatDialogTitle, MatDialogContent, MatDialogRef,
} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ApiService} from "../../../services/api.service";
import {CcqTotalsComponent} from "../../tables/ccq-totals/ccq-totals.component";
import {CurrencyService} from "../../../services/currency.service";
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {MatSelectChange} from "@angular/material/select";
import {VehicleData, VehicleDetails} from "../../../pages/home/ccq/ccq.model.consts";
import {VRT} from "../../../services/fcf.service";
import {QuotesService} from "../../../services/quotes.service";

interface QuoteData extends VehicleData {
  total?: number;
  serviceFee?: number;
  currencyRate?: number;
}

@Component({
  selector: "app-quote",
  standalone: true,
  imports: [DecimalPipe, MatAnchor, MatDialogActions, CcqTotalsComponent, MatDialogTitle, MatDialogContent, CommonModule, MatProgressSpinner,],
  templateUrl: "./quote.component.html",
  styleUrl: "./quote.component.scss",
})
export class QuoteComponent implements OnInit {
  public vrtError: string | undefined;
  vrtLoading = true;
  customsLoading = false;
  public customsError: string | undefined;
  public missingVariables: Array<keyof VehicleDetails> = [];
  public registrationNumber: any;

  constructor(protected dialog: MatDialog, private apiService: ApiService, private snackBar: MatSnackBar, protected currency: CurrencyService, @Inject(MAT_DIALOG_DATA) public data: {
    fcfData?: VRT,
    ccqData?: VehicleData,
    currencyRate?: number,
    lessVat?: number,
    priceEur?: number,
    total?: number,
    vat?: number,
    vrtEuro?: number,
  }, protected dialogRef: MatDialogRef<QuoteComponent>,) {

  }

  get isLoading(): boolean {
    return this.vrtLoading || this.customsLoading;
  }

  ngOnInit(): void {
    // console.log("QuoteComponent.ngOnInit", this.vehicleData);
    // Always get fresh customs and VRT
    this.getCustoms().then(() => {
      this.customsLoading = false;
    })
    this.getVrt().catch(error => {
      console.error("VRT calculation error:", error);
    })
  }

  async submitQuote() {
    await this.apiService.submitQuote(this.data.fcfData)
      .catch(error => {
        console.error("Quote submission error:", error);
      })
    this.snackBar.open("Thank you for submititing your request!", undefined, {
      panelClass: "snack-ok", duration: 5000,
    });
    this.dialog.closeAll();
  }

  protected async getCustoms() {
    this.customsLoading = true;
    this.customsError = undefined;
    if (this.data.fcfData) {
      try {
        const response = await this.apiService.getCustoms(this.data.fcfData);
        if (response?.success) {
          this.data.fcfData.customsRate = response.data.rate;
        } else {
          throw (response.msg || "Failed to get fresh customs");
        }
      } catch (error: any) {
        this.vrtError = error.message;
        console.error("VRT calculation error:", error);
      } finally {
        this.customsLoading = false;
      }
    }
  }

  private async getVrt(): Promise<void> {
    if (this.data.fcfData?.id) {
      this.vrtLoading = true;
      try {
        const response = await this.apiService.getVrt(this.data.fcfData?.id);

        if (response?.success) {
          this.data.fcfData = {...this.data.fcfData, ...response.data};
        } else {
          throw (response?.msg || "Failed to calculate VRT");
        }
      } catch (error: any) {
        this.vrtError = error.message;
        console.error("VRT calculation error:", error);
      } finally {
        this.vrtLoading = false;
      }
    }
  }
}
