<div class="disclaimerContainer">
  <h4>Disclaimer</h4>
  <!-- <p>Whilst due care and attention has been exercised in the presentation of this information, TheCarSite accepts no liability for any loss ensuing from the use of the information provided.</p> -->

  <p>Given the variety of data sources, fluctuating underlying prices of vehicles and currencies and the fact the Revenue does not give definitive import costs -- rather 'current estimates', this and all information presented must also be viewed as estimations also.

    For the avoidance of doubt, some or all of the following may differ from the information presented -- the specific vehicle details, the purchase price of the vehicle, the VRT, the Customs, the Irish VAT, the UK VAT, the Shipping, and the Forex rates.

    Whilst due care and attention has been exercised in the presentation of this information, TheCarSite accepts no liability for any loss ensuing from the use of the information provided.

    TheCarSite is here to help you Save Time, Cut Costs, Grow Profits and Sell Cars Faster. Your own thorough and independent Due Diligence is very much required.</p>
</div>
