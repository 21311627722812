import { Injectable } from "@angular/core";
import { Md5 } from "ts-md5";
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { environment } from "../environment/environment";
import { UserService } from "./user.service";
import { Resource } from "./resource.service";
import { VehicleDetails } from "../pages/home/ccq/ccq.model.consts";
import { LocaleService } from "./locale.service";
import { SortDirection } from "@angular/material/sort";
import { saveAs } from "file-saver";
import { Filters } from "./fcf.service";

export interface ApiResponse {
  success: boolean;
  data: any;
  msg: any;
}

@Injectable({
  providedIn: "root",
})
export class ApiService {
  private endpoints = {
    // Search
    search: "/search/search",
    "details/uk": "/search/details/uk",
    "details/ie": "/search/details/ie",
    vrt: "/search/vrt",
    vrtStatCode: "/search/vrtStatCode",
    similarVrt: "/search/similar",
    options: "/search/options",
    vat: "/search/vat",
    shipping: "/search/shipping",
    customs: "/search/customs",
    makesAndModels: "/search/makesAndModels",
    getVersions: "/search/versions",
    // FCF
    "lists/vrt": "/lists/vrt",
    fcf: "/lists/fcf",
    facets: "/lists/facets",
    equivalents: "/lists/equivalents",
    // CYC
    "check/uk": "/check/uk",
    "check/ie": "/check/ie",
    // Services
    currencyRates: "/services/currencies",
    resources: "/services/resources",
    contact: "/services/contact",
    checkToken: "/services/checkToken",
    submitFeedback: "/services/feedback",
    // User
    getUserQuotes: "/user/quotes/list",
    notificationOptions: "/user/notifications/options",
    addNotification: "/user/notifications/add",
    getNotifications: "/user/notifications/get",
    deleteNotification: "/user/notifications/delete",
    business_name: "/user/data/address",
    business_details: "/user/data/business_details",
    userActivity: "/user/data/activity",
    submitQuote: "/user/quotes/submit",
    submitBug: "/user/data/bugReport",
    // Admin
    users: "/admin/users",
    adminUserActivity: "/admin/userActivity",
    assignRole: "/admin/appoint",
    getQuotes: "/admin/quotes",
    getDealersIe: "/admin/dealers_ie",
    setQuoteStatus: "/admin/quoteStatus",
    maskEntry: "/admin/maskEntry",
    saveResource: "/editor/saveResource",
    validateSubscription: "/admin/validateSubscription",
    resendVerificationEmail: "/admin/resendVerificationEmail",
    sources: "/admin/sources/",
    // Payment
    initiatePaymentSession: "/payment/create",
    checkPayment: "/payment/check",
    products: "/payment/products",
    invoices: "/payment/invoices",
    updatePayment: "/payment/update",
    attachNewPayment: "/payment/attach",
    // PDF
    "pdf/ccq": "/pdf/ccq",
    "pdf/cyc": "/pdf/cyc",

    disclaimers: "/user/data/disclaimers",
  };

  constructor(
    private http: HttpClient,
    private userService: UserService,
    private localeService: LocaleService
  ) {}

  public async search(term: string): Promise<ApiResponse> {
    const payload = {
      search: term,
    };
    return this.request(this.endpoints.search, payload);
  }

  public async check(loc: "uk" | "ie", id: number, check: string): Promise<ApiResponse> {
    const payload = {
      id: id,
      check: check,
    };
    return this.request(this.endpoints[`check/${loc}`], payload);
  }

  getDetails(loc: "uk" | "ie", vehicle_id: string | number) {
    return this.request(this.endpoints[`details/${loc}`], { id: vehicle_id });
  }

  getVrt(vehicle_id: string | number, data: {} | null = null, type: string | null = null) {
    return this.request(this.endpoints["vrt"], { id: vehicle_id, data: data, type: type });
  }

  getVat(
    priceGBP: number,
    customs: number | undefined,
    priceEst: number | undefined,
    vrtEuro: any
  ) {
    return this.request(this.endpoints["vat"], {
      priceGBP: priceGBP,
      customs: customs,
      priceEst: priceEst,
      vrtEuro: vrtEuro,
    });
  }

  getCustoms(data: any) {
    return this.request(this.endpoints["customs"], data);
  }

  getTransport(data: { uk: string; ie: string }) {
    return this.request(this.endpoints["shipping"], {
      origin: data.uk,
      destination: data.ie,
    });
  }

  getEquivs(vehicle_id: number) {
    return this.request(this.endpoints["equivalents"], { id: vehicle_id });
  }

  getOptions(v: VehicleDetails) {
    return this.request(this.endpoints["options"], v);
  }

  public async getVrtList() {
    return this.get(this.endpoints["lists/vrt"]);
  }

  async getCurrencyRates(date: Date = new Date()) {
    return this.request(this.endpoints["currencyRates"], { date: date });
  }

  submitQuote(data: any) {
    return this.request(this.endpoints["submitQuote"], data);
  }

  async getUserQuotes() {
    const res = await this.get(this.endpoints["getUserQuotes"]);
    return res.data;
  }

  async getQuotes() {
    const res = await this.get(this.endpoints["getQuotes"]);
    return res.data;
  }

  async getDealers() {
    return this.get(this.endpoints["getDealersIe"]);
  }

  async getUsers() {
    const res = await this.get(this.endpoints["users"]);
    return res.data;
  }

  async assignRole(role: string, uid: string) {
    await this.request(this.endpoints["assignRole"], { uid: uid, role: role });
  }

  async setQuoteStatus(id: string, status: string) {
    await this.request(this.endpoints["setQuoteStatus"], { id: id, status: status });
  }

  async getResources() {
    const res = await this.get(this.endpoints["resources"]);
    return res.data;
  }

  async saveResource(data: Resource) {
    await this.request(this.endpoints["saveResource"], data);
  }

  async getNotifications() {
    return this.get(this.endpoints["getNotifications"]);
  }

  async saveNotifications(v: any, id: number | null = null) {
    return this.request(this.endpoints["addNotification"], {
      notifications: v,
      notification_id: id,
    });
  }

  async maskEntry(id: number) {
    await this.request(this.endpoints["maskEntry"], { id: id });
  }

  async initiatePaymentSession(payload: any) {
    return await this.request(this.endpoints["initiatePaymentSession"], payload);
  }

  async getProducts() {
    return await this.get(this.endpoints["products"]);
  }

  async getDisclaimers() {
    const res = await this.get(this.endpoints["disclaimers"]);
    return res.data;
  }

  async SaveDisclaimers(data: any) {
    return this.request(this.endpoints["disclaimers"], data);
  }

  /**
   * Use Google Maps API to retrieve address of business
   * @param name
   */
  async lookupBusiness(name: string) {
    return await this.request(this.endpoints["business_name"], { name: name });
  }

  async updateBusinessDetails(data: any) {
    return await this.request(this.endpoints["business_details"], { business_data: data });
  }

  async getBusinessDetails() {
    return await this.get(this.endpoints["business_details"]);
  }

  async contact(email: string, msg: string, token: string) {
    return await this.request(this.endpoints["contact"], { email: email, msg: msg, token: token });
  }

  async checkToken(token: string | null): Promise<ApiResponse> {
    if (!token) {
      return {
        msg: "CAPTCHA token is required.",
        success: false,
        data: null,
      };
    }
    return this.request(this.endpoints["checkToken"], { token: token });
  }

  getPaymentStatus(session_id: string) {
    return this.request(this.endpoints["checkPayment"], { session_id: session_id });
  }

  getInvoices() {
    return this.get(this.endpoints["invoices"]);
  }

  updatePayment() {
    return this.get(this.endpoints["updatePayment"]);
  }

  attachNewPayment(session_id: string) {
    return this.request(this.endpoints["attachNewPayment"], { session_id: session_id });
  }

  userActivity(days: number, entries: number, endpoint: string | undefined) {
    return this.request(this.endpoints["userActivity"], {
      days: days,
      entries: entries,
      endpoint: endpoint,
    });
  }

  submitBug(report: any, data: any) {
    return this.request(this.endpoints["submitBug"], { report: report, data: data });
  }

  deleteNotification(id: number) {
    return this.request(this.endpoints["deleteNotification"], { notification_id: id });
  }

  getMakesAndModels() {
    return this.get(this.endpoints["makesAndModels"]);
  }

  async getVersions(make: string, model: string) {
    if (!make || !model) {
      throw new Error("Make and model are required");
    }
    return this.request(this.endpoints["getVersions"], { make: make, model: model });
  }

  async getFcf(
    pageSize: number,
    pageIndex: number,
    order: SortDirection,
    orderBy: string | null = null,
    filters: Filters
  ) {
    return this.request(this.endpoints["fcf"], {
      offset: pageSize * (pageIndex - 1),
      limit: pageSize,
      order: order,
      orderBy: orderBy,
      filters: filters,
    });
  }

  public getFacets() {
    return this.get(this.endpoints["facets"]);
  }

  submitFeedback(fb: 0 | 1, vrm: string, service: string) {
    return this.request(this.endpoints["submitFeedback"], {
      feedback: fb,
      vrm: vrm,
      service: service,
    });
  }

  validateSubscription(user: any) {
    return this.request(this.endpoints["validateSubscription"], { uid: user.uid });
  }

  resendVerificationEmail(user: any) {
    return this.request(this.endpoints["resendVerificationEmail"], { uid: user.uid });
  }

  /**
   *
   */
  async pdf(mode: "ccq" | "cyc", payload: any, vrm: string): Promise<ApiResponse | void> {
    const body = encodeURIComponent(JSON.stringify(payload));
    const headers = await this.getHeaders(body);
    this.http
      .post(environment.apiServer + this.endpoints[`pdf/${mode}`], body, {
        headers: headers,
        responseType: "blob",
        observe: "response",
      })
      .subscribe(
        async (r: HttpResponse<any>) => {
          switch (r.headers.get("Content-Type")) {
            case "application/json; charset=UTF-8":
            case "application/json":
              const blob = new Blob([r.body]);
              return <ApiResponse>JSON.parse(await blob.text());
            case "application/pdf":
            case "application/x-pdf":
              saveAs(r.body, `${mode.toUpperCase()} - ${vrm}.pdf`);
              break;
          }
          return;
        },
        error => {
          throw error;
        }
      );
  }

  /**
   * Perform a post request
   * Append firebase token if user logged in
   * @param endpoint
   * @param payload
   * @private
   */
  private async request(endpoint: string, payload: any) {
    const body = encodeURIComponent(JSON.stringify(payload));
    const headers = await this.getHeaders(body);
    const r = <ApiResponse>await this.http
      .post(environment.apiServer + endpoint, body, {
        headers: headers,
        responseType: "json",
      })
      .toPromise();
    if (r) {
      return r;
    } else {
      throw new Error("Could not perfom the request.");
    }
  }

  private async getHeaders(payload: string): Promise<HttpHeaders> {
    let headers = new HttpHeaders()
      .set("Checksum", this.getToken(payload))
      .set("locale", this.localeService.locale ?? 'en-IE'); // default to IE, as the locale might not be set for certain prerendering or SSR settings

    if (this.userService.isLoggedIn()) {
      const token = await this.userService.getIdToken();
      if (token) {
        headers = headers.set("FIREBASE_ID_TOKEN", token);
      } else {
        console.warn("getIdToken returned undefined or null.");
      }
    }
    return headers;
  }
  /**
   * Perform a GET request
   * Append Firebase token if logged in
   * @param endpoint
   * @private
   */
  private async get(endpoint: string) {
    const headers = await this.getHeaders(endpoint);
    const r = <ApiResponse>await this.http
      .get(environment.apiServer + endpoint, {
        headers: headers,
        responseType: "json",
      })
      .toPromise();
    if (r?.success) {
      return r;
    } else {
      throw new Error(r.msg ?? "Could not perfom the request.");
    }
  }

  private getToken(body: string): string {
    return Md5.hashStr(body + body.length);
  }

  getSource(source: string) {
    return this.get(this.endpoints["sources"] + source);
  }

  getUserActivity(days: number): Promise<ApiResponse> {
    return this.request(this.endpoints["adminUserActivity"], {days: days});
  }
}
