import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import {
  CurrencyPipe,
  DecimalPipe,
  KeyValuePipe,
  NgClass,
  NgIf,
  ViewportScroller,
} from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButton } from "@angular/material/button";
import { MatProgressSpinner } from "@angular/material/progress-spinner";
import { ActivatedRoute, RouterLink } from "@angular/router";
import { ApiResponse, ApiService } from "../../../services/api.service";
import { VrmSearchComponent } from "../../../components/vrm-search/vrm-search.component";
import {
  MatAccordion,
  MatExpansionPanel,
  MatExpansionPanelHeader,
} from "@angular/material/expansion";
import { MatSlideToggle, MatSlideToggleChange } from "@angular/material/slide-toggle";
import { LOCAL_STORAGE, StorageService } from "ngx-webstorage-service";
import { HeroBgService } from "../../../services/hero-bg.service";
import { UserActivityComponent } from "../../../components/user-activity/user-activity.component";
import { DisclaimerComponent } from "../../../components/disclaimer/disclaimer.component";
import { FflContentComponent } from "../../../components/ffl-content/ffl-content.component";
import { ProductsComponent } from "../../../components/products/products.component";
import { MatIcon } from "@angular/material/icon";
import { BugService } from "../../../services/bug.service";
import { HttpErrorResponse } from "@angular/common/http";
import { VehicleDataComponent } from "../../../components/tables/vehicle-data/vehicle-data.component";
import { formatTechSpecs } from "../../../pipes/format-tech-specs.pipe";
import { FcfService } from "../../../services/fcf.service";
import { VinRevealService } from "../../../services/vin-reveal.service";
import { FeedbackService } from "../../../services/feedback.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Messages } from "../../../messages";
import { DisclaimerDialogComponent } from "../../../components/dialogs/disclaimer-dialog/disclaimer-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { DisclaimerService } from "../../../services/disclaimer.service";
import { CcqLogicComponent } from "../ccq-logic/ccq-logic.component";

interface CheckResponse {
  title: string;
  loading: boolean | null;
  uk: boolean; // Whether to run this check for UK cars
  ie: boolean; // Whether to run this check for IE cars
  col: 1 | 2;
  error?: string | null;
  result?: {
    tableData?: any;
    status: "ok" | "warn" | "fail" | null;
    text?: string[];
    data?: any;
  };
}

interface CheckResponses {
  [p: string]: CheckResponse;
}

@Component({
  selector: "app-check",
  standalone: true,
  imports: [
    DecimalPipe,
    FormsModule,
    KeyValuePipe,
    MatButton,
    MatProgressSpinner,
    NgIf,
    ReactiveFormsModule,
    NgClass,
    RouterLink,
    VrmSearchComponent,
    MatAccordion,
    MatExpansionPanel,
    MatExpansionPanelHeader,
    MatSlideToggle,
    UserActivityComponent,
    DisclaimerComponent,
    FflContentComponent,
    ProductsComponent,
    MatIcon,
    VehicleDataComponent,
    formatTechSpecs,
    CurrencyPipe,
    CcqLogicComponent,
  ],
  templateUrl: "./cyc-home.component.html",
  styleUrl: "./cyc-home.component.scss",
})
export class CycHomeComponent implements OnInit {
  // public searchControl: FormControl;
  public searchResult: { [key: string]: string | boolean | number | null } | undefined;
  public detailsError: string | undefined;
  public detailsLoading: boolean = false;
  public curLoc: "uk" | "ie" = "uk"; // Default vehicle location
  @ViewChild("vrmSearch") vrmSearch: VrmSearchComponent | undefined;
  @ViewChild("financeCheck") financeCheck!: MatSlideToggle;
  @ViewChild("ccqLogic") ccqLogic: CcqLogicComponent | undefined;
  protected searching: boolean = false;
  protected motivationalText: string | undefined;
  protected checks: CheckResponses = {
    finance: { title: "Finance Status", loading: null, uk: true, ie: true, col: 1 },
    stolen: { title: "Stolen Vehicle", loading: null, uk: true, ie: false, col: 1 },
    writeoff: { title: "Write-off status", loading: null, uk: true, ie: true, col: 1 },
    damages: { title: "Vehicle Damages", loading: null, uk: true, ie: false, col: 1 },
    warranty: { title: "Vehicle Warranty", loading: null, uk: true, ie: true, col: 1 },
    mileage: { title: "Mileage History", loading: null, uk: true, ie: false, col: 1 },
    provenance: { title: "High risk provenance", loading: null, uk: true, ie: true, col: 1 },

    imported: { title: "Imported", loading: null, uk: false, ie: true, col: 1 },
    equivalent: { title: "Ads for similar vehicles", loading: null, uk: true, ie: true, col: 1 },

    owners: { title: "Owner History", loading: null, uk: true, ie: true, col: 2 },
    identity: { title: "Vehicle Identity", loading: null, uk: true, ie: true, col: 2 },
    technical: { title: "Technical specs", loading: null, uk: true, ie: false, col: 2 },
    mot: { title: "MOT History", loading: null, uk: true, ie: false, col: 2 },
    nct: { title: "NCT Status", loading: null, uk: false, ie: true, col: 2 },
    crw: { title: "Roadworthiness", loading: null, uk: false, ie: true, col: 1 },
    plates: { title: "Number Plate History", loading: null, uk: true, ie: true, col: 2 },
    taxed: { title: "Tax status", loading: null, uk: true, ie: true, col: 2 },
    costs: { title: "Running Costs", loading: null, uk: true, ie: true, col: 2 },
    valuation: { title: "Vehicle Valuation", loading: null, uk: true, ie: true, col: 2 },

    recall: { title: "Outstanding recalls", loading: null, uk: false, ie: false, col: 2 },
    insurance: { title: "Insurance cover", loading: null, uk: false, ie: false, col: 2 },

    stolen_ie: { title: "Stolen Vehicle", loading: null, uk: false, ie: true, col: 1 },
  };
  protected readonly Number = Number;
  protected readonly String = String;
  protected readonly FcfService = FcfService;

  constructor(
    public dialog: MatDialog,
    protected bug: BugService,
    private scroller: ViewportScroller,
    private route: ActivatedRoute,
    private apiService: ApiService,
    @Inject(LOCAL_STORAGE) private storage: StorageService,
    private hero: HeroBgService,
    protected vinRevealService: VinRevealService,
    private feedback: FeedbackService,
    private snack: MatSnackBar,
    private disclaimerService: DisclaimerService
  ) {}

  protected get currency() {
    return this.curLoc == "ie" ? "EUR" : "GBP";
  }

  ngOnInit(): void {
    this.route.params.subscribe(p => {
      if (p["url"]) {
        this.vrmSearch?.setVrm(p["url"]);
        this.search(p["url"]);
      }
    });
    this.hero.setBg("/assets/img/cyc.jpg");
  }

  async ngAfterViewInit() {
    // Wait for the disclaimers to be loaded
    await this.disclaimerService.load();

    if (this.disclaimerService.get("cyc") === false) {
      this.openDislaimersDialog("cyc");
    }
  }

  async search(vrm: string): Promise<boolean> {
    this.searching = true;
    this.toggleCheckLoaders(this.curLoc);
    this.motivationalText = Messages.lookingForVehicle;
    this.reset();
    try {
      vrm = vrm.toUpperCase().replace(/\s+/g, "");
      this.vrmSearch?.setVrm(vrm); // Update it in the input field
      const r = <ApiResponse>await this.apiService.search(vrm);
      if (r?.success) {
        this.searchResult = r.data;
        this.curLoc = r.data.loc;

        // Check for VRM mismatch before processing details
        const returnedVrm = r.data.registrationNumber || r.data.registrationNumber_ie;
        if (returnedVrm && vrm !== returnedVrm) {
          this.vrmSearch?.setError(
            `Please note that the input VRM (${vrm}) has changed for this vehicle. The most recent we have on file is ${returnedVrm}. See the Number Plate History below for the details.`
          );
        }

        if (this.curLoc == "ie" && this.searchResult) {
          // For IE, check if we're doing RWC or NCT
          const privateVehicle = this.searchResult["euClassification"] == "M1";
          this.checks["crw"].ie = !privateVehicle;
          this.checks["nct"].ie = privateVehicle;
        }
        this.motivationalText = Messages.vehicleFound;
        // Scroll to report
        this.scroller.scrollToAnchor("cycStart");
        this.detailsLoading = true;
        if (this.searchResult && this.searchResult["vin"]) {
          // We already have detail of this car. Load it in parallel
          this.getDetails(r.data.loc, r.data.id).then(() => {
            this.detailsLoading = false;
          });
        } else {
          // We do not have details. Load them first
          await this.getDetails(r.data.loc, r.data.id);
          this.detailsLoading = false;
        }
        await this.loadChecks(r.data.loc, r.data.id);
        await this.feedback.trigger(vrm, "cyc");
      }
      this.searching = false;
      if (this.ccqLogic) {
        this.ccqLogic.search(vrm)
          .catch(e => console.error(e));
      }
      return true;
    } catch (error: any) {
      // console.error(e);
      this.reset();
      let errorMsg = error.error?.msg ?? error.message;
      if (!error.status) {
        // No connectivity
        errorMsg = Messages.connectionError;
      }
      this.vrmSearch?.setError(errorMsg);
      this.searching = false;
      return false;
    }
  }

  origOrder = (): number => {
    return 0;
  };

  /**
   * Store the finance check toggle preference in local storage
   * @param $event
   */
  async toggleFinance($event: MatSlideToggleChange) {
    this.storage.set("financePref", $event.checked);
    if ($event.checked && this.searchResult) {
      // We have a search - run the finance check
      // console.log('We have a search - run the finance check', this.searchResult);
      this.checks["finance"].loading = true;
      await this.check(
        <"uk" | "ie">this.searchResult["loc"],
        <number>this.searchResult["id"],
        "finance"
      );
      this.checks["finance"].loading = false;
    }
  }

  /**
   * Get the finance check toggle state preference
   * If none has been set, default to true (checked)
   */
  getFinancePref(): boolean {
    return <boolean>this.storage.get("financePref") ?? false;
  }

  async printReport() {
    if (!this.searchResult) {
      return;
    }
    const resp = await this.apiService
      .pdf(
        "cyc",
        {
          vehicleData: this.searchResult,
          checks: this.checks,
        },
        String(
          this.searchResult["registrationNumber"] ?? this.searchResult["registrationNumber_ie"]
        )
      )
      .catch((e: HttpErrorResponse) => {
        console.error(e);
        this.snack.open(e.message ?? e.error, "Dismiss", {
          duration: 5000,
          panelClass: ["snack-error"],
        });
      });
    if (resp?.msg) {
      this.snack.open(resp.msg, "Dismiss", { duration: 5000, panelClass: ["snack-error"] });
    }
  }

  private async loadChecks(loc: "uk" | "ie", id: number, concurrencyLimit: number = 4) {
    this.toggleCheckLoaders(loc);

    // We are not doing finance check
    if (!this.financeCheck.checked) {
      this.checks["finance"].result = {
        status: null,
        tableData: null,
        text: [Messages.noFinanceCheckOption, Messages.pleaseUseToggle],
      };
    } else {
      this.checks["finance"].result = undefined;
    }

    // Define an array of eligible tasks
    const tasks: (() => Promise<void>)[] = [];

    for (let key in this.checks) {
      if (key === "finance" && !this.financeCheck.checked) {
        continue;
      }

      if (this.checks[key][loc]) {
        this.checks[key].loading = true;

        // Add each check task as a function to the task list
        tasks.push(async () => {
          this.motivationalText = `Checking the ${this.checks[key].title}`;

          try {
            await this.check(loc, id, key);
          } catch (error: any) {
            const errorMsg = error?.error?.msg ?? error?.message;
            console.error(`Error checking ${key}:`, error);
            this.checks[key].result = {
              status: "fail",
              text: [`Error loading ${key}: ${errorMsg}`],
            };
          } finally {
            this.checks[key].loading = false;
          }
        });
      }
    }

    // Execute the tasks with limited concurrency
    await this.runWithConcurrencyLimit(tasks, concurrencyLimit);
  }

  // Helper to run tasks with a concurrency limit
  private async runWithConcurrencyLimit(
    tasks: (() => Promise<void>)[],
    limit: number
  ): Promise<void> {
    const executing: Promise<void>[] = []; // Currently executing promises

    for (const task of tasks) {
      // Start a new task and push its promise to executing
      const p = task();
      executing.push(p);

      // If the executing pool size exceeds the limit, wait for one to complete
      if (executing.length >= limit) {
        await Promise.race(executing);
      }

      // Remove completed tasks from executing
      executing.splice(
        executing.findIndex(e => e === p),
        1
      );
    }

    // Wait for all remaining tasks to complete
    await Promise.all(executing);
  }

  private async getDetails(loc: "uk" | "ie", vehicle_id: string) {
    const r = await this.apiService.getDetails(loc, vehicle_id);
    if (r?.success) {
      this.searchResult = r?.data;
      if (this.searchResult) {
        this.searchResult["loc"] = loc;
      }
      if (r.data.registrationNumber) {
        this.vrmSearch?.setVrm(r.data.registrationNumber);
      } else if (r.data.registrationNumber_ie) {
        this.vrmSearch?.setVrm(r.data.registrationNumber_ie);
      }
    } else {
      this.detailsError = r?.msg;
    }
    this.detailsLoading = false;
  }

  private reset() {
    for (let key in this.checks) {
      this.checks[key].result = undefined;
      this.checks[key].error = undefined;
    }
    this.vinRevealService.showVin = false;
    this.vrmSearch?.resetError();
    this.searchResult = undefined;
  }

  private async check(loc: "uk" | "ie", id: number, check: string) {
    try {
      const r = await this.apiService.check(loc, id, check);

      if (r?.success) {
        this.checks[check].result = r?.data;
      } else {
        this.checks[check].error = r?.msg;
      }
    } catch (e: any) {
      console.error(e);
      if (e.status === 402) {
        throw e;
      } else if (!e.status) {
        // No connectivity
        this.vrmSearch?.setError(Messages.connectionError);
        this.checks[check].error = Messages.connectionError;
      }
    }
  }

  /**
   * Toggle loaders for all checks
   * @param loc
   * @param loading
   * @private
   */
  private toggleCheckLoaders(loc: "uk" | "ie", loading: boolean = true) {
    for (let key in this.checks) {
      if (key === "finance" && !this.financeCheck?.checked) {
        // We are not doing finance check
        continue;
      }
      if (this.checks[key][loc]) {
        this.checks[key].loading = loading;
      }
    }
  }

  openDislaimersDialog(type: any): void {
    // Open the VatAdjustmentDialogComponent dialog
    const dialogRef = this.dialog.open(DisclaimerDialogComponent, {
      width: "600px", // Fixed dialog width
      data: { service: type },
    });

    // Handle the dialog close event
    // dialogRef.afterClosed().subscribe(result => {
    //   if (result) {
    // Trigger data refresh in FcfHomeComponent
    // this.vatAdjustmentService.triggerDataRefresh();
    //   }
    // });
  }
}
