<h2 mat-dialog-title class="dialog-title">
  Set Importation Fee
</h2>
<mat-dialog-content>
  <div class="dialog-container">
    <!-- Radio buttons to select adjustment type -->
    <mat-radio-group [(ngModel)]="adjustmentType" class="adjustment-type">
      <mat-radio-button value="fixed">Fixed Amount</mat-radio-button>
      <mat-radio-button value="percentage">Percentage of Net UK Purchase Price</mat-radio-button>
    </mat-radio-group>

    @if (adjustmentType === 'fixed') {
    <!-- Input field for fixed amount -->
    <mat-form-field appearance="fill" class="vat-input">
      <mat-label>Fixed Importation Fee</mat-label>
      <input
        matInput
        [formControl]="importFeeController"
        type="number"
        placeholder="Enter Fixed Importation Fee"
        step="10"
      />
    </mat-form-field>
    } @else {
      <!-- Input field for percentage -->
      <mat-form-field appearance="fill" class="vat-input">
        <mat-label>Enter Importation Fee as Percentage</mat-label>
        <input
          matInput
          [formControl]="importFeePercentAdjustmentController"
          type="number"
          placeholder="E.g. 5"
          step="1"
          min="0"
          max="100"
        />
      </mat-form-field>
    }
    <mat-radio-group [formControl]="currencyControl" class="currency-group">
      <mat-radio-button value="eur">EUR</mat-radio-button>
      <mat-radio-button value="gbp">GBP</mat-radio-button>
    </mat-radio-group>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="center" class="button-row">
  <button mat-raised-button (click)="dialogRef.close(false)">Cancel</button>
  <button mat-raised-button [disabled]="adjustmentType === 'fixed' ? importFeeController.untouched : importFeePercentAdjustmentController.untouched" (click)="saveAdjustment(true)">Set for All Vehicles</button>
  <button mat-raised-button color="primary" [disabled]="adjustmentType === 'fixed' ? importFeeController.untouched : importFeePercentAdjustmentController.untouched" (click)="saveAdjustment()">Set for This Vehicle</button>
</mat-dialog-actions>
