import { Component, EventEmitter, Inject, OnInit } from "@angular/core";
import { formatCurrency, formatNumber, KeyValuePipe, NgIf, PercentPipe } from "@angular/common";
import { MatButton } from "@angular/material/button";
import { MatFormField, MatLabel } from "@angular/material/form-field";
import { MatOption } from "@angular/material/autocomplete";
import { MatProgressSpinner } from "@angular/material/progress-spinner";
import { MatSelect } from "@angular/material/select";
import { FormGroup, ReactiveFormsModule } from "@angular/forms";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogActions,
  MatDialogContent,
  MatDialogTitle,
} from "@angular/material/dialog";
import { MatSlider, MatSliderRangeThumb } from "@angular/material/slider";
import { MatInput } from "@angular/material/input";
import { MatTooltip } from "@angular/material/tooltip";
import { MatIcon } from "@angular/material/icon";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ApiService } from "../../../services/api.service";
import { FcfService } from "../../../services/fcf.service";
import { MatSlideToggle } from "@angular/material/slide-toggle";
import { LOCAL_STORAGE, StorageService } from "ngx-webstorage-service";
import { LoadSearchDialogComponent } from "./load-search-dialog/load-search-dialog.component";
import { SearchHistoryService } from "../../../services/search-history.service";
export const bodyTypeOptions: { i: number; key: string; bodyTypes: number[] }[] = [
  { i: 0, key: "Convertible", bodyTypes: [41] },
  { i: 1, key: "Estate", bodyTypes: [77, 2] },
  { i: 2, key: "Hatchback", bodyTypes: [40] },
  { i: 3, key: "Lorry", bodyTypes: [58] },
  { i: 4, key: "MPV", bodyTypes: [92] },
  { i: 5, key: "Pick up", bodyTypes: [48] },
  { i: 6, key: "Saloon", bodyTypes: [1] },
  { i: 7, key: "Sports coupe", bodyTypes: [42] },
  { i: 8, key: "Van", bodyTypes: [6, 60, 56] },
  { i: 9, key: "Other / Unknown", bodyTypes: [] },
];
export const colourOptions: string[] = [
  "BEIGE",
  "BRONZE",
  "BLACK",
  "BLUE",
  "BROWN",
  "CREAM",
  "GOLD",
  "GREEN",
  "GREY",
  "MAROON",
  "ORANGE",
  "PINK",
  "PURPLE",
  "RED",
  "SILVER",
  "TURQUOISE",
  "WHITE",
  "YELLOW",
  "OTHER",
];
@Component({
  selector: "app-fcf-filters",
  standalone: true,
  imports: [
    KeyValuePipe,
    MatButton,
    MatFormField,
    MatLabel,
    MatOption,
    MatProgressSpinner,
    MatSelect,
    NgIf,
    ReactiveFormsModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatSlider,
    MatSliderRangeThumb,
    MatInput,
    MatTooltip,
    MatIcon,
    MatSlideToggle,
  ],
  templateUrl: "./fcf-filters.component.html",
  styleUrl: "./fcf-filters.component.scss",
})
export class FcfFiltersComponent implements OnInit {
  public submit = new EventEmitter<FormGroup>();
  public reset = new EventEmitter<FormGroup>();
  public savedNotification = new EventEmitter();
  private allMakes: string[] = [];
  private allModels: string[] = [];
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { filtersFormGroup: FormGroup; mode: "fcf" | "notifications" },
    private dialog: MatDialog,
    private apiService: ApiService,
    private snackBar: MatSnackBar,
    protected fcfService: FcfService,
    @Inject(LOCAL_STORAGE) private storage: StorageService,
    private searchHistoryService: SearchHistoryService
  ) {}
  ngOnInit() {
    this.allMakes = Object.keys(this.fcfService.makes);
    this.data.filtersFormGroup.valueChanges.subscribe(values => {
      if (values.makes?.length === 0) {
        this.data.filtersFormGroup.get("models")?.disable({ onlySelf: true, emitEvent: false });
      } else {
        this.data.filtersFormGroup.get("models")?.enable({ onlySelf: true, emitEvent: false });
        this.updateAllModels();
      }
    });
  }

  private updateAllModels() {
    const selectedMakes = this.data.filtersFormGroup.get("makes")?.value || [];
    this.allModels = selectedMakes.flatMap((make: string) => this.fcfService.makes[make] || []);
  }

  protected applyFilters() {
    this.submit.emit(this.data.filtersFormGroup);
    this.searchHistoryService.saveSearch(this.data.filtersFormGroup.value, "searchHistory"); // Add this line
    this.close();
  }

  protected openSearchHistoryDialog(): void {
    const searchHistory = this.searchHistoryService.getSavedSearches("searchHistory");
    const keys = Object.keys(searchHistory);

    if (keys.length === 0) {
      this.snackBar.open("No search history found.", undefined, {
        panelClass: "snack-error",
        duration: 3000,
      });
      return;
    }

    const dialogRef = this.dialog.open(LoadSearchDialogComponent, {
      data: {
        items: searchHistory,
        title: "Search History"
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result?.action === "load") {
        this.loadSearch(result.key, "searchHistory");
        this.data.filtersFormGroup.markAsDirty();
      } else if (result?.action === "delete") {
        this.searchHistoryService.deleteSearch(result.key, "searchHistory");
      }
    });
  }

  protected resetFilters() {
    this.reset.emit(this.data.filtersFormGroup);

    this.data.filtersFormGroup.valueChanges.subscribe(values => {
      if (values.makes?.length === 0) {
        this.data.filtersFormGroup.get("models")?.disable({ onlySelf: true, emitEvent: false });
      } else {
        this.data.filtersFormGroup.get("models")?.enable({ onlySelf: true, emitEvent: false });
        this.updateAllModels();
      }
    });
  }

  /**
   * Saves the current filters to local storage.
   */
  protected saveSearch(): void {
    const filters = this.data.filtersFormGroup.value;
    this.searchHistoryService.saveSearch(filters, "savedSearches");
    this.snackBar.open("Search saved successfully!", undefined, {
      panelClass: "snack-ok",
      duration: 3000,
    });
  }

  /**
   * Opens a dialog to load or delete saved searches.
   */
  protected openLoadSearchDialog(): void {
    const savedSearches = this.searchHistoryService.getSavedSearches("savedSearches");
    const keys = Object.keys(savedSearches);

    if (keys.length === 0) {
      this.snackBar.open("No saved searches found.", undefined, {
        panelClass: "snack-error",
        duration: 3000,
      });
      return;
    }

    const dialogRef = this.dialog.open(LoadSearchDialogComponent, {
      data: {
        items: savedSearches,
        title: "Saved Search"
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result?.action === "load") {
        this.loadSearch(result.key, "savedSearches");
        this.data.filtersFormGroup.markAsDirty();
      } else if (result?.action === "delete") {
        this.deleteSearch(result.key);
      }
    });
  }

  /**
   * Loads a saved search and applies it to the form.
   */
  private loadSearch(timestamp_key: number, key: string): void {
    const filters = this.searchHistoryService.loadSearch(timestamp_key, key);
    if (filters) {
      this.data.filtersFormGroup.patchValue(filters); // Apply filters to form
      this.snackBar.open("Search loaded successfully!", undefined, {
        panelClass: "snack-ok",
        duration: 3000,
      });
    }
  }

  /**
   * Deletes a saved search from local storage.
   */
  private deleteSearch(timestamp_key: number): void {
    this.searchHistoryService.deleteSearch(timestamp_key, "savedSearches");
    this.snackBar.open("Search deleted successfully!", undefined, {
      panelClass: "snack-ok",
      duration: 3000,
    });
  }

  protected close() {
    this.dialog.getDialogById("fcf-filters")?.close();
  }

  /**
   * Formats the maximum cost value into a string representation.
   * USed for purchase price
   *
   * @param {number} value - The maximum cost value to be formatted.
   * @return {string} A formatted string. Returns 'No Limit' if the value exceeds or equals the predefined limit; otherwise, returns the formatted price.
   */
  protected formatMaxCostGBP(value: number): string {
    if (value >= FcfService.maxCost) {
      return "Any";
    }
    // return formatCurrency(value, "en", "€", "EUR", "1.0-0");
    return formatCurrency(value, "en", "£", "GBP", "1.0-0");
  }
  /**
   * Formats the maximum cost value into a string representation.
   * USed for total cost
   *
   * @param {number} value - The maximum cost value to be formatted.
   * @return {string} A formatted string. Returns 'No Limit' if the value exceeds or equals the predefined limit; otherwise, returns the formatted price.
   */
  protected formatMaxCostEUR(value: number): string {
    if (value >= FcfService.maxCost) {
      return "Any";
    }
    // return formatCurrency(value, "en", "€", "EUR", "1.0-0");
    return formatCurrency(value, "en", "€", "EUR", "1.0-0");
  }
  protected formatProfit(value: number): string {
    if (value <= 0) {
      return "Any";
    }
    return formatCurrency(value, "en", "€", "EUR", "1.0-0");
  }
  protected formatYear(value: number): string {
    if (value <= 2000) {
      return "Any";
    }
    return String(value);
  }
  protected formatEngineSize(value: number): string {
    if (value >= 5000) {
      return "Any";
    }
    const num = formatNumber(value, "en", "1.0-0");
    return `${num}cc`;
  }
  protected formatKms(value: number): string {
    if (value >= FcfService.maxKms) {
      return "Any";
    }
    const num = formatNumber(value, "en", "1.0-0");
    return `${num}km`;
  }
  protected formatPercent(value: number): string {
    const percentPipe = new PercentPipe("en");
    return percentPipe.transform(value, "1.0-0") ?? "";
  }
  protected formatGPerKm(value: number): string {
    if (value >= 400) {
      return "Any";
    }
    const num = formatNumber(value, "en", "1.0-0");
    return `${num}`;
  }

  protected async createNotification() {
    const r = await this.apiService
      .saveNotifications(
        this.data.filtersFormGroup.value,
        this.data.filtersFormGroup.get("id")?.value
      )
      .catch(e => console.error(e));
    if (r?.success) {
      this.snackBar.open("Notification saved", undefined, {
        panelClass: "snack-ok",
        duration: 5000,
      });
    } else {
      this.snackBar.open(`Could not save the notification. ${r?.msg}`, undefined, {
        panelClass: "snack-error",
        duration: 5000,
      });
    }
    this.savedNotification.emit();
    this.applyFilters(); // Apply filters and close
  }

  protected toggleAllMakes(event: Event) {
    event.stopPropagation();
    const makesControl = this.data.filtersFormGroup.get("makes");
    if (!makesControl) return;

    const currentValues = makesControl.value || [];
    if (currentValues.length === this.allMakes.length) {
      makesControl.setValue([]);
    } else {
      makesControl.setValue(this.allMakes);
    }
  }

  protected toggleAllModels(event: Event) {
    event.stopPropagation();
    const modelsControl = this.data.filtersFormGroup.get("models");
    if (!modelsControl) return;

    const currentValues = modelsControl.value || [];
    if (currentValues.length === this.allModels.length) {
      modelsControl.setValue([]);
    } else {
      modelsControl.setValue(this.allModels);
    }
  }

  protected readonly FcfService = FcfService;

  protected readonly bodyTypeOptions = bodyTypeOptions;
  protected readonly colourOptions = colourOptions;
}
