<section class="header">
  <h1>
    <span>Stop Wasting Time Calculating VRT, VAT,</span>
    <span>Customs, NOx, Forex & Shipping.</span>
  </h1>
  <h2>Stop Wasting Money on Crap Solutions.</h2>
</section>

<section class="hero">
  <div class="search-container noprint">
    <app-vrm-search mode="ccq" [disabled]="searching" flag="uk"></app-vrm-search>
    <br /><br />
    <button mat-raised-button class="vrt-calculation-button" (click)="ccqLogic.reset(); ccqLogic.editInputs()" color="primary">
      VRT Calculation Without a License Plate
    </button>
    <a id="ccqLogicAnchor"></a>
    <app-ccq-logic #ccqLogic></app-ccq-logic>
  </div>
</section>
@if (ccqLogic.vehicleData.vehicleDetails?.priceGBP) {
  <app-ffl-content
    currency="gbp"
    [avgCars]="10"
    [avgPrice]="ccqLogic.vehicleData.vehicleDetails?.priceGBP"></app-ffl-content>
}
<section class="userActivity" [hidden]="userActivityComponent.activity.data.length === 0">
  <h2>Recent CCQ calculations</h2>
  <div class="activity-container">
    <app-user-activity #userActivityComponent [entries]="10" endpoint="ccq"></app-user-activity>
  </div>
</section>
@if (ccqLogic.vehicleData.vehicleDetails) {
  <section class="hero">
    <div class="prods">
      <app-products [exclude]="'ccq'"></app-products>
    </div>
  </section>
}
