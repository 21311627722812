import { Component, Input, OnChanges, OnInit } from "@angular/core";
import { BodyTypePipe } from "../../../pipes/body-type.pipe";
import { CurrencyPipe, DecimalPipe, NgTemplateOutlet, PercentPipe } from "@angular/common";
import { EngineTypePipe } from "../../../pipes/engine-type.pipe";
import { TransmissionTypePipe } from "../../../pipes/transmission-type.pipe";
import { MatTooltip } from "@angular/material/tooltip";
import { VRT } from "../../../services/fcf.service";
import { MilesToKmPipe } from "../../../pipes/miles-to-km.pipe";

@Component({
  selector: "app-vrt-data",
  standalone: true,
  imports: [
    BodyTypePipe,
    CurrencyPipe,
    DecimalPipe,
    EngineTypePipe,
    NgTemplateOutlet,
    PercentPipe,
    TransmissionTypePipe,
    MatTooltip,
  ],
  templateUrl: "./vrt-data.component.html",
  styleUrl: "./vrt-data.component.scss",
})
export class VrtDataComponent {
  @Input("data") data: VRT | undefined;

  get kms(): number | null {
    if (this.data?.kms) {
      // If we have the kms, return as is
      return Number(this.data.kms);
    }
    if (this.data?.mileage === null || this.data?.mileage === undefined) {
      // If we don't have mileage, return null
      return null;
    }
    // Otherwise convert miles to km
    const milesToKmPipe = new MilesToKmPipe();
    return milesToKmPipe.transform(this.data.mileage);
  }
}
