@if (searching && motivationalText) {
  <h4 class="motivational-text">{{ motivationalText }}</h4>
}
<a id="ccqStart"></a>
@if (vehicleData.vehicleDetails || vehicleData.vrtDetails) {
  <div class="ccq-report-container">
    @if (vehicleData.vehicleDetails?.id && showDetails) {
      <mat-accordion>
        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header>
            <div class="expansion-header-content">
              <h3>
                @if (vehicleData.vehicleDetails) {
                  {{ vehicleData.vehicleDetails.registrationNumber }}
                }
                Vehicle Info
              </h3>
            </div>
          </mat-expansion-panel-header>
          <app-vehicle-data [data]="vehicleData.vehicleDetails"></app-vehicle-data>
        </mat-expansion-panel>
      </mat-accordion>
    }
    <!-- VRT SECTION -->
    <a id="vrtStart"></a>

    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header>
        <div class="expansion-header-content">
          <h3>Vehicle Registration Tax (VRT)</h3>
          <p *ngIf="vehicleData.vrtDetails?.vrtEuro && !vrtError" class="header-info">
            {{ vehicleData.vrtDetails?.vrtEuro | currency: "EUR" : "symbol" : "1.0-0" : "en" }}
          </p>
        </div>
      </mat-expansion-panel-header>
      <div class="section">
        @if (vrtError) {
          <div class="report-error">
            <mat-icon aria-hidden="false" aria-label="error" fontIcon="error"></mat-icon>
            <p class="error-text">
              {{ vrtError }}
            </p>
          </div>

          @if (missingVariables.length > 0 && !vrtLoading) {
            <form
              [formGroup]="missingVarsForm"
              class="missing-vars-form"
            >
              <mat-form-field *ngFor="let v of missingVariables">
                <mat-label>{{ v | formatKey }}</mat-label>
                <ng-template [ngIf]="missingOptions && missingOptions[v]" [ngIfElse]="numInput">
                  <mat-select
                    [formControlName]="v"
                    (selectionChange)="changeVrtMissingForm($event)">
                    <mat-option
                      *ngFor="let o of missingOptions[v] | keyvalue: valueAscOrder"
                      [value]="o.key"
                    >{{ o.value }}
                    </mat-option>
                  </mat-select>
                </ng-template>
                <ng-template #numInput>
                  <input
                    [formControlName]="v"
                    matInput
                    type="number"
                    step="1"
                    min="0"
                    [placeholder]="v | formatKey"/>
                </ng-template>
              </mat-form-field>
              <!-- <button mat-raised-button (click)="reset()">Reset</button> -->
            </form>
            <button mat-raised-button (click)="submitVrt()">Submit</button>
          } @else {
            <p class="disclaimerSimilar">
              We were not able to find an exact match for the vehicle in the ROS index. This can happen if this
              particular model has not been allocated a statistical code by ROS yet.
              <br>
              <br>
              You can attempt to edit the parameters in order to estimate the VRT:
              <br>
              <br>
              <button mat-raised-button color="primary" (click)="editInputs()"
                      matTooltip="You can choose to modify the parameters of the VRT calculation.">Edit parameters and
                re-calculate
                <mat-icon>edit</mat-icon>
              </button>
              @if (similar.data.length > 0) {
                <br>
                <br>
                Or you can peruse the list below. It consists of imperfect matches, that may or may not be representative of the VRT due for the vehicle you are probing. If you think one of the matches below can be representative, you can select it to re-run the VRT calculation using the statistical code for that version.
              }
            </p>
            @if (similar.data.length > 0) {
              <table mat-table [dataSource]="similar">
                <ng-container matColumnDef="statCode">
                  <th mat-header-cell *matHeaderCellDef>Stat code</th>
                  <td mat-cell *matCellDef="let element" class="strong">{{ element.statCode }}</td>
                </ng-container>
                <ng-container matColumnDef="model">
                  <th mat-header-cell *matHeaderCellDef>Model</th>
                  <td mat-cell *matCellDef="let element">{{ element.model }}</td>
                </ng-container>
                <ng-container matColumnDef="version">
                  <th mat-header-cell *matHeaderCellDef>Version</th>
                  <td mat-cell *matCellDef="let element">{{ element.version }}</td>
                </ng-container>
                <ng-container matColumnDef="transmissionType">
                  <th mat-header-cell *matHeaderCellDef>Transmission</th>
                  <td mat-cell *matCellDef="let element">{{ element.transmissionType | transmissionType }}</td>
                </ng-container>
                <ng-container matColumnDef="numberOfDoors">
                  <th mat-header-cell *matHeaderCellDef>Doors</th>
                  <td mat-cell *matCellDef="let element">{{ element.numberOfDoors }}</td>
                </ng-container>
                <ng-container matColumnDef="engineType">
                  <th mat-header-cell *matHeaderCellDef>Engine type</th>
                  <td mat-cell *matCellDef="let element">{{ element.engineType | engineType }}</td>
                </ng-container>
                <ng-container matColumnDef="engineCapacity">
                  <th mat-header-cell *matHeaderCellDef>Engine capacity</th>
                  <td mat-cell *matCellDef="let element">{{ element.engineCapacity }}</td>
                </ng-container>
                <ng-container matColumnDef="bodyType">
                  <th mat-header-cell *matHeaderCellDef>Body</th>
                  <td mat-cell *matCellDef="let element">{{ element.bodyType | bodyType }}</td>
                </ng-container>
                <ng-container matColumnDef="euClassification">
                  <th mat-header-cell *matHeaderCellDef>EU Class</th>
                  <td mat-cell *matCellDef="let element">{{ element.euClassification }}</td>
                </ng-container>
                <ng-container matColumnDef="wltpco2">
                  <th mat-header-cell *matHeaderCellDef>CO<sub>2</sub></th>
                  <td mat-cell *matCellDef="let element">{{ element.wltpco2 }} g/km</td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let element; columns: displayedColumns;"
                    (click)="selectStatCode(element.statCode)" class="clickable"
                    [ngClass]="{masked: element.masked}"></tr>
              </table>
            }
          }
        } @else if (vehicleData.vrtDetails) {
          <img
            ngSrc="/assets/icons/vrt.svg"
            width="100"
            height="100"
            class="section-img"
            alt="VRT"/>
          <p class="emphasized">
            {{ vehicleData.vrtDetails.vrtEuro | currency: "EUR" : "symbol" : "1.0-0" : "en" }}
          </p>
          <p class="section-text">This is the calculated VRT amount for your vehicle import
            @if (anyFootnote) {
              *
            }</p>
          @if (evExempt) {
            <!-- This is an EV within the exemptions-->
            <p class="section-footnote">* As this is an Electric Vehicle registered before 31st Dec 2025 it is
              eligible for VRT relief up to a maximum of € 5,000 dependent on the OMSP of the vehicle. <a
                (click)="evFootnote.hidden = false">Click here to read more</a>.</p>
            <div class="prodCard footnote" #evFootnote [hidden]="true">
              <h4>VRT relief for EVs</h4>
              <p>As per Revenue.ie series production passenger cars or commercial vehicles (VRT categories A and B)
                that are:</p>
              <ul>
                <li>powered only by an electric motor</li>
                <li>registered before 31 December 2025</li>
              </ul>
              <p>are eligible for Relief from VRT up to a maximum amount of € 5,000.</p>
              <p>Vehicles with an Open Market Selling Price (OMSP) of up to € 40,000 will be granted relief of up to €
                5,000. Vehicles with an OMSP of greater than € 40,000 but less than € 50,000 will receive a reduced
                level of relief. Reliefs have been removed for any electric vehicles above € 50,000.</p>
            </div>
          } @else if (vehicleData.vrtDetails.source == 'tcs-estimated-omsp') {
            <div class="prodCard footnote warning">
              <h4>* Estimated Open Market Sales Price (OMSP)</h4>
              <p>We do not have the official OMSP from ROS for this particular vehicle. We have therefore provided an
                Estimate for this VRT calculation.</p>
              <p>Although we strive to provide an accurate estimate, it is still an estimate and as such, it could
                have a margin of error of circa 15% and perhaps even greater compared to the official OMSP determined
                by ROS. This stems back to the ROS framework and parameter within which we all must operate – which
                aren’t always ideal. We therefore suggest you rigorously review other highly comparable Makes, Models
                and Versions from a VRT and overall costing perspective and perform as much due diligence as
                physically possible prior to proceeding with this vehicle as significantly great caution must be
                applied for your own benefit.</p>
            </div>
          }
          @if (vehicleData.vrtDetails.maxNox) {
            <div class="prodCard footnote warning">
              <h4>* Max NOx value for Euro Class</h4>
              <p>We could not find the precise NOx value for this car, and have therefore used the theoretical max
                value permitted by the vehicle's Euro Class.</p>
              <p>The real value may be lower. However we prefer to err on the side of caution when it comes to all
                things cost related.</p>
            </div>
          }
          @if (showVrtDetails) {
            <app-vrt-data [data]="vehicleData.vrtDetails"></app-vrt-data>
            <br>
            <button mat-raised-button color="primary" (click)="editInputs()"
                    matTooltip="You can choose to modify the parameters of the VRT calculation.">Edit parameters and
              re-calculate
              <mat-icon>edit</mat-icon>
            </button>
          } @else {
            <button (click)="showVrtDetails = true" mat-raised-button color="primary">
              Show details
            </button>
          }
        } @else {
          <p class="motivational-text">Attempting to calculate the VRT.</p>
          <mat-progress-spinner mode="indeterminate" diameter="50"></mat-progress-spinner>
        }
      </div>
    </mat-expansion-panel>

    <!-- VAT SECTION -->
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header>
        <div class="expansion-header-content">
          <h3>Value-Added Tax (VAT)</h3>
          <p *ngIf="importVat" class="header-info">
            {{ importVat | currency: "EUR" : "symbol" : "1.0-0" : "en" }}
          </p>
        </div>
      </mat-expansion-panel-header>
      <div class="section">
        <p class="note">
          Note: Vehicles brought into the State from the UK are subject to Value-Added Tax (VAT).
        </p>
        @if (!vehicleData.vehicleDetails?.priceGBP) {
          <div class="report-error">
            <mat-icon aria-hidden="false" aria-label="error" fontIcon="error"></mat-icon>
            <p class="error-text">
              We require the purchase price of the vehicle in GBP to calculate the VAT.
            </p>
          </div>
          <ng-container *ngTemplateOutlet="priceForm"></ng-container>
        } @else {
          <img
            ngSrc="/assets/icons/vat.svg"
            width="124"
            height="124"
            class="section-img"
            alt="Vat"/>
          <p class="emphasized">
            {{ importVat | currency: "EUR" : "symbol" : "1.0-0" : "en" }}
          </p>
          <p class="section-text">This is the calculated VAT amount for your vehicle import.</p>
        }
      </div>
    </mat-expansion-panel>
    <!-- CUSTOMS DUTY SECTION -->
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header>
        <div class="expansion-header-content">
          <h3>Customs Duty</h3>
          <p *ngIf="vehicleData.customsDetails?.rate !== undefined" class="header-info">
            {{ fcfService.customsDuty(fcfData) | currency: "EUR" : "symbol" : "1.0-0" : "en" }}
          </p>
        </div>
      </mat-expansion-panel-header>
      <div class="section">
        <p class="note">
          Note: {{ vehicleData.customsDetails?.comment ?? 'Vehicles brought into the State from the UK may be subject to customs duty.' }}
        </p>
        @if (customsError) {
          <div class="report-error">
            <mat-icon aria-hidden="false" aria-label="error" fontIcon="error"></mat-icon>
            <p class="error-text">
              {{ customsError }}
            </p>
          </div>
          @if (!vehicleData.vehicleDetails?.priceGBP) {
            <ng-container *ngTemplateOutlet="priceForm"></ng-container>
          } @else if (countries) {
            <form [formGroup]="missingCoMForm">
              <mat-form-field>
                <mat-label>Country of Manufacture</mat-label>
                <mat-select (selectionChange)="getCustoms($event)">
                  @for (country of countries; track country) {
                    <mat-option [value]="country">{{ country }}</mat-option>
                  }
                </mat-select>
              </mat-form-field>
            </form>
          }
        } @else if (customsDuty !== null) {
          <img
            ngSrc="/assets/icons/customs.svg"
            width="124"
            height="124"
            class="section-img"
            alt="Customs"/>
          <p class="emphasized">
            {{ customsDuty | currency: "EUR" : "symbol" : "1.0-0" : "en" }}
          </p>
          <p class="section-text">
            This is the calculated Customs Duty at a Rate of
            {{ fcfData.customsRate | percent: "1.0-0" }}
          </p>
        } @else {
          <p class="motivational-text">Attempting to calculate the Customs Duty.</p>
          <mat-progress-spinner mode="indeterminate" diameter="50"></mat-progress-spinner>
        }
      </div>
    </mat-expansion-panel>

    <!-- TOTAL SECTION -->

    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header>
        <div class="expansion-header-content">
          <h3>Total Estimated Costs</h3>
          <p class="header-info">
            @if (totalsComponent) {
              {{totalsComponent.total | currency: "EUR" : "symbol" : "1.0-0" : "en" }}
            } @else {
              ...
            }
          </p>
        </div>
      </mat-expansion-panel-header>
      <div class="section total-section">
        <h3>Costs Breakdown</h3>
      @if (totalsLoading || vrtLoading || customsLoading || postCodeLoading || !vehicleData.vrtDetails?.vrtEuro) {
        Complete the VRT calculation to see the cost breakdown.
      } @else {
        <app-ccq-totals [fcfDataInput]="fcfData" #totalsComponent></app-ccq-totals>
        <p class="note">
          Currency conversion rate EUR/GBP
          {{ currency.rates["gbp"] | number: "1.3-3" : "en" }}
        </p>
        <div class="buttonRow">
          <button mat-raised-button color="primary" (click)="getQuote(totalsComponent.fcfData)">Get quote</button>
          <!--            TODO add email me this report button -->
        </div>
      }
      </div>
    </mat-expansion-panel>


    <ng-template #priceForm>
      <form [formGroup]="missingPriceForm" class="missing-price-form">
        <mat-form-field>
          <mat-label>Price (GBP)</mat-label>
          <input matInput type="number" formControlName="priceGBP"/>
        </mat-form-field>
        <button mat-raised-button (click)="submitPrice()">Submit value</button>
      </form>
    </ng-template>
  </div>
  <div class="printContainer">
    <button mat-raised-button color="primary" (click)="printReport()">
      Print this report
      <mat-icon>print</mat-icon>
    </button>
  </div>
  <div class="disclaimer-container">
    <app-disclaimer></app-disclaimer>
    <button class="bug" mat-raised-button color="primary"
            (click)="bug.report('Calculate Cars Quicker', {vehicleData: vehicleData})">
      <mat-icon>flag</mat-icon>
      Report an issue with this report
    </button>
  </div>

}
