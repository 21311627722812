<h3 mat-dialog-title>Show/Hide Columns</h3>
<mat-dialog-content class="custom-dialog-content">
  <!-- Instructions -->
  <p class="instructions">
    Tick the columns you want to display. When you click Apply, your preferences will be saved in the browser for next time.
  </p>

  <div class="filtersContainer">
    <!-- Display availableColumns with checkboxes for toggling visibility -->
    <div *ngFor="let column of availableColumns" class="checkboxContainer">
      <mat-checkbox [(ngModel)]="column.visible">
        {{ column.name | fcfCols }}
      </mat-checkbox>
    </div>

    <div *ngIf="!availableColumns.length">
      <p>No columns available to show or hide.</p>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button  (click)="close()">Cancel</button>
  <button mat-raised-button (click)="resetColumns()">Reset column order and visibility</button>
  <button mat-raised-button color="primary" (click)="save()">Apply</button>
</mat-dialog-actions>
