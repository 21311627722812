import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class DisclaimerService {
  private localStorageKey = 'disclaimers';
  public disclaimers: any = {
    "fcf": false,
    "cyc": false,
    "ccq": false
  };
  constructor(private apiService: ApiService) {
    this.load();
   }
  async load() {
    try {
      // Load from local storage first
      const storedDisclaimers = localStorage.getItem(this.localStorageKey);
      if (storedDisclaimers) {
        this.disclaimers = JSON.parse(storedDisclaimers);
      } else {
        // If not found in local storage, load from API
        const disclaimersFromApi = await this.apiService.getDisclaimers();
        this.disclaimers = { ...this.disclaimers, ...disclaimersFromApi };
        // Save to local storage
        localStorage.setItem(this.localStorageKey, JSON.stringify(this.disclaimers));
      }
    } catch (error) {
      console.error("Failed to load disclaimers:", error);
    }
  }
  public save() {
    localStorage.setItem(this.localStorageKey, JSON.stringify(this.disclaimers));
    this.apiService.SaveDisclaimers(this.disclaimers);
  }
  public set(service: "fcf" | "ccq" | "cyc") {
    this.disclaimers[service] = true;
    this.save();
  }
  public get(service: "fcf" | "ccq" | "cyc"):boolean {
      return this.disclaimers[service];
  }
}
