import {Component, ElementRef, Inject, Input, ViewChild} from "@angular/core";
import {
    FormBuilder, FormControl, FormGroup, ReactiveFormsModule, ValidatorFn, Validators,
} from "@angular/forms";
import {ApiService} from "../../../services/api.service";
import {CurrencyService} from "../../../services/currency.service";
import {LOCAL_STORAGE, StorageService} from "ngx-webstorage-service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatDialog} from "@angular/material/dialog";
import {
    MatLabel, MatOption, MatSelectChange, MatSelectModule,
} from "@angular/material/select";
import {
    CurrencyPipe,
    DecimalPipe,
    KeyValue,
    KeyValuePipe,
    NgClass,
    NgForOf,
    NgIf,
    NgOptimizedImage,
    NgTemplateOutlet,
    PercentPipe,
    ViewportScroller,
} from "@angular/common";
import {
    MatCell,
    MatCellDef,
    MatColumnDef,
    MatHeaderCell,
    MatHeaderCellDef,
    MatHeaderRow,
    MatHeaderRowDef,
    MatRow,
    MatRowDef,
    MatTable,
    MatTableDataSource,
} from "@angular/material/table";
import {
    MatAccordion, MatExpansionPanel, MatExpansionPanelHeader,
} from "@angular/material/expansion";
import {VehicleDataComponent} from "../../../components/tables/vehicle-data/vehicle-data.component";
import {MatIcon} from "@angular/material/icon";
import {DisclaimerComponent} from "../../../components/disclaimer/disclaimer.component";
import {VrmSearchComponent} from "../../../components/vrm-search/vrm-search.component";
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {VrtDataComponent} from "../../../components/tables/vrt-data/vrt-data.component";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInput, MatInputModule} from "@angular/material/input";
import {MatButton, MatButtonModule} from "@angular/material/button";
import {formatKey} from "../../../pipes/format-key.pipe";
import {MatTooltipModule} from "@angular/material/tooltip";
import {TransmissionTypePipe} from "../../../pipes/transmission-type.pipe";
import {EngineTypePipe} from "../../../pipes/engine-type.pipe";
import {BodyTypePipe} from "../../../pipes/body-type.pipe";
import {CcqTotalsComponent} from "../../../components/tables/ccq-totals/ccq-totals.component";
import {QuoteComponent} from "../../../components/dialogs/quote/quote.component";
import {HttpErrorResponse} from "@angular/common/http";
import {ManualVrtComponent} from "../../../components/dialogs/manual-vrt/manual-vrt.component";
import {VehicleDetails} from "../ccq/ccq.model.consts";
import {QuotesService} from "../../../services/quotes.service";
import {BugService} from "../../../services/bug.service";
import {Messages} from "../../../messages";
import {VinRevealService} from "../../../services/vin-reveal.service";
import {FeedbackService} from "../../../services/feedback.service";
import {FcfService, VRT} from "../../../services/fcf.service";
import {AdditionalCostsService} from "../../../services/additional-costs.service";
import {vatRateUK} from "../../../services/locale.service";

interface VehicleData {
    vehicleDetails?: any;
    vrtDetails?: any;
    vatDetails?: any;
    customsDetails?: {
      rate: number;
      cost: number;
      comment: string;
    };
    // transportDetails?: any;
}

interface StatCode {
    statCode: string;
    version?: string | null;
    make?: string | null;
    model?: string | null;
    transmissionType?: string | null;
    numberOfDoors?: string | null;
    engineType?: string | null;
    bodyType?: string | null;
    engineCapacity?: string | null;
    euClassification?: "M1" | "N1" | "L3" | null;
    firstregisteredInJapan: string;
    wltpco2?: string | null;
}

@Component({
    selector: "app-ccq-logic",
    standalone: true,
    imports: [MatFormFieldModule, MatInputModule, MatSelectModule, ReactiveFormsModule, NgForOf, NgClass, KeyValuePipe, NgIf, MatButtonModule, MatProgressSpinner, DecimalPipe, formatKey, NgOptimizedImage, MatLabel, MatInput, MatButton, MatExpansionPanel, MatExpansionPanelHeader, NgOptimizedImage, MatTooltipModule, MatAccordion, KeyValuePipe, NgTemplateOutlet, MatIcon, CurrencyPipe, MatTable, MatCell, MatCellDef, MatColumnDef, MatHeaderCell, MatHeaderCellDef, MatHeaderRow, MatHeaderRowDef, MatRow, MatRowDef, TransmissionTypePipe, EngineTypePipe, BodyTypePipe, PercentPipe, DisclaimerComponent, VehicleDataComponent, VrtDataComponent, CcqTotalsComponent,],
    templateUrl: "./ccq-logic.component.html",
    styleUrl: "./ccq-logic.component.scss",
})
export class CcqLogicComponent {

    public vrtError: string | undefined;
    public customsError: string | undefined;
    public transportError: string | undefined;
    @ViewChild("submit") submit: ElementRef | undefined;
    @ViewChild("vrmSearch") vrmSearch: VrmSearchComponent | undefined;
    @ViewChild("totalsComponent") totalsComponent: CcqTotalsComponent | undefined;
    detailsLoading = true;
    vrtLoading = true;
    customsLoading = false;
    postCodeLoading = false;
    totalsLoading = true;
    public missingVariables: string[] = [];
    public missingOptions: any = {};
    vehicleData: VehicleData = {
        vehicleDetails: undefined, vrtDetails: undefined, vatDetails: undefined, customsDetails: undefined,
    };
    @ViewChild("reportContainer", {static: false}) reportContainer: ElementRef | undefined;
    showVrtDetails: boolean = false;

    displayedColumns = ["statCode", "version", "model", "transmissionType", "numberOfDoors", "engineType", "bodyType", "engineCapacity", "euClassification", "wltpco2",];
    @Input() showDetails: boolean = true; // Whether to show the car details
    protected readonly Number = Number;
    protected searching = false;
    protected motivationalText: string | undefined;
    protected missingVarsForm: FormGroup = new FormGroup([]);
    protected missingCoMForm!: FormGroup;
    protected missingPriceForm!: FormGroup;
    protected transportFormGroup: FormGroup;
    protected similar = new MatTableDataSource<StatCode[]>([]);
    protected countries: string[] | undefined;
    private manuallySelectedStatCode: number | null = null;

    constructor(
      private apiService: ApiService,
     @Inject(LOCAL_STORAGE) private storage: StorageService,
     private snack: MatSnackBar,
     private dialog: MatDialog,
     protected quotesService: QuotesService,
     protected bug: BugService,
     protected currency: CurrencyService,
     private vinRevealService: VinRevealService,
     private feedback: FeedbackService,
     public scroller: ViewportScroller,
      private additionalCostService: AdditionalCostsService,
      protected fcfService: FcfService,
    ) {
        // this.searchControl = new FormControl({value: '', disabled: this.searching});
        this.transportFormGroup = new FormGroup({
            uk: new FormControl("", { validators: [Validators.required, this.validateUKPostCode()] }),
            ie: new FormControl(this.getpostCode(), {
                validators: [Validators.required, this.validateIrishPostCode()],
            }),
        });
        this.missingVarsForm = new FormGroup([]);
        this.missingCoMForm = new FormGroup({
            countryOfManufacture: new FormControl(null, [Validators.required]),
        });
        this.missingPriceForm = new FormGroup({
            priceGBP: new FormControl("", [Validators.required]),
        });
    }
    private getpostCode(): string | undefined {
        return this.storage.get("IEpostCode");
    }
    public get evExempt(): boolean {
        return (this.vehicleData.vrtDetails?.vrtEuro == "0" && this.vehicleData.vehicleDetails.engineType == "3");
    }

    public get anyFootnote(): boolean {
        return (this.evExempt || this.vehicleData?.vrtDetails?.source == "tcs-estimated-omsp" || this.vehicleData?.vrtDetails?.source == "tcs-prestige");
    }

    async search(vrm: string): Promise<void> {
      this.reset(); // Make sure we reset before performing a new search
        try {
            vrm = vrm.toUpperCase().replace(/\s+/g, "");
            this.searching = true;
            this.motivationalText = "Looking for vehicle...";
            // this.reset();

            const searchResponse = await this.apiService.search(vrm);
            this.motivationalText = "Vehicle found!";

            const detailsResponse = await this.apiService.getDetails("uk", searchResponse.data.id);
            this.vehicleData.vehicleDetails = detailsResponse.data;

            await this.getVrt(detailsResponse.data.id);

            // if (this.transportFormGroup.valid) {
            //     await this.getTransport();
            // }

            this.searching = false;
        } catch (error: any) {
            console.error(error);
            this.vrtError = error.error?.msg ?? error.message;
            this.searching = false;
        }
    }

    /**
     * Request a VRT calculation from the API
     * Valid types are:
     * 'auto' => the default, which attempts to match the vehicle automatically using all available sources
     * 'missing' => used to provide missing data points like mileage, price etc. Other fields can still be autmatically sourced
     * 'manual' => a manual VRT calculation using only the provided data points
     * 'statCode' => User has selected a vehicle from the table of close matches
     * @param vehicle_id
     * @param data
     * @param type
     */
    public async getVrt(vehicle_id: string, data: {} | null = null, type: string | null = null) {
        this.motivationalText = Messages.attemptVRT;
        this.vrtLoading = true;
        this.vrtError = undefined;
        this.missingOptions = {};
        if (this.manuallySelectedStatCode) {
            type = "statCode";
            let d: any = data ?? {};
            d["statCode"] = this.manuallySelectedStatCode;
            data = d;
        }
        const response = await this.apiService.getVrt(vehicle_id, data, type).catch(e => {
            console.error(e);
            let errorMsg = e.error?.msg ?? e.message;
            if (!e.status) {
                // No connectivity
                errorMsg = Messages.connectionError;
            }
            this.vrtError = errorMsg;
            return;
        });

        if (response?.success) {
            this.vrtError = undefined;
            this.vehicleData.vrtDetails = response.data;
        } else {
            this.vrtError = response?.msg;
            const res = response?.data;
            if (res?.missingVariables) {
                for (const missingVariable of res.missingVariables) {
                    this.missingVarsForm?.addControl(missingVariable, new FormControl(), {
                        emitEvent: false,
                    });
                }
                this.missingVariables = res.missingVariables;
            } else if (res?.similar?.length) {
                // We got similar models
                this.similar = new MatTableDataSource(res.similar);
            }
            this.missingOptions = res?.options;
        }
        this.vrtLoading = false;
        await this.feedback.trigger(this.vehicleData.vehicleDetails?.registrationNumber ?? this.vehicleData.vehicleDetails?.registrationNumber_ie, "ccq");
        if (!this.vehicleData.customsDetails) {
            await this.getCustoms();
        }
    }


    public reset() {
        this.vrmSearch?.resetError();
        this.manuallySelectedStatCode = null;
        this.vrtLoading = true;
        this.detailsLoading = true;
        this.postCodeLoading = false;
        this.totalsLoading = true;
        this.showVrtDetails = false;
        this.similar = new MatTableDataSource<StatCode[]>([]);
        this.vehicleData = {
            vrtDetails: undefined, vehicleDetails: undefined, vatDetails: undefined, customsDetails: undefined,
        };

        // this.vehicleData.transportDetails = undefined;

        this.vrtError = undefined;
        this.transportError = undefined;

        this.missingVariables = [];
        this.missingOptions = {};
        this.missingVarsForm = new FormGroup([]);
        this.missingPriceForm.reset();
        this.vinRevealService.showVin = false;
    }

    /**
     *  Get a new VAT / Customs calculation with the new price
     */
    async submitPrice() {
        if (!this.vehicleData.vehicleDetails) {
            this.vehicleData.vehicleDetails = {
                priceGBP: this.missingPriceForm?.get("priceGBP")?.value,
            };
        } else {
            this.vehicleData.vehicleDetails.priceGBP = this.missingPriceForm?.get("priceGBP")?.value;
        }
        await this.getCustoms();
    }

    getQuote(data: VRT | undefined) {
        this.dialog.open(QuoteComponent, {
            data: {
                fcfData: data,
            },
        });
    }

    async printReport() {
        const resp = await this.apiService
            .pdf("ccq", this.vehicleData, this.vehicleData.vehicleDetails.registrationNumber ?? this.vehicleData.vehicleDetails.registrationNumber_ie)
            .catch((e: HttpErrorResponse) => {
                console.error(e);
                this.snack.open(e.message ?? e.error, "Dismiss", {
                    duration: 5000, panelClass: ["snack-error"],
                });
            });
        if (resp?.msg) {
            this.snack.open(resp.msg, "Dismiss", {duration: 5000, panelClass: ["snack-error"]});
        }
    }

    resetTransport() {
        // this.storage.remove("IEpostCode");
        // this.vehicleData.transportDetails = undefined;
    }

    public editInputs() {
        this.dialog.open(ManualVrtComponent, {
            data: this,
        });
    }

    selectStatCode(statCode: number) {
        this.manuallySelectedStatCode = statCode;
        return this.getVrt(this.vehicleData.vehicleDetails["id"], this.vehicleData.vehicleDetails);
    }

    submitVrt() {
        let data = this.missingVarsForm?.value;
        if (!this.vehicleData.vehicleDetails && this.vehicleData.vrtDetails) {
            // This is a manual VRT calc
            // Merge in the data
            data = {...this.vehicleData.vrtDetails, ...data};
        }
        this.getVrt(this.vehicleData.vehicleDetails?.id, data, "missing");
        // Update the details
        if (this.vehicleData.vehicleDetails) {
            for (let key in this.missingVarsForm?.value) {
                (this.vehicleData.vehicleDetails[key as keyof VehicleDetails] as any) = this.missingVarsForm.get(key)?.value;
            }
        }
    }

    /** Order by ascending property value **/
    valueAscOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
        return String(a.value).localeCompare(String(b.value));
    };

    protected async getCustoms($event: MatSelectChange | null = null) {
        this.motivationalText = Messages.attemptCustoms;
        const {vehicleDetails, vrtDetails} = this.vehicleData;

        if (!vehicleDetails?.priceGBP) {
            this.customsLoading = false;
            this.customsError = Messages.noPriceOnFile;
            return;
        }
        if ($event) {
            vehicleDetails.countryOfManufacture = $event.value;
        }
        if (!vehicleDetails.yearOfRegistration && vrtDetails?.yearOfRegistration) {
            vehicleDetails.yearOfRegistration = vrtDetails.yearOfRegistration;
            vehicleDetails.monthOfRegistration = vrtDetails.monthOfRegistration;
        }
        this.customsLoading = true;
        this.customsError = undefined;
        await this.apiService
            .getCustoms(vehicleDetails)
            .then(response => {
                if (response.success) {
                    this.vehicleData.customsDetails = response.data;
                    this.customsError = undefined;
                } else {
                    this.customsError = response.msg;
                    if (response.data["options"]["countryOfManufacture"]) {
                        this.countries = response.data["options"]["countryOfManufacture"];
                    }
                }
            })
            .catch(error => (this.customsError = error.message));

        this.customsLoading = false;
        this.totalsLoading = false;
    }

    // protected async getTransport() {
    //     if (this.postCodeLoading) {
    //         return;
    //     }
    //     this.transportError = undefined;
    //     if (this.transportFormGroup.invalid) {
    //         this.transportError = Messages.invalidPostcodes;
    //         return;
    //     }
    //     this.motivationalText = Messages.attemptTransport;
    //
    //     this.postCodeLoading = true;
    //     await this.apiService
    //         .getTransport(this.transportFormGroup.value)
    //         .then(response => {
    //             if (response.success) {
    //                 this.vehicleData.transportDetails = response?.data;
    //                 // Update this in additional costs
    //               this.additionalCostService.saveForSpecificVehicle('shippingCost', this.vehicleData.vehicleDetails.id, this.vehicleData.transportDetails.cost);
    //             }
    //         })
    //         .catch(e => {
    //             let errorMsg = e.error?.msg ?? e.message;
    //             if (!e.status) {
    //                 // No connectivity
    //                 errorMsg = Messages.connectionError;
    //             }
    //             this.transportError = errorMsg;
    //         });
    //     this.postCodeLoading = false;
    //     if (this.transportFormGroup.get("ie")?.valid) {
    //         // this.storage.set('UKpostCode', this.UKpostCodeControl.value);
    //         this.storage.set("IEpostCode", this.transportFormGroup.get("ie")?.value);
    //     }
    // }

    protected async changeVrtMissingForm(ev: MatSelectChange) {
        const {vehicleDetails} = this.vehicleData;

        // Update the search result
        // console.log(ev.source.selected);
        if (vehicleDetails) {
            const key = ev.source.ngControl.name as keyof VehicleDetails;

            if (key) {
                (vehicleDetails[key] as any) = ev.value;
            }
            // This limits the choice of statcodes after
            if (key === "statCode" && !vehicleDetails.model) {
                // We can also get the model
                const option = <MatOption>ev.source.selected;
                let label = option._text?.nativeElement.textContent;
                if (label) {
                    label = label.split(" - ")[0];
                    vehicleDetails.model = label;
                }
            }

            // Update the options accordingly
            try {
                const r = await this.apiService.getOptions(vehicleDetails);
                this.missingOptions = r.data;
                // Select single vals
                if (this.missingVariables) {
                    for (let v of this.missingVariables) {
                        if (this.missingOptions[v] && Object.keys(this.missingOptions[v]).length === 1) {
                            // There's only one option
                            const val: any = Object.keys(this.missingOptions[v])[0];
                            // @ts-ignore
                            this.missingVarsForm.get(v)?.setValue(val, {emitEvent: false});
                        }
                    }
                }
            } catch (e) {
                console.error(e);
            }
        }
    }

    private validateUKPostCode(): ValidatorFn {
        return (control: any): { [key: string]: string } | null => {
            const regex = /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/;
            return control.value?.match(regex) ? null : {invalidPostCode: "Invalid UK Postcode"};
        };
    }

    private validateIrishPostCode(): ValidatorFn {
        return (control: any): { [key: string]: string } | null => {
            const regex = /(?:^[AC-FHKNPRTV-Y][0-9]{2}|D6W)[ -]?[0-9AC-FHKNPRTV-Y]{4}$/;
            return control.value?.match(regex) ? null : {invalidPostCode: "Invalid Irish Postcode"};
        };
    }

    protected get fcfData(): VRT {
      // Check if there's any import fee or shipping defined for this vehicle
      const priceEurExVat = this.currency.convert(this.currency.gbp,this.currency.eur,this.vehicleData.vehicleDetails.priceGBP / (1 + vatRateUK));
      const finalVatAdjustment = this.additionalCostService.importFee(this.vehicleData.vehicleDetails.id,priceEurExVat);
      const houseMiscCharge = this.additionalCostService.houseMiscCharge(this.vehicleData.vehicleDetails.id, this.vehicleData.vehicleDetails.make, this.vehicleData.vehicleDetails.model);
      const shippingCost = this.additionalCostService.shipping(this.vehicleData.vehicleDetails.id, null, this.vehicleData.vehicleDetails.make, this.vehicleData.vehicleDetails.model);

      // Format the data as VRT
      return { ...this.vehicleData.vrtDetails,
        ...this.vehicleData.vehicleDetails,
        shippingEur: shippingCost,
        customsRate: this.vehicleData.customsDetails?.rate,
        vatAdjustment: finalVatAdjustment,
        houseMiscCharge: houseMiscCharge,
      } as VRT;
    }
    get importVat(): number | null {
      return this.fcfService.importVat(this.fcfData);
    }
    get customsDuty(): number | null {
      return this.fcfService.customsDuty(this.fcfData);
    }
}
