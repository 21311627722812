<div class="prodCard">
  <div>
    <mat-form-field>
      <mat-label>User Activity in the past</mat-label>
      <mat-select [(value)]="days" (selectionChange)="loadUserActivity()">
        @for (days of [7, 14, 30, 45, 60, 90, 180, 365]; track days) {
          <mat-option [value]="days">{{ days }} days</mat-option>
        }
      </mat-select>
    </mat-form-field>
  </div>

  <mat-progress-spinner mode="indeterminate" diameter="50" *ngIf="loading"></mat-progress-spinner>

  <table mat-table [dataSource]="userActivity" matSort>
    <!-- User Column -->
    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>User</th>
      <td mat-cell *matCellDef="let element">{{ element.name }} ({{ element.email }})</td>
    </ng-container>

    @for (service of services; track $index) {
      <ng-container [matColumnDef]="service">
        <th class="right" mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ service | uppercase }}
        </th>
        <td class="" mat-cell *matCellDef="let element">
          {{ element[service] | number }}
        </td>
      </ng-container>
    }

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let element; columns: displayedColumns;"></tr>
  </table>
</div>
