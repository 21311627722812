import {Component, Inject} from "@angular/core";
import {FormControl, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatButton} from "@angular/material/button";
import {
  MAT_DIALOG_DATA, MatDialogActions, MatDialogContent, MatDialogRef, MatDialogTitle,
} from "@angular/material/dialog";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatRadioModule} from "@angular/material/radio";
import {CommonModule} from "@angular/common";
import {VRT} from "../../../services/fcf.service";
import {LOCAL_STORAGE, StorageService} from "ngx-webstorage-service";
import {AdditionalCostsService} from "../../../services/additional-costs.service";
import {CurrencyService} from "../../../services/currency.service";

@Component({
  selector: "app-vat-adjustment-dialog",
  standalone: true,
  imports: [MatButton, MatDialogContent, MatDialogActions, MatDialogTitle, MatFormFieldModule, MatInputModule, FormsModule, MatRadioModule, CommonModule, ReactiveFormsModule,],
  templateUrl: "./importation-fee-dialog.component.html",
  styleUrls: ["./importation-fee-dialog.component.scss"],
})
export class ImportationFeeDialog {
  adjustmentType: "fixed" | "percentage" = "fixed"; // Default adjustment type
  importFeeController: FormControl;
  importFeePercentAdjustmentController : FormControl;
  currencyControl: FormControl = new FormControl('eur');
  constructor(private currencyService: CurrencyService, protected dialogRef: MatDialogRef<ImportationFeeDialog>, @Inject(MAT_DIALOG_DATA) public data: VRT, @Inject(LOCAL_STORAGE) private storage: StorageService, protected additionaCostService: AdditionalCostsService) {
    this.importFeeController = new FormControl(this.data.vatAdjustment ?? 0);
    // Check if we have a preferred currency for the importation fee
    this.currencyControl.valueChanges.subscribe(value => {
      if (value == 'gbp') {
        this.importFeeController.setValue(this.currencyService.convert(this.currencyService.eur,this.currencyService.gbp,this.importFeeController.value,2));
      } else if (value == 'eur') {
        this.importFeeController.setValue(this.currencyService.convert(this.currencyService.gbp,this.currencyService.eur,this.importFeeController.value,2));
      }
    });
    // Retrieve the percentage, if set
    const percentAdjustVehicle = this.storage.get(`vatAdjustment_${this.data.id}_percentage`);
    const percentAdjustGeneral = this.storage.get(`vatAdjustment_general_percentage`);
    this.importFeePercentAdjustmentController = new FormControl(percentAdjustVehicle ?? percentAdjustGeneral ?? 0);

  }

  saveAdjustment(all?: boolean) {
    let key = all ? "vatAdjustment_general" : `vatAdjustment_${this.data.id}`;
    let value = this.importFeeController.value;
    if (this.currencyControl.value == 'gbp' && this.adjustmentType === 'fixed') {
      // We must always convert to EUR before storing a fixed value
      value = this.currencyService.convert(this.currencyService.gbp,this.currencyService.eur,this.importFeeController.value);
    }
    let rmKey;
    if (this.adjustmentType === 'percentage') {
      rmKey = key;
      value = this.importFeePercentAdjustmentController.value;
      key += "_percentage";
    } else {
      rmKey = key + "_percentage";
    }
    this.additionaCostService.saveForKey(key, value, this.dialogRef);
    this.additionaCostService.removeKey(rmKey); // We should always remove the counterpart
  }
}
