import {
  AfterViewInit,
  Component,
  OnInit,
  ViewChild,
} from "@angular/core";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import {
  ReactiveFormsModule,

} from "@angular/forms";
import {
  ViewportScroller,
} from "@angular/common";
import { MatButton, MatButtonModule } from "@angular/material/button";
import { MatSelectModule } from "@angular/material/select";
import { ActivatedRoute } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { VrmSearchComponent } from "../../../components/vrm-search/vrm-search.component";
import { MatTooltipModule } from "@angular/material/tooltip";
import { HeroBgService } from "../../../services/hero-bg.service";
import { FflContentComponent } from "../../../components/ffl-content/ffl-content.component";
import { UserActivityComponent } from "../../../components/user-activity/user-activity.component";
import { ProductsComponent } from "../../../components/products/products.component";
import { DisclaimerService } from "../../../services/disclaimer.service";
import { DisclaimerDialogComponent } from "../../../components/dialogs/disclaimer-dialog/disclaimer-dialog.component";
import { CcqLogicComponent } from "../ccq-logic/ccq-logic.component";

@Component({
  selector: "app-search",
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatButtonModule,
    VrmSearchComponent,
    MatButton,
    MatTooltipModule,
    FflContentComponent,
    UserActivityComponent,
    ProductsComponent,
    CcqLogicComponent
],
  templateUrl: "./ccq-home.component.html",
  styleUrl: "./ccq-home.component.scss",
})
export class CcqHomeComponent implements OnInit, AfterViewInit {

  @ViewChild("ccqLogic", {static: false}) ccqLogic!: CcqLogicComponent;
  protected searching = false;

  constructor(
    public scroller: ViewportScroller,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private hero: HeroBgService,
    private disclaimerService: DisclaimerService
  ) {
  }

  ngOnInit(): void {
    this.hero.setBg("/assets/img/race-car.jpg", "center 6%", undefined, "80%");
  }

  async ngAfterViewInit() {
    // Wait for the disclaimers to be loaded
    await this.disclaimerService.load();
    if (!this.disclaimerService.get("ccq")) {
      this.openDislaimersDialog("ccq");
    }
    this.route.params.subscribe(async (p) => {
      if (p["url"]) {
        this.searching = true;
        await this.ccqLogic.search(p["url"]);
        this.searching = false;
        this.scroller.scrollToAnchor("ccqLogicAnchor");
      }
    });
  }

  openDislaimersDialog(type: any): void {
    // Open the VatAdjustmentDialogComponent dialog
    const dialogRef = this.dialog.open(DisclaimerDialogComponent, {
      width: "600px", // Fixed dialog width
      data: { service: type },
    });
  }
}
