import { Component, Input, OnInit } from "@angular/core";
import { CurrencyPipe, DatePipe, NgIf, PercentPipe } from "@angular/common";
import { MatIcon } from "@angular/material/icon";
import { MatFormField, MatHint, MatSuffix } from "@angular/material/form-field";
import { MatInput } from "@angular/material/input";
import { FormsModule } from "@angular/forms";
import { MatTooltip } from "@angular/material/tooltip";
import { MatIconButton } from "@angular/material/button";
import { CurrencyService } from "../../../services/currency.service";
import { FcfService, VRT } from "../../../services/fcf.service";
import { FLAT_SHIPPING_FEE } from "../../../services/quotes.service";
import { ShippingCostDialogComponent } from "../../dialogs/shipping-cost-dialog/shipping-cost-dialog.component";
import { AdditionalCostsService } from "../../../services/additional-costs.service";
import { Observable } from "rxjs";
import { ImportationFeeDialog } from "../../dialogs/vat-adjustment-dialog/importation-fee-dialog.component";
import { vatRateIreland, vatRateUK } from "../../../services/locale.service";
import { HouseMiscChargeDialogComponent } from "../../dialogs/house-misc-charge-dialog/house-misc-charge-dialog.component";

@Component({
  selector: "app-ccq-totals",
  standalone: true,
  imports: [
    CurrencyPipe,
    MatIcon,
    MatFormField,
    MatInput,
    FormsModule,
    MatTooltip,
    MatHint,
    MatSuffix,
    MatIconButton,
    NgIf,
    PercentPipe,
    DatePipe,
  ],
  templateUrl: "./ccq-totals.component.html",
  styleUrl: "./ccq-totals.component.scss",
})
export class CcqTotalsComponent implements OnInit {
  @Input() fcfDataInput: VRT | undefined;
  public fcfData: VRT | undefined;
  customsRateAboutToChange: boolean = false;
  protected editingPrice: boolean = false;
  protected editingPriceZero: boolean = false;
  protected editingFinalCost: boolean = false;
  protected readonly Number = Number;
  protected readonly ShippingCostDialogComponent = ShippingCostDialogComponent;
  private fcfDataOriginal: VRT | undefined;

  public constructor(
    protected currencyService: CurrencyService,
    protected additionalCostsService: AdditionalCostsService
  ) {}

  /**
   * Commit the new purchase price and update other values accordingly
   */
  get totalExportPriceGBP(): number {
    return this.priceGBPExVat + this.importationFee + this.shippingGBP;
  }

  get totalExportPriceEUR(): number {
    return this.currencyService.convert(
      this.currencyService.gbp,
      this.currencyService.eur,
      this.totalExportPriceGBP
    );
  }

  get importationFee(): number {
    return this.currencyService.convert(
      this.currencyService.eur,
      this.currencyService.gbp,
      this.fcfData?.vatAdjustment || 0
    );
  }

  get shippingGBP(): number {
    return this.currencyService.convert(
      this.currencyService.eur,
      this.currencyService.gbp,
      Number(this.fcfData?.shippingEur ?? FLAT_SHIPPING_FEE)
    );
  }

  get customsBasisEUR(): number {
    return this.currencyService.convert(
      this.currencyService.gbp,
      this.currencyService.eur,
      this.priceGBPExVat + this.shippingGBP
    );
  }

  /**
   * Calculates the customs duty based on the customs rate and the converted value of the price and shipping costs in EUR.
   * We do not include importation fee in the custom basis
   *
   * @return {number} The computed customs duty amount.
   */
  get customsDuty(): number {
    return this.customsBasisEUR * this.customsRate;
  }

  get customsRate(): number {
    return Number(this.fcfData?.customsRate ?? 0.1);
  }

  get importVAT(): number {
    return (this.customsBasisEUR + this.customsDuty) * vatRateIreland;
  }

  get vat(): number {
    return this.currencyService.convert(
      this.currencyService.eur,
      this.currencyService.gbp,
      Number(this.fcfData?.vat || 0)
    );
  }

  /**
   * Cost of Vehicle with Shipping, Customs and Vat at Import
   */
  get costAtImport(): number {
    return this.totalExportPriceEUR + this.customsDuty + this.importVAT;
  }

  get total(): number {
    return Math.round(
      this.costAtImport + Number(this.fcfData?.vrtEuro) + Number(this.fcfData?.houseMiscCharge)
    );
  }

  get priceGBPExVat(): number {
    if (this.fcfData?.priceGBP) {
      return Math.round(this.fcfData.priceGBP / (1 + vatRateUK));
    }
    return 0;
  }

  ngOnInit() {
    this.fcfData = Object.assign({}, this.fcfDataInput);
    this.fcfDataOriginal = Object.assign({}, this.fcfDataInput); // Save the initial data, so as to reset
    this.customsRateAboutToChange = FcfService.customsRateAboutToChange(this.fcfData);
  }

  /**
   * Toggle editing mode for the purchase price
   */
  toggleEditPrice() {
    // Set a new purchase price
    this.editingPriceZero = false;
    this.editingFinalCost = false;
    this.editingPrice = !this.editingPrice;
  }

  toggleEditPriceZero() {
    this.editingPrice = false;
    this.editingFinalCost = false;
    this.editingPriceZero = !this.editingPriceZero;
  }

  toggleEditTotalCost() {
    this.editingPrice = false;
    this.editingPriceZero = false;
    this.editingFinalCost = !this.editingFinalCost;
  }

  editShipping() {
    if (!this.fcfData) {
      return;
    }
    const onDialogClose = <Observable<any>>(
      this.additionalCostsService.openEditDialog(this.fcfData, ShippingCostDialogComponent, true)
    );
    onDialogClose.subscribe(newValue => {
      // console.log("shipping dialog closed with: ", newValue);
      if (typeof newValue === "number") {
        // We have a new shipping cost
        if (this.fcfData) {
          this.fcfData.shippingEur = newValue;
        }
      }
    });
  }

  editImportFee() {
    if (!this.fcfData) {
      return;
    }
    const onDialogClose = <Observable<any>>(
      this.additionalCostsService.openEditDialog(this.fcfData, ImportationFeeDialog, true)
    );
    onDialogClose.subscribe(newValue => {
      if (typeof newValue === "number") {
        // We have a new import fee
        if (this.fcfData) {
          this.fcfData.vatAdjustment = newValue;
        }
      }
    });
  }

  updateZeroRatedPrice($event: Event) {
    const input = $event.target as HTMLInputElement;
    const priceZeroRated = Number(input.value);
    if (this.fcfData) {
      this.fcfData.priceGBP = priceZeroRated * (1 + vatRateUK);
    }
  }

  updateTotalCost($event: Event) {
    if (this.fcfData) {
      const input = $event.target as HTMLInputElement;
      const newTotalCost = Number(input.value);
      const vrtEuro = Number(this.fcfData?.vrtEuro ?? 0); // VRT in Euro

      // Reverse-calculate priceGBP to match the newTotalCost
      const vatAdjustment = this.currencyService.convert(
        this.currencyService.eur,
        this.currencyService.gbp,
        this.fcfData.vatAdjustment || 0
      );
      const shippingGBP = this.currencyService.convert(
        this.currencyService.eur,
        this.currencyService.gbp,
        Number(this.fcfData?.shippingEur ?? FLAT_SHIPPING_FEE)
      );
      const customsRate = Number(this.fcfData?.customsRate ?? 0.1);
      const conversionRateEURtoGBP = this.currencyService.getRate(this.currencyService.gbp);

      // Iterate until total converges (if necessary)
      let priceGBP = 0;
      const tolerance = 0.01; // Small tolerance value for convergence
      let calculatedTotal = 0;

      for (let i = 0; i < 1000; i++) {
        // Limit iterations to prevent infinite loop
        const priceGBPExVat = priceGBP / (1 + vatRateUK);
        const totalExportPriceGBP = priceGBPExVat + vatAdjustment + shippingGBP;
        const totalExportPriceEUR = totalExportPriceGBP / conversionRateEURtoGBP;
        const customsDuty = totalExportPriceEUR * customsRate;
        const importVAT = (totalExportPriceEUR + customsDuty) * vatRateIreland;
        const costAtImport = totalExportPriceEUR + customsDuty + importVAT;

        calculatedTotal = costAtImport + vrtEuro;

        // Adjust priceGBP if the total doesn't match
        const error = newTotalCost - calculatedTotal;
        if (Math.abs(error) < tolerance) break;

        // Adjust priceGBP based on the error
        priceGBP += error * conversionRateEURtoGBP;
      }

      this.fcfData.priceGBP = Math.round(priceGBP); // Commit the calculated priceGBP
    }
  }

  /**
   * Resets the total cost by restoring the original financial calculation data (fcfData)
   * to its initial state while preserving specific adjustments such as vatAdjustment
   * and shippingEur. This method also resets editing-related flags to false.
   *
   * @return {void} Does not return any value.
   */
  resetTotalCost(): void {
    this.fcfData = Object.assign(
      {},
      {
        ...this.fcfDataOriginal,
        vatAdjustment: this.fcfData?.vatAdjustment,
        shippingEur: this.fcfData?.shippingEur,
      }
    ) as VRT;
    this.editingPrice = false;
    this.editingPriceZero = false;
    this.editingFinalCost = false;
  }

  editHouseCharge() {
    if (!this.fcfData) {
      return;
    }
    const onDialogClose = <Observable<any>>(
      this.additionalCostsService.openEditDialog(this.fcfData, HouseMiscChargeDialogComponent, true)
    );
    onDialogClose.subscribe(newValue => {
      if (typeof newValue === "number") {
        // We have a new house charge
        if (this.fcfData) {
          this.fcfData.houseMiscCharge = newValue;
        }
      }
    });
  }
}
