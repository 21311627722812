import { Component } from "@angular/core";
import {NotificationsComponent} from "../../../components/notifications/notifications.component";
import {MatButton} from "@angular/material/button";
import {MatDialog} from "@angular/material/dialog";
import {FcfService} from "../../../services/fcf.service";
import {FcfFiltersComponent} from "../../../components/dialogs/fcf-filters/fcf-filters.component";

@Component({
  selector: "app-notifications-home",
  standalone: true,
  imports: [
    NotificationsComponent,
    MatButton
  ],
  templateUrl: "./notifications-home.component.html",
  styleUrl: "./notifications-home.component.scss"
})
export class NotificationsHomeComponent {
constructor(private dialog: MatDialog) {
}
  async new(notifications: NotificationsComponent) {
    const dialog = this.dialog.open(FcfFiltersComponent, {
      data: {
        filtersFormGroup: FcfService.initForm(),
        mode: "notifications"
      },
      id: "fcf-filters"
    });
    const saveSubscription = dialog.componentInstance.savedNotification.subscribe(() => {
      notifications.ngOnInit();
    });
    dialog.afterClosed().subscribe(() => {
      saveSubscription.unsubscribe();
    });
  }
}
