import { Inject, Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { SESSION_STORAGE, StorageService } from "ngx-webstorage-service";
import { FormControl, FormGroup } from "@angular/forms";
import { SortDirection } from "@angular/material/sort";
import { bodyTypeOptions } from "../components/dialogs/fcf-filters/fcf-filters.component";
import { Subject } from "rxjs";
import { vatRateIreland, vatRateUK } from "./locale.service";
import { CurrencyService } from "./currency.service";
import {FLAT_SHIPPING_FEE} from "./quotes.service";

export const columnStorageKey = "fcfColumnOrder";
export const KM_TOGGLE_KEY = "fcf_show_km";
export interface SortCriteria {
  active: string | null;
  direction: SortDirection;
}
export interface VRT {
  id: number;
  kms: string | null; // Mileage in KMs
  bca_id?: string | null; // Adjusted for null
  registrationNumber: string | null;
  registrationNumber_ie: string | null;
  make: string;
  model: string;
  model_tfl?: string;
  transmissionType: string | number; // Adjusted to accept both
  numberOfDoors: string | number; // Adjusted to accept both
  engineType: string | number; // Adjusted to accept both
  bodyType: string | number; // Adjusted to accept both
  engineCapacity: number | null;
  statCode: string;
  mileage: number | null; // Mileage in miles
  yearOfRegistration: string | null;
  monthOfRegistration: number;
  euroStatus: string | null;
  versionData: string | null;
  vrtEuro: string | null;
  evRelief: string | number | null; // EV Relief
  omsp_current: string | null;
  attemptedVrt_ts: string | null;
  astonbarclay_id: string | null;
  manheim_id: string | null;
  autotrader_id: number | null;
  autotrader_seller_id: number | null;
  priceGBP: number | null;
  priceGBP_exvat: string | null;
  priceEst: string | null;
  priceEst_orig?: string | null; // Set if we override the original priceEst
  priceEstDisclaimer?: string; // Added if the price est was set manually
  priceEurExVat: string | null;
  vatAdjustment: number | null;
  priceEstExVat: string | null;
  shippingEur: string | number | null;
  houseMiscCharge: number | null;
  customsDuty: number | null; // Calculated at runtime
  profit: string | null;
  exchange_rate: string;
  roi: string | null;
  totalCosts: string | null; // Total cost using vat on sale
  totalLandedCost: number | null; // Total cost, using vat on import
  masked: string | null;
  monthOfFirstRegistration: string | null; // 2017-03-01
  vat: number | null;
  importVat?: number | null; // Calculated at runtime
  customsRate: string | number | null;
  customsDisclaimer: string | null;
  customsRateAboutToChange: boolean
  omsp: string | null;
  monthlyAdjustment: number | null;
  deprCode: string | null;
  mileageRed: string | null;
  deprRate: number | null;
  wltpco2: number | null;
  co2Charges: number | null;
  noxCharge: number | null;
  place_id: string | null;
  distance_dublin: number | null;
  sellerType: string | null;
  yearOfManufacture: number | null;
  colour: string | null;
  vin: string | null;
  euClassification: string | null;
  motStatus: string | null;
  taxStatus: string | null;
  taxDueDate: string | null;
  dateOfLastV5CIssued: string | null;
  seats: number | null;
  wheelplan: string | null;
  revenueWeight: number | null;
  co2Emissions: number | null;
  nox: number | null;
  countryOfOrigin: string | null;
  countryOfOrigin_vin: string | null;
  version: string | null;
  unmodifiedVRTAmount: number | null;
  insuranceWriteOffCategory: string | null;
  firstRegisteredInJapan?: boolean;
  source: string; // Source of the VRT calculation
  showMiles?: boolean; // Whether to show miles or kms
  vehicleStatus?: string; // NVDF
  nctExpiry?: string; // NVDF

}

export interface Filters {
  bodyTypes: number[],
  makes: string[],
  models: string[],
  kmsMin: number,
  kmsMax: number,
  co2Min: number,
  co2Max: number,
  engineSizeMin: number,
  engineSizeMax: number,
  engineTypes: number[],
  purchasePriceMin: number,
  purchasePriceMax: number,
  totalCostMin: number,
  totalCostMax: number,
  profitMin: number,
  profitMax: number,
  roiMin: number,
  roiMax: number,
  sellerTypes: string[],
  yearMin: number,
  yearMax: number,
  transmissionTypes: number[],
  numberOfDoors: number[],
  keyword: string,
  colours: string[],
  insuranceWriteOff: boolean,
  showNoIREPrice: boolean,
  showNoCosting: boolean,
}

@Injectable({
  providedIn: "root",
})

export class FcfService {
  public total: number = 0;
  public makes: { [make: string]: string[] } = {};
  public loadingFacets: boolean = false;
  public loading: boolean = false;

  static maxCost: number = 250000;
  static maxKms: number = 400000;
  static minYear: number = 2000;
  static maxCo2: number = 400;
  static maxEngineSize: number = 5000;
  static maxProfit: number = 50000;
  static maxRoi: number = 2; // 200 %
  static maxYear: number = new Date().getFullYear();
  private refreshDataSource = new Subject<void>();
  // Observable to notify when data should be refreshed
  refreshData$ = this.refreshDataSource.asObservable();
  constructor(private apiService: ApiService, @Inject(SESSION_STORAGE) private storage: StorageService, private currencyService: CurrencyService) {
  }

  // Trigger a data refresh
  triggerDataRefresh() {
    this.refreshDataSource.next();
  }
  /**
   * Get source (name and URL to ad)
   * @param element
   */
  static getSource(element: VRT | any): { url: string | null, source: string | null } {
    let source: { url: string | null, source: string | null } = {
      url: null, source: null
    };
    if (element.autotrader_id) {
      source.url = `https://www.autotrader.co.uk/car-details/${element.autotrader_id}`;
      source.source = "Autotrader.co.uk";
    } else if (element.astonbarclay_id) {
      source.url = `https://catalogue.astonbarclay.net/details/catalogue/${element.astonbarclay_id}`;
      source.source = "AstonBarclay.net";
    } else if (element.manheim_id) {
      source.url = `https://www.manheim.co.uk/search?keywords=${element.registrationNumber}`;
      source.source = "Manheim.co.uk";
    } else if (element.bca_id) {
      source.url = `https://www.bca.co.uk/lot/${element.registrationNumber}`;
      source.source = "BCA.co.uk";
    } else if (element.source == "carsireland") {
      source.url = `https://www.carsireland.ie/${element.id}`;
      source.source = "Cars Ireland"
    } else if (element.source == "carzone") {
      source.source = "CarZone"
      source.url = `https://www.carzone.ie/used-cars/${element.make}/${element.model}/fpa/${element.id}`;
    } else if (element.source == "donedeal") {
      source.source = "DoneDeal"
      source.url = `https://www.donedeal.ie/cars-for-sale/a/${element.id}`;
    }
    return source;
  }

  public async get(pageSize: number, pageIndex: number, sortCriteria: SortCriteria, filters: Filters): Promise<VRT[]> {

    //Populate array for bodytypes
    const bodyTypes: number[] = [];
    for (let option of bodyTypeOptions) {
      if (filters.bodyTypes.indexOf(option.i) > -1) {
        bodyTypes.push(...option.bodyTypes)
      }
    }
    filters.bodyTypes = bodyTypes;

    const res = await this.apiService.getFcf(pageSize, pageIndex, sortCriteria.direction, sortCriteria.active, filters);
    if (res?.success) {
      this.total = res.data.rows;
      return res.data.fcf;
    }
    throw new Error(res.msg ?? "Could not retrieve FCF data");
  }

  static initForm(): FormGroup {
    return new FormGroup({
      id: new FormControl(null),
      makes: new FormControl([]),
      models: new FormControl({value: [], disabled: true}),
      kmsMin: new FormControl(0),
      kmsMax: new FormControl(FcfService.maxKms),
      engineSizeMin: new FormControl(0),
      engineSizeMax: new FormControl(FcfService.maxEngineSize),
      engineTypes: new FormControl([]),
      purchasePriceMin: new FormControl(0),
      purchasePriceMax: new FormControl(FcfService.maxCost),
      totalLandedCostMin: new FormControl(0),
      totalLandedCostMax: new FormControl(FcfService.maxCost),
      totalCostMin: new FormControl(0),
      totalCostMax: new FormControl(FcfService.maxCost),
      profitMin: new FormControl(0),
      profitMax: new FormControl(FcfService.maxProfit),
      roiMin: new FormControl(0),
      roiMax: new FormControl(FcfService.maxRoi),
      sellerTypes: new FormControl([]),
      yearMin: new FormControl(FcfService.minYear),
      yearMax: new FormControl(FcfService.maxYear),
      co2Min: new FormControl(0),
      co2Max: new FormControl(FcfService.maxCo2),
      transmissionTypes: new FormControl([]),
      numberOfDoors: new FormControl([]),
      bodyTypes: new FormControl([]),
      keyword: new FormControl(""),
      colours: new FormControl([]),
      insuranceWriteOff: new FormControl(false),
      showNoIREPrice: new FormControl(false),
      showNoCosting: new FormControl(false)
    });
  }

  async getFacets() {
    if (!this.loadFacets()) {
      const res = await this.apiService.getFacets();
      if (res?.success) {
        this.makes = res.data.makes;
        this.saveFacets();
      }
    }
  }

  private saveFacets(): void {
    this.storage.set("makes", this.makes);
  }

  private loadFacets(): boolean {
    this.makes = this.storage.get("makes");
    return Boolean(this.makes);
  }

  /**
   * Calculates the VAT on sale, ie. offsetting VRT etc
   * @param purchasePriceGBPIncVat
   * @param salesPriceEurIncVat
   * @param vrt
   */
  public salesVat(
    purchasePriceGBPIncVat: number | null | undefined,
    salesPriceEurIncVat: number | null | undefined = null,
    vrt: number | null = null
  ): number {
    // Ensure at least one of purchasePriceGBPIncVat or salesPriceEurIncVat is provided
    if (
      (!purchasePriceGBPIncVat || purchasePriceGBPIncVat <= 0) &&
      (!salesPriceEurIncVat || salesPriceEurIncVat <= 0)
    ) {
      throw new Error(
        "Either 'purchasePriceGBPIncVat' or 'salesPriceEurIncVat' must be provided and greater than zero."
      );
    }

    // Convert purchase price from GBP to EUR, if provided
    const priceEur = purchasePriceGBPIncVat
      ? this.currencyService.convert(
        this.currencyService.gbp,
        this.currencyService.eur,
        purchasePriceGBPIncVat
      )
      : null;

    // Remove UK VAT (if purchasePriceGBPIncVat is provided)
    const priceExVat = priceEur ? priceEur / (1 + vatRateUK) : null;

    // Determine sales price excluding VAT
     // Use priceExVat if salesPriceEurIncVat is not provided
    // Adjust for VRT if provided
    let finalPrice = salesPriceEurIncVat
      ? salesPriceEurIncVat / (1 + vatRateIreland)
      : (priceExVat as number);
    if (vrt) {
      finalPrice -= vrt / (1 + vatRateIreland);
    }

    // Return Irish VAT, ensuring it's not negative
    return Math.max(0, Math.round(finalPrice * vatRateIreland));
  }
  /**
   * Calculates the VAT (Value Added Tax) on import based on the provided element details.
   * Requires priceGBP and uses also shippingEur and customsRate
   *
   * @param {VRT} element - The element containing pricing, shipping, and customs rate details, required for VAT calculation.
   * @return {number} - The computed VAT-inclusive total export price in EUR considering shipping, customs, and VAT rates.
   */
  public importVat(element: VRT): number | null {
    if (!element.priceGBP) {
      // We do not have a purchase price
      return null;
    }
    // Use the priceEurExVat from FCF if available, otherwise convert from GBP
    const purchasePriceEur = element.priceEurExVat ?? this.currencyService.convert(
      this.currencyService.gbp,
      this.currencyService.eur,
      Number(element.priceGBP / (1 + vatRateUK)));
    const totalExportPriceEur = Number(purchasePriceEur) + Number(element.shippingEur ?? FLAT_SHIPPING_FEE);
    const customsRate = Number(element.customsRate ?? 0.1);
    return totalExportPriceEur * (1 + customsRate) * vatRateIreland;
  }

  /**
   * Calculates the customs duty based on the provided item's properties.
   *
   * @param {VRT} element - The item for which the customs duty is to be calculated.
   *                        It must contain properties such as `priceGBP`, `customsRate`,
   *                        and optionally `shippingEur`.
   * @return {number|null} The calculated customs duty for the item, or null if mandatory
   *                       data is missing.
   */
  customsDuty(element: VRT): number | null {
    if (!element.priceGBP) {
      return null;
    }
    const purchasePriceEur = element.priceEurExVat ?? this.currencyService.convert(
      this.currencyService.gbp,
      this.currencyService.eur,
      Number(element.priceGBP / (1 + vatRateUK))
    );
    const customsBasis = Number(element.shippingEur ?? FLAT_SHIPPING_FEE) + Number(purchasePriceEur); // Always custom's duty on shipping
    return customsBasis * Number(element.customsRate ?? 0.1);
  }
  static customsRateAboutToChange(fcfData: VRT): boolean {
    if (fcfData?.customsRate == 0.1) {
      return false; // No need to alert if custom's rate already full
    }
    if (fcfData?.countryOfOrigin == "United Kingdom") {
      return false; // UK is a preferred trade partner. It's always 0-rate
    }
    // Define the base date for the car's age calculation
    const baseDate = FcfService.vehicleDOB(fcfData);

    if (baseDate === null) {
      return false; // Insufficient data
    }
    const currentDate = new Date();
    const ageInMilliseconds = currentDate.getTime() - baseDate.getTime(); // Car's age in milliseconds
    const ageInYears = ageInMilliseconds / (1000 * 60 * 60 * 24 * 365.25); // Convert milliseconds to years

    // Check if the car's age is between 2.5 and 3 years
    return ageInYears >= 2.5 && ageInYears < 3;
  }
  public static vehicleDOB(fcfData: VRT): Date | null {
    const dateOfFirstReg = fcfData?.monthOfFirstRegistration ? new Date(fcfData.monthOfFirstRegistration) : null;
    if (dateOfFirstReg !== null) {
      return dateOfFirstReg;
    }
    // Fallback to year
    return fcfData?.yearOfRegistration ? new Date(Number(fcfData.yearOfRegistration), 5, 1) : null;
  }
}
