import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
  name: "fcfCols", standalone: true,
})
export class FcfColsPipe implements PipeTransform {
  transform(value: string): unknown {
    switch (value) {
      case "make":
        return "Make and Model";
      case "yearOfRegistration":
        return "Year";
      case "engineType":
        return "Engine Type";
      case "vrtEuro":
        return "VRT";
      case "shippingEur":
        return "Est. shipping";
      case "priceEstExVat":
        return "Est Sale Price IE net VAT";
      case "priceEurExVat":
        return "Est. purchase price UK - Net VAT";
      case "vat":
        return "VAT on Sale";
      case "vatImport":
        return "VAT on Import";
      case "houseMiscCharge":
        return "House charge";
      case "profit":
        return "Profit";
      case "vatAdjustment":
        return "Importation Fee";
      case "customsDuty":
        return "Customs";
      case "totalCosts":
        return "Total Cost inc. VAT at Sale";
      case "totalLandedCost":
        return "Total Landed Cost";
      case "roi":
        return "ROI";
      case "kms":
        return "Mileage";
      case "priceEst":
        return "Est. Sale Price IE";
      case "co2Charges":
        return "VRT CO2 charge";
      case "noxCharge":
        return "VRT NOx charge";
      case "priceGBP":
        return "Est. Purchase Price UK -Incl. VAT";
      case "colour":
        return "Colour";

      default:
        return value;
    }

  }
}
