<!-- Show loader when data is loading -->
<div *ngIf="isLoading" class="loading-container">
  <mat-spinner></mat-spinner>
</div>

<h2 mat-dialog-title>
  Quote for <strong>{{ data.fcfData?.registrationNumber }}</strong> -
  {{ data.fcfData?.make }} {{ data.fcfData?.model }}
</h2>
<mat-dialog-content>
  <p class="disclaimer">
    This quote is subject to verification of all details provided.<br>
    If adjustments need to be made, you will receive an amended quote, reflecting the most accurate estimate we can provide.
  </p>

  <app-ccq-totals *ngIf="!isLoading" [fcfDataInput]="data.fcfData"></app-ccq-totals>
  <p class="note">
    Currency conversion rate GBP/EUR
    {{ currency.getRate(currency.gbp) | number: "1.2-2" : "en" }},
    last updated {{ currency.rates.ts | date:'short'  }}.
  </p>
  <mat-dialog-actions class="dialog-actions" align="center">
    <a mat-raised-button color="primary" (click)="submitQuote()" [disabled]="isLoading">Submit Request</a>
    <a mat-raised-button (click)="dialogRef.close()">Cancel</a>
  </mat-dialog-actions>
</mat-dialog-content>
