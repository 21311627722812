<h2 mat-dialog-title class="dialog-title">
  Adjust House/Misc Charge for Vehicle
</h2>
<mat-dialog-content>
  <div class="dialog-container">
    <mat-form-field appearance="fill" class="misc-charge-input">
      <mat-label>Enter House/Misc Charge</mat-label>
      <input
        matInput
        [formControl]="houseMiscChargeControl"
        type="number"
        placeholder="Enter House/Misc Charge"
        step="10"
      />
    </mat-form-field>
    <div>{{Messages.houseMiscCharge}} In EUR.</div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="center" class="button-row">
  <button mat-raised-button [disabled]="houseMiscChargeControl.untouched || houseMiscChargeControl.invalid" (click)="additionaCostService.saveForSpecificVehicle('houseMiscCharge',data.id,houseMiscChargeControl.value,dialogRef)">
    Set for Vehicle
  </button>
  <button mat-raised-button (click)="additionaCostService.saveForSpecificMakeAndModel('houseMiscCharge',data.make, data.model,houseMiscChargeControl.value,dialogRef)" [disabled]="houseMiscChargeControl.untouched || houseMiscChargeControl.invalid">
    Set for {{ data.make }} {{ data.model }}
  </button>
  <button mat-raised-button (click)="additionaCostService.saveForAll('houseMiscCharge',houseMiscChargeControl.value,dialogRef)" [disabled]="houseMiscChargeControl.untouched || houseMiscChargeControl.invalid">
    Set for All Vehicles
  </button>
  <button mat-raised-button (click)="dialogRef.close(false)">Cancel</button>
</mat-dialog-actions>
