import {Component, OnInit, ViewChild} from '@angular/core';
import {DecimalPipe, NgIf, UpperCasePipe} from "@angular/common";
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatHeaderCell, MatHeaderCellDef,
  MatHeaderRow,
  MatHeaderRowDef,
  MatRow, MatRowDef, MatTable, MatTableDataSource
} from "@angular/material/table";
import {MatOption} from "@angular/material/autocomplete";
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {MatFormField, MatSelect} from "@angular/material/select";
import {ApiService} from "../../../../services/api.service";
import {MatLabel} from "@angular/material/form-field";
import { MatSort, MatSortModule } from '@angular/material/sort';

@Component({
  selector: 'app-user-activity',
  standalone: true,
  imports: [
    MatCell,
    MatCellDef,
    MatColumnDef,
    MatHeaderCell,
    MatHeaderRow,
    MatHeaderRowDef,
    MatProgressSpinner,
    MatRow,
    MatRowDef,
    MatTable,
    NgIf,
    UpperCasePipe,
    MatHeaderCellDef,
    MatSelect,
    MatOption,
    MatFormField,
    MatLabel,
    DecimalPipe,
    MatSortModule
  ],
  templateUrl: './admin-user-activity.component.html',
  styleUrl: './admin-user-activity.component.scss'
})
export class AdminUserActivityComponent implements OnInit {
  protected userActivity = new MatTableDataSource([]);
  protected loading: boolean | undefined;
  @ViewChild(MatSort) sort: MatSort | undefined;

  services = [
    "ccq","cyc","finance","fcf", "total"
  ];
  displayedColumns = [
    "email",
      ...this.services,
  ];
  days: number = 30;
  constructor(private apiService: ApiService) {
  }
  async ngOnInit() {
    this.loading = true;
    await this.loadUserActivity();
  }

  protected async loadUserActivity() {
    const res = await this.apiService.getUserActivity(this.days);
    if (res?.success) {
      this.userActivity = new MatTableDataSource(res.data);
      this.userActivity.sort = this.sort ?? null;
    }
    this.loading = false;
  }
}
