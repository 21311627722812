<h2 mat-dialog-title class="dialog-title">
  Edit Estimated Sales Price IE – with VAT
</h2>
<mat-dialog-content>
  <div class="dialog-container">
    <mat-form-field >
      <mat-label>Enter the estimated sales price</mat-label>
      <input
        matInput
        [formControl]="priceEstControl"
        type="number"
        placeholder="Est. Sales Price IRE, inc VAT"
        step="100"
      />
      <mat-hint>Price should be in EUR and include VAT.</mat-hint>
    </mat-form-field>

  </div>
</mat-dialog-content>
<mat-dialog-actions align="center" class="button-row">
  <button mat-raised-button (click)="dialogRef.close(false)">Cancel</button>
  <button mat-raised-button (click)="reset()" [disabled]="!data.priceEst_orig && priceEstControl.untouched">Reset the Sales Price</button>
  <button color="primary" mat-raised-button (click)="additionaCostService.saveForSpecificVehicle('priceEst',data.id,priceEstControl.value,dialogRef)" [disabled]="priceEstControl.untouched || priceEstControl.invalid">
    Update the Sales Price
  </button>
</mat-dialog-actions>
