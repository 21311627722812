import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fcfFilterControlNameToLabel',
  standalone: true
})
export class FcfFilterControlNameToLabelPipe implements PipeTransform {

  private readonly controlNameMapping: { [key: string]: string } = {
    makes: 'Makes',
    models: 'Models',
    kmsMin: 'Min Km',
    kmsMax: 'Max Km',
    engineSizeMin: 'Min CC',
    engineSizeMax: 'Max CC',
    engineTypes: 'Fuel',
    purchasePriceMin: 'Min Purchase Price',
    purchasePriceMax: 'Max Purchase Price',
    totalCostMin: 'Min Total Cost',
    totalCostMax: 'Max Total Cost',
    profitMin: 'Min Profit',
    profitMax: 'Max Profit',
    roiMin: 'Min ROI',
    roiMax: 'Max ROI',
    sellerTypes: 'Sellers',
    yearMin: 'Min Year',
    yearMax: 'Max Year',
    co2Min: 'Min CO2',
    co2Max: 'Max CO2',
    transmissionTypes: 'Transmission',
    numberOfDoors: 'Doors',
    bodyTypes: 'Body',
    keyword: 'Keyword',
    colours: 'Colour',
    insuranceWriteOff: 'Ins. Write-Off',
    showNoIREPrice: 'Vehicles Without IRE Price',
    showNoCosting: 'Vehicles Without Complete Costing',
  };

  transform(controlName: string): string {
    return this.controlNameMapping[controlName] || controlName;
  }


}
