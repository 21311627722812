import { Component } from "@angular/core";
import { RouterOutlet } from "@angular/router";
import { UserService } from "./services/user.service";
import { MatProgressSpinner } from "@angular/material/progress-spinner";
import { FooterComponent } from "./components/footer/footer.component";
import { HeaderComponent } from "./components/header/header.component";
import { NgxCookieConsentModule } from "@localia/ngx-cookie-consent";

@Component({
  selector: "app-root",
  standalone: true,
  imports: [
    RouterOutlet,
    MatProgressSpinner,
    FooterComponent,
    HeaderComponent,
    NgxCookieConsentModule,
  ],
  templateUrl: "./app.component.html",
  styleUrl: "./app.component.scss",
})
export class AppComponent {
  protected loaded = false;

  constructor(
    protected userService: UserService
  ) {
    this.userService.loginEvent.subscribe(() => {
      this.loaded = true;
      // this.fcfS.init().catch(e => console.error(e));
    });
  }

  // Disable this for now, and direct everyone to IRE
  // ngAfterViewInit(): void {
  //   if (isPlatformBrowser(this.platformId)) {
  //     this.userService.localeService.toggleOverlay();
  //   }
  // }
}
