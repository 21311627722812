<h2 mat-dialog-title class="dialog-title">
  Estimated Shipping Cost
</h2>
<mat-dialog-content>
  <div class="dialog-container">
    <mat-form-field appearance="fill" class="shipping-cost-input">
      <mat-label>Enter Shipping Cost</mat-label>
      <input
        matInput
       [formControl]="shippingCostControl"
        type="number"
        placeholder="Enter Shipping Cost"
        min="0"
        step="10"
      />
    </mat-form-field>
    <mat-radio-group [formControl]="currencyControl" class="currency-group">
      <mat-radio-button value="eur">EUR</mat-radio-button>
      <mat-radio-button value="gbp">GBP</mat-radio-button>
    </mat-radio-group>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="center" class="button-row">
  <button mat-raised-button (click)="dialogRef.close(false)">Cancel</button>
  <button mat-raised-button (click)="additionaCostService.saveForSpecificMakeAndModel('shippingCost',data.make, data.model,shippingEur,dialogRef)" [disabled]="shippingCostControl.untouched || shippingCostControl.invalid">
    Set for {{ data.make }} {{ data.model }}
  </button>
  <button mat-raised-button (click)="additionaCostService.saveForAll('shippingCost',shippingEur,dialogRef)" [disabled]="shippingCostControl.untouched || shippingCostControl.invalid">
    Set for All Vehicles
  </button>
  <button mat-raised-button color="primary" (click)="additionaCostService.saveForSpecificVehicle('shippingCost',data.id,shippingEur,dialogRef)" [disabled]="shippingCostControl.untouched || shippingCostControl.invalid">
    Set for this Vehicle
  </button>
</mat-dialog-actions>
