<div class="prodCard">
  <mat-progress-spinner mode="indeterminate" diameter="50" *ngIf="loading"></mat-progress-spinner>
  <mat-table [dataSource]="sourcesData" matSort>

    <!-- Source Column -->
    <ng-container matColumnDef="source">
      <mat-header-cell *matHeaderCellDef> Source</mat-header-cell>
      <mat-header-cell *matCellDef="let element"> {{ element.source | source }}</mat-header-cell>
    </ng-container>

    <!-- Complete Data Column -->
    <ng-container matColumnDef="completeData">
      <mat-header-cell *matHeaderCellDef> Complete Data</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <ng-container *ngIf="element.status === 'error'; else validData">
          <mat-icon color="warn">error</mat-icon> <!-- Use an error icon -->
          <span>Error</span> <!-- Error text -->
        </ng-container>
        <ng-template #validData>
          @if (element.completeData) {
            {{ (element.completeData | number: '1.0-0') }}
            ({{element.completeData / element.total | percent: '1.0-0'}})
          } @else {
            Loading...
          }
        </ng-template>
      </mat-cell>
    </ng-container>

    <!-- VRT Column -->
    <ng-container matColumnDef="vrt">
      <mat-header-cell *matHeaderCellDef> VRT</mat-header-cell>
      <mat-cell *matCellDef="let element">
        @if (element.vrt) {
          {{ (element.vrt | number: '1.0-0') }}
          ({{element.vrt / element.total | percent: '1.0-0'}})
        } @else {
          Loading...
        }
          </mat-cell>
    </ng-container>

    <!-- Price Est. IRE Column -->
    <ng-container matColumnDef="priceEstIRE">
      <mat-header-cell *matHeaderCellDef> Price Est. IRE</mat-header-cell>
      <mat-cell *matCellDef="let element">
        @if (element.priceEstIRE) {
          {{ (element.priceEstIRE | number: '1.0-0') }}
          ({{element.priceEstIRE / element.total | percent: '1.0-0'}})
        } @else {
          Loading...
        }
      </mat-cell>
    </ng-container>

    <!-- Price UK Column -->
    <ng-container matColumnDef="priceUK">
      <mat-header-cell *matHeaderCellDef> Price UK</mat-header-cell>
      <mat-cell *matCellDef="let element">
        @if (element.priceUK) {
          {{ (element.priceUK | number: '1.0-0') }}
          ({{element.priceUK / element.total | percent: '1.0-0'}})
        } @else {
          Loading...
        }
      </mat-cell>
    </ng-container>

    <!-- Customs Rate Column -->
    <ng-container matColumnDef="customsRate">
      <mat-header-cell *matHeaderCellDef> Customs Rate</mat-header-cell>
      <mat-cell *matCellDef="let element">
        @if (element.customsRate) {
          {{ (element.customsRate | number: '1.0-0') }}
          ({{element.customsRate / element.total | percent: '1.0-0'}})
        } @else {
          Loading...
        }
      </mat-cell>
    </ng-container>

    <!-- Geolocation Column -->
    <ng-container matColumnDef="geoloc">
      <mat-header-cell *matHeaderCellDef> Geolocation</mat-header-cell>
      <mat-cell *matCellDef="let element">
        @if (element.geoloc) {
          {{ (element.geoloc | number: '1.0-0') }}
          ({{element.geoloc / element.total | percent: '1.0-0'}})
        } @else {
          Loading...
        }
      </mat-cell>
    </ng-container>

    <!-- Total Column -->
    <ng-container matColumnDef="total">
      <mat-header-cell *matHeaderCellDef> Total</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.total | number: '1.0-0' }}</mat-cell>
    </ng-container>

    <!-- Define header and row structure -->
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"
             [ngClass]="{'error-row': row.status === 'error', 'loading-row': row.status === 'loading', 'totals-row': row.source === 'Total'}"></mat-row>

  </mat-table>

</div>
