<h2 mat-dialog-title>
  Disclaimer - {{CommerceService.prodName(data.service)}}
</h2>

<mat-dialog-content>
  <p>Given the variety of data sources, fluctuating underlying prices of vehicles and currencies and the fact the Revenue does not give definitive import costs -- rather 'current estimates', this and all information presented must also be viewed as estimations also.</p>
  <p>For the avoidance of doubt, some or all of the following may differ from the information presented -- the specific vehicle details, the purchase price of the vehicle, the VRT, the Customs, the Irish VAT, the UK VAT, the Shipping, and the Forex rates.</p>
  <p>Whilst due care and attention has been exercised in the presentation of this information, TheCarSite accepts no liability for any loss ensuing from the use of the information provided.</p>
  <p>TheCarSite is here to help you Save Time, Cut Costs, Grow Profits and Sell Cars Faster. Your own thorough and independent Due Diligence is very much required.</p>

  <!-- Add Agree Checkbox -->
  <mat-checkbox [(ngModel)]="isAgreed" (change)="onAgreeChange()">
    <span class="checkbox-label">I agree to the terms and conditions</span>
  </mat-checkbox>
</mat-dialog-content>

<mat-dialog-actions class="dialog-actions" align="center">
  <button mat-raised-button color="primary" [disabled]="!isAgreed" (click)="submitDisclaimer()">Submit</button>
  <button mat-raised-button (click)="closeDialog()">Cancel</button>
</mat-dialog-actions>
