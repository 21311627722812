import {
  HttpBackend,
  HttpClient,
  HttpErrorResponse,
  HttpInterceptorFn,
} from "@angular/common/http";
import { catchError, lastValueFrom } from "rxjs";
import { inject } from "@angular/core";
import { UserService } from "../services/user.service";
import { Router } from "@angular/router";

export const authInterceptor: HttpInterceptorFn = (req, next) => {
  const user = inject(UserService);
  const backend = inject(HttpBackend);
  const router = inject(Router);
  return next(req).pipe(
    catchError(async (error: HttpErrorResponse) => {
      if (error.status === 403) {
        if (user.isLoggedIn()) {
          // User is supposed to be logged in.
          // Attempt refresh and repeat
          const token = await user.refresh();
          if (token) {
            // Success, we can try again
            const newReq = req.clone({
              headers: req.headers.set("FIREBASE_ID_TOKEN", token),
            });
            const httpBackend = new HttpClient(backend);
            try {
              return await lastValueFrom(httpBackend.request(newReq));
            } catch (err) {
              console.error("Error retrying the request with new token:", err);
              throw error; // Rethrow the original 403 error
            }
          } else {
            throw new Error("Token refresh failed: No valid token obtained!");
          }
        } else {
          // User is not logged in
          // Redirect the user to login
          await router.navigate(["/login"]);
        }
      }
      // Other errors throw through
      throw error;
    })
  );
};
