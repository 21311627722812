import { Component, Inject } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckbox } from "@angular/material/checkbox";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogContent,
  MatDialogActions, MatDialogTitle,
} from "@angular/material/dialog";
import { MatSpinner } from "@angular/material/progress-spinner";
import { DisclaimerService } from "../../../services/disclaimer.service";
import {CommerceService} from "../../../services/commerce.service";

@Component({
  selector: "app-disclaimer-dialog",
  templateUrl: "./disclaimer-dialog.component.html",
  styleUrls: ["./disclaimer-dialog.component.scss"],
  standalone: true,
  imports: [
    MatDialogContent,
    MatDialogActions,
    MatCheckbox,
    MatSpinner,
    FormsModule,
    MatButtonModule,
    MatDialogTitle,
  ],
})
export class DisclaimerDialogComponent {
  isAgreed = false;

  constructor(
    public dialogRef: MatDialogRef<DisclaimerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private disclaimerService: DisclaimerService
  ) {}
  onAgreeChange() {
    // Optional: Add any additional logic here when the checkbox state changes
  }

  submitDisclaimer() {
    if (this.isAgreed) {
      this.disclaimerService.set(this.data.service);
      this.dialogRef.close({ agreed: true });
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  protected readonly CommerceService = CommerceService;
}
