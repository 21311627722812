<h2 mat-dialog-title>{{title}}</h2>
<mat-dialog-content>
  <div *ngIf="dataSource.data.length === 0; else hasSavedSearches">
    <p>No saved searches found.</p>
  </div>

  <ng-template #hasSavedSearches>
    <mat-table [dataSource]="dataSource" class="saved-searches-table" matSort matSortActive="timestamp" matSortDirection="desc">
      <!-- Timestamp Column -->
      <ng-container matColumnDef="timestamp">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Timestamp</mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.timestamp | date:'medium' }}
        </mat-cell>
      </ng-container>

      <!-- Search Specs Column -->
      <ng-container matColumnDef="specs">
        <mat-header-cell *matHeaderCellDef>Search Filters</mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.specs }}</mat-cell>
      </ng-container>

      <!-- Actions Column -->
      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <button mat-icon-button color="primary"
                  (click)="load(element.id); $event.stopPropagation()"
                  matTooltip="Load search">
            <mat-icon>filter_alt</mat-icon>
          </button>
          <button mat-icon-button color="warn"
                  (click)="deleteSearch(element.id); $event.stopPropagation()"
                  matTooltip="Delete search">
            <mat-icon>delete</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
  </ng-template>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-raised-button (click)="dialogRef.close()">Close</button>
</mat-dialog-actions>
