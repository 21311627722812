import {Component, Inject} from "@angular/core";
import {FormControl, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {MatButton} from "@angular/material/button";
import {
  MAT_DIALOG_DATA, MatDialogActions, MatDialogContent, MatDialogRef, MatDialogTitle,
} from "@angular/material/dialog";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {VRT} from "../../../services/fcf.service";
import {AdditionalCostsService} from "../../../services/additional-costs.service";
import {MatRadioButton, MatRadioGroup} from "@angular/material/radio";
import {CurrencyService} from "../../../services/currency.service";

@Component({
  selector: "app-shipping-cost-dialog",
  standalone: true,
  imports: [MatButton, MatDialogContent, MatDialogActions, MatDialogTitle, MatFormFieldModule, MatInputModule, FormsModule, ReactiveFormsModule, MatRadioGroup, MatRadioButton,],
  templateUrl: "./shipping-cost-dialog.component.html",
  styleUrls: ["./shipping-cost-dialog.component.scss"],
})
export class ShippingCostDialogComponent {
  shippingCostControl: FormControl;
  currencyControl: FormControl = new FormControl("eur");
  constructor(protected dialogRef: MatDialogRef<ShippingCostDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: VRT, protected additionaCostService: AdditionalCostsService, private currencyService: CurrencyService) {
    this.shippingCostControl = new FormControl(data.shippingEur ?? 0, [Validators.required, Validators.min(0)]);
    this.currencyControl.valueChanges.subscribe(value => {
      if (value == "gbp") {
        this.shippingCostControl.setValue(this.currencyService.convert(this.currencyService.eur,this.currencyService.gbp,this.shippingCostControl.value,2));
      } else if (value == "eur") {
        this.shippingCostControl.setValue(this.currencyService.convert(this.currencyService.gbp,this.currencyService.eur,this.shippingCostControl.value,2));
      }
    })
  }

  /**
   * The shipping cost in EUR, as we should always save it in EUR
   * @protected
   */
  get shippingEur(): number {
      return this.currencyControl.value == "gbp" ?
        this.currencyService.convert(this.currencyService.gbp,this.currencyService.eur,this.shippingCostControl.value) :
        this.shippingCostControl.value;
  }
}
