export const Messages = {
  noPriceOnFile: "We do not have the price on record for this vehicle. Please provide the value.",
  invalidIREPostCode: "Please enter a valid Irish postcode",
  invalidUKPostCode: "Please enter a valid UK postcode",
  connectionError: "Unable to connect to the server. Please check your internet connection and try again.",
  onlyUKReg: "We currently only support VRT checks on UK registered vehicles",
  vehicleFound: "Found the vehicle. Looking for more data.",
  lookingForVehicle: "Looking for the vehicle.",
  invalidPostcodes: "Please enter a valid post code for both origin and destination.",
  attemptTransport: "Attempting to calculate the transport costs.",
  attemptCustoms: "Attempting to calculate customs duty.",
  attemptVat: "Attempting to calculate the VAT.",
  attemptVRT: "Attempting to calculate the VRT.",
  noFinanceCheckOption: "You have opted to not run finance checks on this vehicle.",
  pleaseUseToggle: "Please use the toggle under the search input if you wish to enable it.",
  customsDisclaimer: "This custom's rate is an estimate. If you click this entry and request a quote, you will get a precise custom's rate in the overview.",
  vrtDisclaimer: "We were not able to get a precise VRT calculation for this vehicle. If you click this entry and request a quote, we will get back to you with a precise VRT.",
  priceEstOverride: "This price estimate was provided by the user.",
  houseMiscCharge: "Miscellaneous charges for the house or other expenses.",
  totalLandedCost: "The ‘Total Landed Cost’ provides an indicative break-even price. This total does not include the difference between the VAT at Import and the VAT at Sale. To see a projected P&L please include and review the following columns - ‘Est. Sale Price in IRE’, then ‘Total Cost inc. VAT at Sale’ and ‘Est Profit’."
};
