import {Component, Inject, PLATFORM_ID} from "@angular/core";
import {isPlatformBrowser, NgIf, NgOptimizedImage, NgStyle} from "@angular/common";
import { MatButton } from "@angular/material/button";
import { HeroBgService } from "../../services/hero-bg.service";
import { MatFormField, MatInput } from "@angular/material/input";
import { MatLabel } from "@angular/material/form-field";
import { ApiService } from "../../services/api.service";
import { NgxTurnstileModule } from "ngx-turnstile";
import { FormControl, ReactiveFormsModule, Validators } from "@angular/forms";
import { UserService } from "../../services/user.service";

@Component({
  selector: "app-contact",
  standalone: true,
  imports: [
    MatButton,
    NgOptimizedImage,
    MatInput,
    MatFormField,
    MatLabel,
    NgIf,
    NgxTurnstileModule,
    ReactiveFormsModule,
    NgStyle,
  ],
  templateUrl: "./contact.component.html",
  styleUrl: "./contact.component.scss",
})
export class ContactComponent {
  protected contactError: string | undefined;
  protected msgControl: FormControl = new FormControl("", [
    Validators.required,
    Validators.minLength(6),
  ]);
  protected emailControl: FormControl = new FormControl(this.userService.email, [
    Validators.email,
    Validators.required,
  ]);
  protected sent: boolean = false;
  private token: string | null = null;

  constructor(
    hero: HeroBgService,
    private apiService: ApiService,
    protected userService: UserService,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {
    hero.setBg("/assets/img/f1-handshake.jpg");
  }

  async submitForm() {
    if (this.emailControl.invalid || this.msgControl.invalid) {
      this.contactError = "Could not send the message: Please fill in your e-mail and message.";
      return;
    }
    if (!this.token) {
      this.contactError = "Could not send the message: The CAPTCHA was not completed..";
      return;
    }

    try {
      const r = await this.apiService.contact(
        this.emailControl.value,
        this.msgControl.value,
        this.token
      );
      if (r.success) {
        this.sent = true;
      } else {
        throw new Error(r.msg);
      }
    } catch (e: any) {
      this.contactError = `Could not send the message: ${e.message}`;
    }
  }

  sendCaptchaResponse(captchaResponse: string | null) {
    this.token = captchaResponse;
  }
  get isBrowser(): boolean {
    return isPlatformBrowser(this.platformId);
  }
}
