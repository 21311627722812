section.tnc {
  padding: 1em;
  background-color: whitesmoke;
  &.header {
    background-color: #b71c1c;
    color: white;
  }
}
app-header {
  position: sticky;
  top: 0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
}
