import { Component, Input } from "@angular/core";
import { BodyTypePipe } from "../../../pipes/body-type.pipe";
import { DatePipe, DecimalPipe, NgIf } from "@angular/common";
import { EngineTypePipe } from "../../../pipes/engine-type.pipe";
import { TransmissionTypePipe } from "../../../pipes/transmission-type.pipe";
import { VinRevealService } from "../../../services/vin-reveal.service";
import {MilesToKmPipe} from "../../../pipes/miles-to-km.pipe";

@Component({
  selector: "app-vehicle-data",
  standalone: true,
  imports: [BodyTypePipe, DatePipe, DecimalPipe, EngineTypePipe, TransmissionTypePipe, NgIf],
  templateUrl: "./vehicle-data.component.html",
  styleUrl: "./vehicle-data.component.scss",
})
export class VehicleDataComponent {
  @Input("data") data: any;

  constructor(protected vinRevealService: VinRevealService) {}

  get kms(): number | null {
    if (this.data?.kms) {
      // If we have the kms, return as is
      return this.data.kms;
    }
    if (this.data?.mileage === null || this.data?.mileage === undefined) {
      // If we don't have mileage, return null
      return null;
    }
    // Otherwise convert miles to km
    const milesToKmPipe = new MilesToKmPipe();
    return milesToKmPipe.transform(this.data.mileage);
  }
}
