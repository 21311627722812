<h1>Admin</h1>
<div class="prodCard adminNav">
  <button color="primary" mat-raised-button routerLink="users">Users</button>
  <button color="primary" mat-raised-button routerLink="userActivity">User Activity</button>
  <button color="primary" mat-raised-button routerLink="quotes">Quotes</button>
  <button color="primary" mat-raised-button routerLink="dealers">Dealers IE</button>
  <button color="primary" mat-raised-button routerLink="sources">Sources funnel</button>
</div>

<router-outlet></router-outlet>
