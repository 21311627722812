import { AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatTableDataSource, MatTableModule } from "@angular/material/table";
import {CurrencyPipe, DatePipe, DecimalPipe, NgClass, NgIf, PercentPipe} from "@angular/common";
import { MatPaginator, MatPaginatorModule, PageEvent } from "@angular/material/paginator";
import { MatProgressSpinner } from "@angular/material/progress-spinner";
import { MatSort, MatSortModule, Sort } from "@angular/material/sort";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatDialog } from "@angular/material/dialog";
import { MatButton } from "@angular/material/button";
import { formatRes } from "../../../../pipes/format-res.pipe";
import { CurrencyService } from "../../../../services/currency.service";
import {columnStorageKey, KM_TOGGLE_KEY, FcfService, SortCriteria, VRT} from "../../../../services/fcf.service";
import { FormGroup, ReactiveFormsModule } from "@angular/forms";
import { MatSelect } from "@angular/material/select";
import { HeroBgService } from "../../../../services/hero-bg.service";
import { MatToolbar, MatToolbarRow } from "@angular/material/toolbar";
import { MatIcon } from "@angular/material/icon";
import { DisclaimerComponent } from "../../../../components/disclaimer/disclaimer.component";
import { Subscription } from "rxjs";
import { FCFCarDetailsDialog } from "../../../../components/dialogs/fcf-car-details/dialog-car-details";
import { FcfFiltersComponent } from "../../../../components/dialogs/fcf-filters/fcf-filters.component";
import {
  MatSlideToggle,
  MatSlideToggleChange,
  MatSlideToggleModule,
} from "@angular/material/slide-toggle";
import { FcfColumnVisibilityDialogComponent } from "../../../../components/dialogs/fcf-column-visibility-dialog/fcf-column-visibility-dialog.component";
import { ActivatedRoute } from "@angular/router";
import { Messages } from "../../../../messages";
import { DisclaimerDialogComponent } from "../../../../components/dialogs/disclaimer-dialog/disclaimer-dialog.component";
import { DisclaimerService } from "../../../../services/disclaimer.service";
import { LOCAL_STORAGE, StorageService } from "ngx-webstorage-service";
import {AdditionalCostsService} from "../../../../services/additional-costs.service";
import { CdkDragDrop, DragDropModule, moveItemInArray } from "@angular/cdk/drag-drop";
import {FLAT_SHIPPING_FEE} from "../../../../services/quotes.service";

@Component({
  selector: "app-vrt",
  standalone: true,
  imports: [
    MatTableModule,
    MatToolbarRow,
    MatPaginatorModule,
    NgIf,
    DecimalPipe,
    MatProgressSpinner,
    MatSortModule,
    formatRes,
    MatTooltipModule,
    NgClass,
    MatButton,
    ReactiveFormsModule,
    MatToolbar,
    MatIcon,
    DisclaimerComponent,
    CurrencyPipe,
    PercentPipe,
    MatSlideToggle,
    MatSlideToggleModule,
    DragDropModule,
    DatePipe,
  ],
  templateUrl: "./fcf-home.component.html",
  styleUrl: "./fcf-home.component.scss",
})
export class FcfHomeComponent implements AfterViewInit, OnInit, OnDestroy {
  private dataRefreshSubscription: Subscription | undefined;
  public filtersFormGroup!: FormGroup;
  private routeBasedTriggered = false; // Flag to track if `getData()` was triggered by the route



  // Columns that should be visible by default (removing vatAdjustment and houseMiscCharge)
  public columnsCutoff: { [key: string]: number } = {
    make: 0,
    yearOfRegistration: 0,
    kms: 900,
    colour: 2000,
    engineType: 2000,
    priceGBP: 1400,
    priceEurExVat: 1800,
    vatAdjustment: 1400,
    shippingEur: 1400,
    customsDuty: 1200,
    vatImport: 1200,
    vat: 2000,
    vrtEuro: 1200,
    co2Charges: 2000,
    noxCharge: 2000,
    houseMiscCharge: 1500,
    totalLandedCost: 800,
    priceEst: 600,
    priceEstExVat: 2000,
    totalCosts: 800,
    profit: 0,
    roi: 2000,
  };

  public displayedColumns: string[] = [];

  public vrt = new MatTableDataSource<VRT>([]);
  public loading = true;
  @ViewChild("topPaginator") topPaginator: MatPaginator | undefined;
  @ViewChild("bottomPaginator") bottomPaginator: MatPaginator | undefined;
  @ViewChild(MatSort) sort: MatSort | undefined;
  @ViewChild("modelsFilter") modelsFilter!: MatSelect;
  @ViewChild("showMiles") showMiles: MatSlideToggle | undefined;
  total = 0;

  protected errorMsg: string | undefined;

  protected readonly Number = Number;
  private curSortCriteria: SortCriteria = {
    active: "id",
    direction: "desc",
  };
  private submitSubscription: Subscription | undefined;
  private resetSubscription: Subscription | undefined;
  protected preferKm: boolean = false;

  constructor(
    public dialog: MatDialog,
    protected currency: CurrencyService,
    public fcfService: FcfService,
    private hero: HeroBgService,
    private route: ActivatedRoute,
    private disclaimerService: DisclaimerService,
    @Inject(LOCAL_STORAGE) private storage: StorageService,
    private additionalCostService: AdditionalCostsService
  ) {
    this.filtersFormGroup = FcfService.initForm();
    this.initializeColumns();
  }

  private initializeColumns() {
    this.displayedColumns = [
      "make",
      "yearOfRegistration",
      "kms",
      "priceGBP",
      "vatAdjustment",
      "shippingEur",
      "customsDuty",
      "vatImport",
      "vrtEuro",
      "houseMiscCharge",
      "totalLandedCost",
      "priceEst",
      "totalCosts",
      "profit",
    ];
  }

  ngOnDestroy(): void {
    // Unsubscribe from submitSubscription if it exists to prevent memory leaks
    this.submitSubscription?.unsubscribe();

    // Unsubscribe from resetSubscription if it exists to prevent memory leaks
    this.resetSubscription?.unsubscribe();

    // Check if dataRefreshSubscription is active before unsubscribing
    // This ensures that we clean up any ongoing subscriptions properly
    if (this.dataRefreshSubscription) {
      // Unsubscribe from dataRefreshSubscription to avoid memory leaks
      this.dataRefreshSubscription.unsubscribe();
    }
  }

  ngOnInit() {
    this.loadColumnOrder();
    // Set default to miles (toggle OFF)
    this.preferKm = this.storage.get(KM_TOGGLE_KEY) ?? false; // true = show km, false = show miles

    this.route.params.subscribe(params => {
      if (params["filtersb64"]) {
        // We have filters from a link out
        try {
          const filters = JSON.parse(atob(params["filtersb64"]));
          // console.log(filters);
          this.filtersFormGroup.patchValue(filters);
          this.filtersFormGroup.markAsDirty(); // Mark dirty, so that reset button etc is enabled
          // Enable models
          this.filtersFormGroup.get("models")?.enable();
          // console.log(this.filtersFormGroup.value);
          this.getData(this.topPaginator?.pageSize || 50, this.topPaginator?.pageIndex || 1);
          // Mark the route as having triggered `getData()`
          this.routeBasedTriggered = true;
        } catch (e) {
          console.error(e);
        }
      }
    });

    // Set the background image of the hero component with specified positioning
    this.hero.setBg("/assets/img/fast.jpg", "center center");

    // Subscribe to the refreshData$ observable from vatAdjustmentService
    // This will trigger a data refresh whenever there's a new emission from the observable
    this.dataRefreshSubscription = this.fcfService.refreshData$.subscribe(() => {
      // Call getData method with current page size and index from topPaginator,
      // Defaulting to 50 for pageSize and 1 for pageIndex if not set
      this.getData(this.topPaginator?.pageSize || 50, this.topPaginator?.pageIndex || 1);
    });
    this.setDefaultHiddenColumns();
  }

  /**
   * Handles the drop event triggered by drag-and-drop actions to reorder an array of columns.
   * Updates the column order based on the drag operation and applies visibility changes.
   *
   * @param {CdkDragDrop<string[]>} event The drag-and-drop event containing details of the previous and current index.
   * @return {void} No return value as the function updates the array and state directly.
   */
  handleColumnDrop(event: CdkDragDrop<string[]>): void {
    moveItemInArray(this.displayedColumns, event.previousIndex, event.currentIndex);
    console.log("Moved column from index " + event.previousIndex + " to " + event.currentIndex);
    // this.applyColumnVisibility();
    this.saveColumnOrder(this.displayedColumns);
  }

  private loadColumnOrder() {
    const loadedOrder = this.storage.get(columnStorageKey);
    if (loadedOrder) {
        this.displayedColumns = loadedOrder;
    }
  }

  private saveColumnOrder(newOrder: string[] = this.displayedColumns) {
    this.storage.set(columnStorageKey,newOrder);
  }



  /**
   * Sets the default hidden columns for the application if not already defined.
   * The method retrieves stored data for hidden columns, and if no data is found,
   * it initializes the hidden columns based on the current viewport width.
   *
   * @return {void} This function does not return any value.
   */
  private setDefaultHiddenColumns(): void {
    const hiddenCols = this.storage.get("hiddenColumns");
    if (hiddenCols) {
    // We've already defined hidden cols
      return;
    }
    this.storage.set("hiddenColumns", this.getHiddenColsForViewportWidth());

  }
  private getHiddenColsForViewportWidth(viewportWidth: number = window.innerWidth): string[] {
    return Object.keys(this.columnsCutoff).filter(
      column => this.columnsCutoff[column] > viewportWidth
    );
  }

  async ngAfterViewInit() {

    // Wait for the disclaimers to be loaded
    await this.disclaimerService.load();

    if (!this.disclaimerService.get("fcf")) {
      this.openDislaimersDialog("fcf");
    }

    // Check if topPaginator and sort objects are defined before proceeding
    if (this.topPaginator && this.sort) {
      // Assign the sort object to the vrt component for sorting functionality
      this.vrt.sort = this.sort;
      // Uncomment the line below if you want to set a paginator for vrt in the future
      // this.vrt.paginator = this.paginator;
    }
    // Run `getData()` only if it wasn't triggered by the route
    if (!this.routeBasedTriggered) {
      // Fetch data using getData method with the current page size from topPaginator
      // Defaulting to page index 1, handling any errors that might occur during fetch
      this.getData(this.topPaginator?.pageSize, 1).catch(e => {
        // Set an error message if data fetching fails and log the error to the console
        this.errorMsg = `Could not fetch data due to an error: ${e.message}`;
        console.error(e);
      });
    }
    // Call getFacets method from fcfService to retrieve facet data asynchronously
    this.fcfService.getFacets()
      .catch(e => console.error(e));
  }

  // Add toggle handler
  onKmToggleChange(checked: boolean): void {
    this.storage.set(KM_TOGGLE_KEY, checked);
  }

  openFilterDialog() {
    // Open a dialog for applying filters, using FcfFiltersComponent
    const filterDialog = this.dialog.open(FcfFiltersComponent, {
      // Allow the dialog to close when navigation occurs
      closeOnNavigation: true,
      // Pass data to the dialog component, including the mode and the current form group
      data: {
        mode: "fcf",
        filtersFormGroup: this.filtersFormGroup,
      },
      // Assign an ID to the dialog for identification purposes
      id: "fcf-filters",
    });

    // Subscribe to the submit event from the filter dialog component instance
    this.submitSubscription = filterDialog.componentInstance.submit.subscribe(async () => {
      // Close the filter dialog when the submit event is triggered
      filterDialog.close();

      // Check if topPaginator is defined before attempting to set the page index
      if (this.topPaginator) {
        // Set paginator page index to 0 (1st page)
        this.topPaginator.pageIndex = 0;
      }

      // Fetch data again based on the updated filters and reset to the first page
      await this.getData(this.topPaginator?.pageSize, 1).catch(e => {
        // Log any errors that occur during data fetching
        console.error(e);
      });
    });

    // Subscribe to the reset event from the filter dialog component instance
    this.resetSubscription = filterDialog.componentInstance.reset.subscribe(() => {
      // Initialize the filters form group back to its original state
      this.filtersFormGroup = FcfService.initForm();
      // Update the dialog's data with the new filters form group
      filterDialog.componentInstance.data.filtersFormGroup = this.filtersFormGroup;

      // Fetch data again based on the updated filters without error handling
      this.getData(this.topPaginator?.pageSize, 1).catch(e => {});
    });
  }

  openColumnVisibilityDialog(): void {
    const dialogRef = this.dialog.open(FcfColumnVisibilityDialogComponent, {
      width: "700px",
      data: {
        availableColumns: Object.keys(this.columnsCutoff), // Pass the updated string array
        displayedColumns: this.displayedColumns, // Pass currently displayed columns
      },
    });

    // Update displayedColumns when dialog is closed
    dialogRef.afterClosed().subscribe((result: {selectedColumns: string[], reset: boolean}) => {
      if (result?.selectedColumns) {
        this.displayedColumns = result?.selectedColumns; // Update displayed columns based on user selection
      }
      if (result?.reset) {
        this.storage.remove("hiddenColumns");
        this.storage.remove(columnStorageKey);
        this.initializeColumns();
      }
    });
  }

  openDialog(data: VRT) {
    // Open a dialog for displaying car details using CarDetailsDialog component
    this.dialog.open(FCFCarDetailsDialog, {
      data: {
        ...data, // Pass the car data to the dialog component
        showMiles: this.showMiles?.checked, // Should we show mileage as miles or kms?
      },
      id: "car-details", // Assign an ID to the dialog for identification purposes
    });
  }

  openDislaimersDialog(type: any): void {
    // Open the VatAdjustmentDialogComponent dialog
    const dialogRef = this.dialog.open(DisclaimerDialogComponent, {
      width: "600px", // Fixed dialog width
      data: { service: type },
    });

    // Handle the dialog close event
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // Trigger data refresh in FcfHomeComponent
        this.fcfService.triggerDataRefresh();
      }
    });
  }

  announceSortChange($event: Sort) {
    // Uncomment the line below to log sorting changes for debugging purposes
    // console.log("Sorting:", $event);

    // Check if the top paginator is defined (exists)
    if (this.topPaginator) {
      // Reset the paginator to the first page whenever a new sort is applied
      this.topPaginator.pageIndex = 0;

      // Update the current sorting criteria with the new values from the event
      this.curSortCriteria = {
        active: $event.active, // The column currently being sorted
        direction: $event.direction, // The direction of sorting: 'asc' or 'desc'
      };

      // Fetch data again with the new sort criteria and reset to the first page
      this.getData(this.topPaginator.pageSize, 1);
    }
  }

  handlePageEvent(event: PageEvent) {
    // Check if the top paginator is defined (exists)
    if (this.topPaginator) {
      // Update the current page index of the top paginator based on the event
      this.topPaginator.pageIndex = event.pageIndex;

      // Update the page size of the top paginator according to the event
      this.topPaginator.pageSize = event.pageSize;

      // Uncomment the line below to set the total number of items in the top paginator
      // this.paginator.length = this.fcfService.total;
    }

    // Check if the bottom paginator is defined (exists)
    if (this.bottomPaginator) {
      // Update the current page index of the bottom paginator based on the event
      this.bottomPaginator.pageIndex = event.pageIndex;

      // Update the page size of the bottom paginator according to the event
      this.bottomPaginator.pageSize = event.pageSize;

      // Uncomment the line below to set the total number of items in the bottom paginator
      // this.paginator.length = this.fcfService.total;
    }

    // Fetch new data based on the updated page size and page index (adding 1 for zero-based indexing)
    this.getData(event.pageSize, event.pageIndex + 1);
  }

  async getData(pageSize = 50, pageIndex = 1): Promise<void> {
    // Set loading state to true to indicate that data fetching is in progress
    this.loading = true;

    try {
      // Fetch raw data from the backend using specified parameters: page size, page index, current sort criteria, and filter values
      const filters = this.getAdjustedFilters();
      const rawData = await this.fcfService.get(
        pageSize,
        pageIndex,
        this.curSortCriteria,
        filters
      );

      // Process the fetched raw data: transform the structure of each item
      this.vrt.data = rawData
        .map(item => {

          const importationFee = this.additionalCostService.importFee(item.id,item.priceEurExVat);
          const houseMiscCharge = this.additionalCostService.houseMiscCharge(item.id, item.make, item.model);
          const shippingEur = this.additionalCostService.shipping(item.id, item.shippingEur, item.make, item.model);

          // Check if there's a manual sales price estimate
          const priceEstOverride = this.storage.get(`priceEst_${item.id}`);
          if (priceEstOverride) {
            const priceEst = Number(priceEstOverride);
            try {
              let vat = this.fcfService.salesVat(item.priceGBP, priceEst, Number(item.vrtEuro));
              const priceEstExVat = priceEst - vat;

              item.vat = vat;
              item.priceEst_orig = item.priceEst;
              item.priceEst = String(priceEst);
              item.priceEstExVat = String(priceEstExVat);
              item.priceEstDisclaimer = Messages.priceEstOverride;
            } catch (e) {
              // Could not calculate the VAT
              // Fail gracefully and continue without priceEstOverride
              console.error(e);
            }
          }

          // Calculate custom's duty
          const customsDuty = this.fcfService.customsDuty({...item, shippingEur: shippingEur}) ?? 0;

          // Calculate importVAT
          const importVat = this.fcfService.importVat({...item, shippingEur: shippingEur}) ?? 0;

          // Only calculate profit and ROI if priceEstExVat is set
          const priceEurExVat = this.currency.convert(this.currency.gbp,this.currency.eur,Number(item.priceGBP_exvat));
          const vrtEuro = Number(item.vrtEuro);
          let profit: number | null = null;
          let totalCosts: number | null = null;
          let totalLandedCost: number | null = null;
          let roi: number | null = null;

          if (priceEurExVat !== null && item.vrtEuro !== null) {
            if (item.vat !== null) {
              totalCosts =
                priceEurExVat +
                vrtEuro +
                customsDuty +
                shippingEur +
                item.vat +
                importationFee +
                houseMiscCharge;
            }
            if (importVat !== null) {
              totalLandedCost =
                priceEurExVat +
                vrtEuro +
                customsDuty +
                shippingEur +
                importVat +
                importationFee +
                houseMiscCharge;
            }
          }
          if (item.priceEst !== null && totalCosts !== null) {
            profit = Number(item.priceEst) - totalCosts;
          }
          if (profit !== null && totalCosts !== null) {
            roi = profit / totalCosts;
          }

          // Return a new object containing the original item properties along with updated and formatted values
          return {
            ...item, // Spread other existing item properties
            vatAdjustment: importationFee, // Format VAT adjustment as a string with two decimal places
            totalCosts: totalCosts?.toString() ?? item.totalCosts, // Format total costs
            totalLandedCost: totalLandedCost, // Format total landed costs
            profit: profit?.toString() ?? null, // Format profit
            roi: roi?.toString() ?? null, // Format ROI as a percentage
            houseMiscCharge: Math.round(houseMiscCharge), // Format house/misc charge
            shippingEur: String(shippingEur), // Format shipping cost for display
            importVat: importVat,
            customsDuty: customsDuty,
            customsRateAboutToChange: FcfService.customsRateAboutToChange(item)
          };


        });

      // Update the total count of data items retrieved
      this.total = this.fcfService.total;
    } catch (error: unknown) {
      // Handle errors; assert the error type to provide meaningful feedback
      if (error instanceof Error) {
        this.errorMsg = `Failed to fetch data: ${error.message}`;
      } else {
        this.errorMsg = "Failed to fetch data: An unknown error occurred";
      }
      console.error(error); // Log error details for debugging
    } finally {
      // Reset loading state to false regardless of whether the fetch was successful or not
      this.loading = false;
    }
  }
  get partialCostingsToggled(): boolean {
    return !(
      this.filtersFormGroup.get("showNoCosting")?.value ||
      this.filtersFormGroup.get("showNoIREPrice")?.value
    );
  }

  async toggleCosting($event: MatSlideToggleChange) {
    if (this.filtersFormGroup) {
      this.filtersFormGroup.get("showNoCosting")?.setValue(!$event.checked);
      this.filtersFormGroup.get("showNoIREPrice")?.setValue(!$event.checked);
      await this.getData(this.topPaginator?.pageSize, 1);
    }
  }

  protected readonly Messages = Messages;


  /**
   * Adjust filters as required
   * We need to add house charge and importation fee to total landed cost and total cost
   * @private
   */
  private getAdjustedFilters(): any {
    const filters = Object.assign({},this.filtersFormGroup.value);

    // Check if we have any general values set
    const generalImportationFee = this.additionalCostService.importFee();
    const generalHouseMiscCharge = this.additionalCostService.houseMiscCharge();
    let generalShippingEur = this.additionalCostService.shipping();
    // If there's no general additional costs, so we do not need to adjust
    if (generalImportationFee === 0 && generalHouseMiscCharge === 0 && generalShippingEur === FLAT_SHIPPING_FEE) {
      return filters;
    }
    generalShippingEur -= FLAT_SHIPPING_FEE; // Remove the flat fee, as it's already accounted for

    // console.log('generalImportationFee: ', generalImportationFee);
    // console.log('generalHouseMiscCharge: ', generalHouseMiscCharge);
    // console.log('generalShippingEur: ', generalShippingEur);
    if (filters.totalLandedCostMin > 0) {
      filters.totalLandedCostMin -= generalImportationFee + generalHouseMiscCharge + generalShippingEur;
      if (filters.totalLandedCostMin < 0) {
        // Prevent neg value
        filters.totalLandedCostMin = 0;
      }
    }
    if (filters.totalLandedCostMax != FcfService.maxCost) {
      filters.totalLandedCostMax -= generalImportationFee + generalHouseMiscCharge + generalShippingEur;
    }
    if (filters.totalCostsMin > 0) {
      filters.totalCostsMin -= generalImportationFee + generalHouseMiscCharge + generalShippingEur;
      if (filters.totalCostsMin < 0) {
        // Prevent neg value
        filters.totalCostsMin = 0;
      }
    }
    if (filters.totalCostsMax != FcfService.maxCost) {
      filters.totalCostsMax -= generalImportationFee + generalHouseMiscCharge + generalShippingEur;
    }
    if (filters.profitMin > 0) {
      filters.profitMin += generalImportationFee + generalHouseMiscCharge + generalShippingEur;
    }
    if (filters.profitMax != FcfService.maxProfit) {
      filters.profitMax += generalImportationFee + generalHouseMiscCharge + generalShippingEur;
    }
    // console.log('filters: ', filters);
    return filters;
  }
}
