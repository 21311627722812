import { Injectable } from "@angular/core";
import {QuoteDetailsDialog} from "../components/dialogs/quote-details-dialog/quote-details-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {ApiService} from "./api.service";
import {Totals, VehicleData, VehicleDetails} from "../pages/home/ccq/ccq.model.consts";
import {CurrencyService} from "./currency.service";
import {vatRateUK} from "./locale.service";
export interface Quote {
  id: string;
  ts: string;
  vehicle_id: string;
  statCode: string;
  mileage: string;
  priceGBP: string;
  priceEUR: string;
  currencyRate: string;
  total: string;
  nox: string;
  customs_cost: string;
  customs_rate: string;
  vat: string;
  vrtEuro: string;
  transport_cost: string;
  transport_distance: string;
  transport_origin: string;
  transport_destination: string;
  make: string;
  model: string;
  version: string;
  status: string;
  user: string;
}

/**
 * Shipping fee in EUR
 */
export const FLAT_SHIPPING_FEE: number =  600; // Default to 600 EUR flat fee
@Injectable({
  providedIn: "root"
})
export class QuotesService {

  constructor(private dialog: MatDialog, private apiService: ApiService, private currency: CurrencyService) { }
  openDialog(element: Quote) {
    this.dialog.open(QuoteDetailsDialog,{
      data: element
    });
  }
  async setQuoteStatus(value: string, element: Quote) {
    await this.apiService.setQuoteStatus(element.id, value);
  }

  /**
   * Calculate totals for quote breakdown
   * @param vehicleData
   */
  public totals(vehicleData: VehicleData): Totals {
    const { vrtDetails, vatDetails, customsDetails, transportDetails } = vehicleData;
    const priceEur = this.priceEur(vehicleData.vehicleDetails);
    const lessVat = priceEur !== undefined ? -(priceEur - priceEur / (1 + vatRateUK)) : undefined;
    const vrtEuro = Number(vrtDetails?.vrtEuro ?? 0);
    const vat = Number(vatDetails?.vat ?? 0);
    const transport = Number(transportDetails?.cost ?? FLAT_SHIPPING_FEE);
    const customs = Number(customsDetails?.cost ?? 0);
    return {
      priceEur: priceEur,
      lessVat: lessVat, // UK VAT rate is 20 %
      vrtEuro: vrtEuro === 0 ? undefined : vrtEuro,
      customsDetails: customs,
      vat: vat === 0 ? undefined : vat,
      transportDetails: transport === 0 ? undefined : transport,
      total: vrtEuro + vat + customs + (priceEur ?? 0) + (lessVat ?? 0) + transport,
    };
  }

  private priceEur(vehicleDetails: VehicleDetails): number | undefined {
    if (vehicleDetails?.priceEur) {
      return Number(vehicleDetails.priceEur);
    } else if (vehicleDetails?.priceGBP) {
      return this.currency.convert(
        this.currency.gbp,
        this.currency.eur,
        Number(vehicleDetails?.priceGBP)
      );
    }
    return undefined;
  }
}
