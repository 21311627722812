import {Component, Inject} from "@angular/core";
import {FormControl, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {MatButton} from "@angular/material/button";
import {
    MAT_DIALOG_DATA, MatDialog, MatDialogActions, MatDialogContent, MatDialogRef, MatDialogTitle,
} from "@angular/material/dialog";
import {MatFormField, MatHint, MatLabel} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {VRT} from "../../../services/fcf.service";
import {AdditionalCostsService} from "../../../services/additional-costs.service";

@Component({
    selector: "app-fcf-edit-salesprice",
    standalone: true,
    imports: [FormsModule, MatButton, MatDialogActions, MatDialogContent, MatDialogTitle, MatFormField, MatInput, MatLabel, MatHint, ReactiveFormsModule],
    templateUrl: "./fcf-edit-salesprice.component.html",
    styleUrl: "./fcf-edit-salesprice.component.scss",
})
export class FcfEditSalespriceComponent {
    priceEstControl: FormControl;

    constructor(protected dialogRef: MatDialogRef<FcfEditSalespriceComponent>, private dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: VRT, protected additionaCostService: AdditionalCostsService) {
        this.priceEstControl = new FormControl(data.priceEst ?? 0, [Validators.required, Validators.min(0)]);
    }

    reset() {
        this.additionaCostService.removeKey(`priceEst_${this.data.id}`); // MAke sure we remove from local storage
        if (this.data.priceEst_orig) {
            // We have the original priceEst
            this.data.priceEst = this.data.priceEst_orig;
            this.priceEstControl.reset(Number(this.data.priceEst_orig));
            delete this.data.priceEst_orig;
            this.dialogRef.close(true); // We need to refresh the data to get the original priceEst
            setTimeout(() => {
                this.dialog.closeAll(); // Close all dialogs to show the refreshed data in the table
            }, 100);

        } else if (this.priceEstControl.dirty) {
            // We have unsaved changes to the controller
            this.priceEstControl.reset(this.data.priceEst);
        }
    }
}
