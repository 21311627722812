import {Component, ComponentRef, Inject, Injectable} from "@angular/core";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {LOCAL_STORAGE, StorageService} from "ngx-webstorage-service";
import {FcfService, VRT} from "./fcf.service";
import {
  ImportationFeeDialog
} from "../components/dialogs/vat-adjustment-dialog/importation-fee-dialog.component";
import {ComponentType} from "@angular/cdk/overlay";
import {Observable} from "rxjs";
import { FLAT_SHIPPING_FEE } from "./quotes.service";

@Injectable({
  providedIn: "root"
})
export class AdditionalCostsService {

  constructor(@Inject(LOCAL_STORAGE) private storage: StorageService, private dialog: MatDialog, private fcfService: FcfService) {
  }

  /**
   * Saves a given value for a specific key
   * Closes the dialog reference upon saving.
   *
   * @param {string} key - The unique key to associate with the value being saved.
   * @param {any} value - The value to be saved in storage.
   * @param {MatDialogRef<any>} dialogRef - The reference to the dialog, which will be closed after saving.
   * @return {void}
   */
  public saveForKey(key: string, value: number, dialogRef?: MatDialogRef<any>): void {
    this.storage.set(`${key}`, value);
    if (dialogRef) {
      dialogRef.close(value);
    }
  }

  public saveForAll(key: string, value: number, dialogRef?: MatDialogRef<any>): void {
    const keyGeneral = `${key}_general`;
    return this.saveForKey(keyGeneral, value, dialogRef);
  }

  /**
   * Saves a value associated with a specific vehicle into storage and optionally closes a dialog.
   *
   * @param {string} key - The key associated with the vehicle.
   * @param {number|string} id - The identifier for the specific vehicle.
   * @param {*} value - The value to be stored for the specified vehicle.
   * @param {MatDialogRef<any>} [dialogRef] - Optional dialog reference to close after saving.
   * @return {void} This method does not return a value.
   */
  saveForSpecificVehicle(key: string, id: number | string, value: number, dialogRef?: MatDialogRef<any>): void {
    const keyWithId = `${key}_${id}`;
    return this.saveForKey(keyWithId, value, dialogRef);
  }

  /**
   * Saves a value associated with a specific make and model into the storage.
   * Optionally, it can close the provided dialog reference after saving the value.
   *
   * @param {string} key - The base key to identify the storage entry.
   * @param {string} make - The make of the vehicle to be specified.
   * @param {string} model - The model of the vehicle to be specified.
   * @param {any} value - The value to be saved in the storage.
   * @param {MatDialogRef<any>} [dialogRef] - Optional dialog reference to be closed after saving.
   * @return {void} This method does not return any value.
   */
  saveForSpecificMakeAndModel(key: string, make: string, model: string, value: number, dialogRef?: MatDialogRef<any>): void {
    this.storage.set(`${key}_${make}_${model}`, value);
    if (dialogRef) {
      dialogRef.close(value);
    }
  }
  /**
   * Opens an edit dialog with the specified component and populates it with the provided data.
   * Subscribes to the dialog's close event to handle any actions upon closure.
   *
   * @param {VRT} data - The data to be passed into the dialog component.
   * @param {ComponentType<any>} dialogComponent - The component to use as the dialog.
   * @param returnCloseObservable - Return the close observable instead of handling it
   * @return {void} Does not return any value.
   */
  openEditDialog(data: VRT, dialogComponent: ComponentType<any>, returnCloseObservable: boolean = false): void | Observable<any> {
    // Open the VatAdjustmentDialogComponent dialog
    const dialogRef = this.dialog.open(dialogComponent, {
      data: data
    });

    if (returnCloseObservable) {
      return dialogRef.afterClosed();
    }
    // Handle the dialog close event
    dialogRef.afterClosed().subscribe(result => {
      if (result !== false) {
        // Trigger data refresh in FcfHomeComponent
        this.fcfService.triggerDataRefresh();
        this.dialog.closeAll(); // Close all dialogs to show the refreshed data in the table
      }
    });

  }

  /**
   * Removes a specified key from storage.
   *
   * @param {string} rmKey - The key to be removed from the storage.
   * @return {void} This method does not return a value.
   */
  removeKey(rmKey: string): void {
    this.storage.remove(rmKey);
  }
  /**
   * Calculates the import fee for a vehicle based on its price and optional VAT adjustments.
   *
   * @param {number} [id] - The unique identifier for the vehicle, used to determine specific import fee adjustments.
   * @param {string|number|null} [vehiclePriceEurExVat] - The price of the vehicle in Euros excluding VAT. Defaults to 0 if not provided.
   * @return {number} The calculated import fee based on the provided parameters and applicable adjustments.
   */
  public importFee(id?: number, vehiclePriceEurExVat?: string | number | null): number {
    const priceEurExVat = Number(vehiclePriceEurExVat ?? 0); // Est. Purchase price UK
    // Get the generalimportation fee and percentage from localStorage
    const generalImportationFee: number = Number(this.storage.get("vatAdjustment_general") || 0);
    const generalImportationPercentage: number = Number(
      this.storage.get("vatAdjustment_general_percentage") || 0
    );

    let individualVatAdjustment = Number(this.storage.get(`vatAdjustment_${id}`));
    // Determine individual import fee as percentage for the item
    let individualVatPercentage = Number(this.storage.get(`vatAdjustment_${id}_percentage`));
    if (!individualVatAdjustment && !individualVatPercentage) {
      // We can apply the general import fees
      individualVatAdjustment = generalImportationFee;
      individualVatPercentage = generalImportationPercentage;
    }

    // Use percentage-based VAT adjustment if applicable
    if (individualVatPercentage > 0) {
      return (individualVatPercentage / 100) * priceEurExVat
    }
    return individualVatAdjustment;
  }

  /**
   * Retrieves the house miscellaneous charge for a specific item or falls back to a general charge if not specified.
   * The charge is fetched based on a priority hierarchy, checking specific parameters (id, make, and model)
   * before defaulting to a general value.
   *
   * @param {number} [id] - The unique identifier of the item to retrieve the charge for.
   * @param {string} [make] - The make of the item to retrieve the charge for.
   * @param {string} [model] - The model of the item to retrieve the charge for.
   * @return {number} The resolved house miscellaneous charge for the specified parameters or the general default value.
   */
  public houseMiscCharge(id?: number, make?: string, model?: string): number {
    // Get the general house miscellaneous charge value from localStorage; default to 0 if not found
    const generalHouseMiscCharge = Number(this.storage.get("houseMiscCharge_general") || 0);
    // Retrieve house/misc charge for the item or use the general charge as a fallback
    return Number(
      this.storage.get(`houseMiscCharge_${id}`) ??
      this.storage.get(`houseMiscCharge_${make}_${model}`) ??
      generalHouseMiscCharge
    );
  }
  /**
   * Calculates the shipping cost based on various parameters, including specific item IDs, make and model,
   * and default or general shipping costs. If none of the parameters are provided, a flat shipping fee is used.
   *
   * @param {number} [id] - Optional unique identifier for the item used to fetch specific shipping cost.
   * @param {string|number|null} [shippingEur] - An optional manual shipping cost in Euros.
   * @param {string} [make] - The make of the item, used as part of retrieving a specific shipping cost.
   * @param {string} [model] - The model of the item, used as part of retrieving a specific shipping cost.
   * @return {number} - The calculated shipping cost as a number.
   */
  public shipping(id?: number, shippingEur?: string | number | null, make?: string, model?: string): number {
    // Retrieve general shipping cost
    const generalShippingCost = (() => {
      const value = this.storage.get("shippingCost_general");
      return value === undefined ? undefined : Number(value);
    })();
    // Retrieve shipping cost (specific or general)
    return Number(
      this.storage.get(`shippingCost_${id}`) ??
      this.storage.get(`shippingCost_${make}_${model}`) ??
      generalShippingCost ?? // General shipping cost
      shippingEur ??
      FLAT_SHIPPING_FEE
    );
  }
}
