<header>
<!--  Hide for now, as we're not focusing on UK -->
<!--  <img class="flag" [ngSrc]="flag()" width="40" height="40" [alt]="localeService.locale" (click)="localeService.setLocale(null)">-->
  <a routerLink="/"><img class="logo" ngSrc="/assets/thecarsite.svg" priority width="250" height="60"  alt="TheCarSite"></a>
<!--  <h1 [routerLink]="userService.isLoggedIn() ? '/home' : '/'">TheCarSite</h1>-->
  <app-nav-hamburger></app-nav-hamburger>
  <nav class="miniMenu" role="menu">
    @if (userService.isLoggedIn()) {
      <a mat-raised-button role="menuitem" routerLink="/home/account">Account</a>
      <a mat-raised-button role="menuitem"  routerLink="/contact">Contact Us</a>
      <a mat-raised-button role="menuitem" (click)="userService.logout()">Log out</a>
    } @else {
      @if (!nav.hidden) {
        <a mat-raised-button role="menuitem" routerLink="/about">About us</a>
        <a mat-raised-button role="menuitem" routerLink="/register">Sign up</a>
      }
      <a mat-raised-button role="menuitem" routerLink="/login">Login</a>
    }
  </nav>
<!--  <div class="subtitle">-->
<!--    <h2>-->
<!--      <ul>-->
<!--        <li>Save Time.</li>-->
<!--        <li>Cut Costs.</li>-->
<!--        <li>Grow Profits.</li>-->
<!--        <li>Sell Cars Faster.</li>-->
<!--      </ul>-->
<!--    </h2>-->
<!--    <h3>TheCarSite is Your One Stop Shop.</h3>-->
<!--  </div>-->
</header>
<header class="print-only">
  <img class="logo" ngSrc="/assets/thecarsite.svg" priority width="500" height="120" alt="TheCarSite">
</header>
<app-nav></app-nav>
