<h3 mat-dialog-title>
  @if (data.mode === "fcf") {
  Filter your table
  } @else {
  Choose filters for your notification
  }
</h3>
<mat-dialog-content>
  <div class="filtersContainer" [formGroup]="data.filtersFormGroup">
    <input type="hidden" formControlName="id" />
    <mat-form-field>
      <mat-label>Seller Types</mat-label>
      <mat-select formControlName="sellerTypes" multiple>
        <!-- <mat-option value="Private">Private</mat-option> -->

        <mat-option value="Trade-sites">Trade - sites</mat-option>
        <mat-option value="Trade-auctions">Trade - auctions</mat-option>

        <!-- <mat-option value="Other">Other</mat-option> -->
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Body Types</mat-label>
      <mat-select formControlName="bodyTypes" multiple>
        @for (bodyType of bodyTypeOptions; track bodyType.i) {
        <mat-option [value]="bodyType.i">{{ bodyType.key }}</mat-option>
        }
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Makes
        <mat-progress-spinner *ngIf="fcfService.loadingFacets" mode="indeterminate"
          [diameter]="15"></mat-progress-spinner>
      </mat-label>
      <mat-select multiple formControlName="makes" #makesFilter [disabled]="fcfService.loadingFacets">
        <mat-option (click)="toggleAllMakes($event)" [value]="null">Select All</mat-option>
        @for (make of fcfService.makes | keyvalue; track make.key) {
        <mat-option [value]="make.key">{{ make.key }}</mat-option>
        }
      </mat-select>
    </mat-form-field>
    <mat-form-field class="double">
      <mat-label>Models
        <mat-progress-spinner *ngIf="fcfService.loadingFacets" mode="indeterminate"
          [diameter]="15"></mat-progress-spinner>
      </mat-label>
      <mat-select multiple formControlName="models">
        <mat-option (click)="toggleAllModels($event)" [value]="null">Select All</mat-option>
        @for (make of makesFilter.value; track make) {
        @for (model of fcfService.makes[make]; track model) {
        <mat-option [value]="model">{{ model }}</mat-option>
        }
        }
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Fuel Types</mat-label>
      <mat-select formControlName="engineTypes" multiple>
        <mat-option [value]="1">Petrol</mat-option>
        <mat-option [value]="2">Diesel</mat-option>
        <mat-option [value]="3">Electric</mat-option>
        <!--      <mat-option [value]="8">Mild Hybrid Petrol</mat-option>-->
        <mat-option [value]="16">Hybrid Petrol</mat-option>
        <!--      <mat-option [value]="10">Mild Hybrid diesel</mat-option>-->
        <mat-option [value]="15">Hybrid Diesel</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Transmission Types</mat-label>
      <mat-select formControlName="transmissionTypes" multiple>
        <mat-option [value]="1">Manual</mat-option>
        <mat-option [value]="2">Automatic</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Doors</mat-label>
      <mat-select formControlName="numberOfDoors" multiple>
        @for (i of [2, 3, 4, 5, 6, 7]; track i) {
        <mat-option [value]="i">{{ i }}</mat-option>
        }
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Colour</mat-label>
      <mat-select formControlName="colours" multiple>
        @for (colour of colourOptions; track colour) {
        <mat-option [value]="colour">{{ colour }}</mat-option>
        }
      </mat-select>
    </mat-form-field>
    <mat-form-field class="double">
      <mat-label>Keyword Search</mat-label>
      <input type="text" matInput formControlName="keyword" />
    </mat-form-field>

    <div class="formField triple">
      <mat-label>Purchase price in GBP</mat-label>
      <mat-slider min="0" [max]="FcfService.maxCost" step="500" discrete [displayWith]="formatMaxCostGBP">
        <input matSliderStartThumb formControlName="purchasePriceMin" />
        <input matSliderEndThumb formControlName="purchasePriceMax" />
      </mat-slider>
    </div>
    <div class="formField triple">
      <mat-label>Total Landed Cost of Car</mat-label>
      <mat-slider min="0" [max]="FcfService.maxCost" step="500" discrete [displayWith]="formatMaxCostEUR">
        <input matSliderStartThumb formControlName="totalLandedCostMin" />
        <input matSliderEndThumb formControlName="totalLandedCostMax" />
      </mat-slider>
    </div>
    <div class="formField triple">
      <mat-label>Total Cost inc. VAT at Sale</mat-label>
      <mat-slider min="0" [max]="FcfService.maxCost" step="500" discrete [displayWith]="formatMaxCostEUR">
        <input matSliderStartThumb formControlName="totalCostMin" />
        <input matSliderEndThumb formControlName="totalCostMax" />
      </mat-slider>
    </div>
    <div class="formField triple">
      <mat-label>Year</mat-label>
      <mat-slider [min]="FcfService.minYear" [max]="FcfService.maxYear" step="1" discrete [displayWith]="formatYear">
        <input matSliderStartThumb formControlName="yearMin" />
        <input matSliderEndThumb formControlName="yearMax" />
      </mat-slider>
    </div>
    <div class="formField triple">
      <mat-label>Mileage</mat-label>
      <mat-slider min="0" [max]="FcfService.maxKms" step="500" discrete [displayWith]="formatKms">
        <input matSliderStartThumb formControlName="kmsMin" />
        <input matSliderEndThumb formControlName="kmsMax" />
      </mat-slider>
    </div>
    <div class="formField triple">
      <mat-label>Engine Size</mat-label>
      <mat-slider min="0" [max]="FcfService.maxEngineSize" step="200" discrete [displayWith]="formatEngineSize">
        <input matSliderStartThumb formControlName="engineSizeMin" />
        <input matSliderEndThumb formControlName="engineSizeMax" />
      </mat-slider>
    </div>
    <div class="formField triple">
      <mat-label>Estimated Profits</mat-label>
      <mat-slider [min]="0" [max]="FcfService.maxProfit" step="100" discrete [displayWith]="formatProfit">
        <input matSliderStartThumb formControlName="profitMin" />
        <input matSliderEndThumb formControlName="profitMax" />
      </mat-slider>
    </div>
    <div class="formField triple">
      <mat-label>Return on Investment</mat-label>
      <mat-slider min="0" [max]="FcfService.maxRoi" step="0.05" discrete [displayWith]="formatPercent">
        <input matSliderStartThumb formControlName="roiMin" />
        <input matSliderEndThumb formControlName="roiMax" />
      </mat-slider>
    </div>

    <div class="formField triple">
      <mat-label>CO<sub>2</sub>-Emissions (g/km)</mat-label>
      <mat-slider min="0" [max]="FcfService.maxCo2" step="20" discrete [displayWith]="formatGPerKm">
        <input matSliderStartThumb formControlName="co2Min" />
        <input matSliderEndThumb formControlName="co2Max" />
      </mat-slider>
    </div>
    <mat-slide-toggle class="triple" formControlName="showNoIREPrice">Include Cars With All Costs and Some with No Est. Sales Price IE</mat-slide-toggle>
    <mat-slide-toggle class="triple" formControlName="showNoCosting">Include Cars With No Costings</mat-slide-toggle>
    <mat-slide-toggle class="triple" formControlName="insuranceWriteOff">Include Cars Written Off by Insurance
      (Categories C/D/S/N)</mat-slide-toggle>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-raised-button (click)="close()">Close</button>
  @if (data.mode === "fcf") {
  <button mat-raised-button (click)="resetFilters()" [disabled]="!data.filtersFormGroup.dirty">
    Reset
  </button>
  <span class="spacer"></span>
  <button mat-raised-button (click)="createNotification()" [disabled]="!data.filtersFormGroup.dirty"
    matTooltip="Receive automated e-mail notification about new vehicles matching the filters you've set.">
    <mat-icon>add_alert</mat-icon>
    Create alert
  </button>
  <button mat-raised-button (click)="saveSearch()" [disabled]="!data.filtersFormGroup.dirty">
    Save Search
  </button>
  <button mat-raised-button (click)="openLoadSearchDialog()">
    Load Search
  </button>
  <button mat-raised-button (click)="openSearchHistoryDialog()">
    Search History
  </button>
  <span class="spacer"></span>
  <button mat-raised-button color="primary" (click)="applyFilters()" [disabled]="!data.filtersFormGroup.dirty">
    Apply
  </button>
  } @else if (data.mode === "notifications") {
  <span class="spacer"></span>
  <button mat-raised-button color="primary" (click)="createNotification()" [disabled]="!data.filtersFormGroup.dirty">
    Save
  </button>
  }
</mat-dialog-actions>
