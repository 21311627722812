import {Component, Inject} from "@angular/core";
import {FormControl, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {MatButton} from "@angular/material/button";
import {
  MAT_DIALOG_DATA, MatDialogActions, MatDialogContent, MatDialogRef, MatDialogTitle,
} from "@angular/material/dialog";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {VRT} from "../../../services/fcf.service";
import {AdditionalCostsService} from "../../../services/additional-costs.service";
import {Messages} from "../../../messages";

@Component({
  selector: "app-house-misc-charge-dialog",
  standalone: true,
  imports: [MatButton, MatDialogContent, MatDialogActions, MatDialogTitle, MatFormFieldModule, MatInputModule, FormsModule, ReactiveFormsModule,],
  templateUrl: "./house-misc-charge-dialog.component.html",
  styleUrls: ["./house-misc-charge-dialog.component.scss"],
})
export class HouseMiscChargeDialogComponent {
  houseMiscChargeControl: FormControl;

  constructor(protected dialogRef: MatDialogRef<HouseMiscChargeDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: VRT, protected additionaCostService: AdditionalCostsService) {
    this.houseMiscChargeControl = new FormControl(data.houseMiscCharge ?? 0, [Validators.required, Validators.min(0)]);
  }

  protected readonly Messages = Messages;
}
