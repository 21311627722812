import { Inject, Injectable } from "@angular/core";
import { LOCAL_STORAGE, StorageService } from "ngx-webstorage-service";

@Injectable({
  providedIn: "root",
})
export class SearchHistoryService {
  constructor(@Inject(LOCAL_STORAGE) private storage: StorageService) {}

  /**
   * Saves a set of search filters into storage with a unique timestamp key.
   *
   * @param {any} filters - The search filters to be saved.
   * @param {string} key - The key identifier for the saved searches in storage.
   * @return {void} Does not return any value.
   */
  saveSearch(filters: any, key: string): void {
    const timestamp = Date.now(); // Unique key
    const savedSearches = this.storage.get(key) || {};
    savedSearches[timestamp] = filters; // Save filters with timestamp as key
    this.storage.set(key, savedSearches); // Update storage
  }

  /**
   * Retrieves the saved searches associated with the specified key from storage.
   *
   * @param {any} key - The key used to identify the saved searches in the storage.
   * @return {any} The saved searches associated with the given key, or an empty object if no data is found.
   */
  getSavedSearches(key: any): any {
    return this.storage.get(key) || {};
  }

  /**
   * Deletes a search entry from the storage based on the provided timestamp key and storage key.
   *
   * @param {number} timestamp_key - The unique timestamp identifier for the search entry to be deleted.
   * @param {string} key - The key used to retrieve the stored searches from the storage.
   * @return {void} This method does not return a value.
   */
  deleteSearch(timestamp_key: number, key: string): void {
    const savedSearches = this.storage.get(key) || {};
    if (timestamp_key in savedSearches) {
      delete savedSearches[timestamp_key];
      this.storage.set(key, savedSearches);
    }
  }

  /**
   * Loads a specific search entry from the storage based on the provided timestamp key and search key.
   *
   * @param {number} timestamp_key - The unique identifier for the specific search entry, typically represented as a timestamp.
   * @param {string} key - The key used to access the storage location containing the saved searches.
   * @return {any} The search entry corresponding to the given timestamp key. Returns `undefined` if no entry is found.
   */
  loadSearch(timestamp_key: number, key: string): any {
    const savedSearches = this.storage.get(key) || {};
    return savedSearches[timestamp_key];
  }
}
