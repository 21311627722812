import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'bodyType', standalone: true
})
export class BodyTypePipe implements PipeTransform {

  static lookup(value: string | number) {
    switch (Number(value)) {
      case 1:
        return "Saloon";
      case 6:
      case 60:
      case 56:
        return "Van";
      case 37:
        return "Other";
      case 40:
        return "Hatchback";
      case 41:
        return "Convertible";
      case 42:
        return "Sports coupe (non convertible)";
      case 48:
        return "Pick up";
      case 58:
        return "Drop-side lorry";
      case 77:
      case 2:
        return "Estate";
      case 92:
        return "MPV";
      default:
        return 'Unknown';
    }
  }

  transform(value: string | number): string {
    return BodyTypePipe.lookup(value);
  }

}
