<section class="header">
  <h1>We Help You Find Your Ideal Next Purchase.</h1>
  <!--  <h2>We Got You.</h2>-->
</section>
<section class="pageContent">
  <!--  @if (!init) {-->
  <!--    <app-prod-details singleProduct="fcf" [showPriceTag]="false" (load)="load()"></app-prod-details>-->
  <!--  } @else {-->

  <h3>List of vehicles currently on the market</h3>

  <div class="fcfContainer">
    <mat-toolbar>
      <mat-toolbar-row>
        <span class="spacer"></span>
        <mat-slide-toggle #showKm
                          [checked]="preferKm"
                          (change)="onKmToggleChange($event.checked)">
          Show mileage in km
        </mat-slide-toggle>
        <mat-slide-toggle (change)="toggleCosting($event)" [checked]="partialCostingsToggled"
        >Only show cars with complete data
        </mat-slide-toggle>

        <button mat-raised-button color="primary" (click)="openFilterDialog()">
          Toggle Filters
          <mat-icon>tune</mat-icon>
        </button>

        <button mat-raised-button (click)="openColumnVisibilityDialog()">
          <mat-icon>table_chart</mat-icon>
          Toggle Columns
        </button>


        <mat-paginator
          #topPaginator
          [pageSize]="topPaginator?.pageSize ?? 20"
          [pageSizeOptions]="[20, 50, 100, 250, 500]"
          [length]="total"
          aria-label="Select page"
          (page)="handlePageEvent($event)"
          showFirstLastButtons
          [class.disabled]="loading">
        </mat-paginator>
      </mat-toolbar-row>
    </mat-toolbar>
    <mat-progress-spinner *ngIf="loading " class="table-spinner" mode="indeterminate"
                          [diameter]="50"></mat-progress-spinner>
    <table mat-table
           [dataSource]="vrt"
           matSort
           (matSortChange)="announceSortChange($event)"
           [class.disabled]="loading"
           cdkDropList
           cdkDropListOrientation="horizontal"
           (cdkDropListDropped)="handleColumnDrop($event)"
    >

      <!-- Updated columns with cdkDrag -->
      <ng-container matColumnDef="make">
        <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag>Make and Model</th>
        <td mat-cell *matCellDef="let element">
          <div>
            <span>{{ element.make }} {{ element.model }}</span>
            <br/>
            <small class="version-data" matTooltip="{{ element.versionData }}">
              {{
                element.versionData?.substring(0, 100)
              }}{{ element.versionData?.length > 100 ? "..." : "" }}
            </small>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="yearOfRegistration">
        <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag>Year</th>
        <td mat-cell *matCellDef="let element">{{ element.yearOfRegistration }}</td>
      </ng-container>
      <ng-container matColumnDef="kms">
        <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag>Mileage</th>
        <td mat-cell *matCellDef="let element">
          @if (showKm.checked) {
            {{ element.kms | number: "1.0-0" }} km
          } @else {
            {{ element.mileage | number: "1.0-0" }} mi.
          }
        </td>
      </ng-container>
      <ng-container matColumnDef="engineType">
        <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag>Engine Type</th>
        <td mat-cell *matCellDef="let element">
          {{ (element | formatRes)["Engine type / fuel"] }}
        </td>
      </ng-container>
      <ng-container matColumnDef="priceGBP">
        <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag
            matTooltip="The UK purchase price including VAT, displayed in GBP.">
          Est. Purchase Price UK -Incl. VAT
          <mat-icon
            class="info-icon"
          >info
          </mat-icon
          >
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.priceGBP | currency: "GBP" : "symbol" : "1.0-0" : "en" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="priceEurExVat">
        <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag [matTooltip]="
              'Estimate Purchase Price based on Advertised Price or Clean Cap excluding VAT. Currency Conversion Rate EUR/GBP ' +
              (currency.rates['gbp'] | number: '1.3-3' : 'en')
            ">
          Est. Purchase Price UK - Net VAT
          <mat-icon
            class="info-icon"
          >info
          </mat-icon
          >
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.priceEurExVat | currency: "EUR" : "symbol" : "1.0-0" : "en" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="vatAdjustment">
        <th mat-header-cell *matHeaderCellDef matTooltip="Fees for Brokering, Handling etc" cdkDrag>
          Importation Fee
          <mat-icon
            class="info-icon"
          >info
          </mat-icon
          >
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.vatAdjustment | currency: "EUR" : "symbol" : "1.0-0" : "en" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="priceEstExVat">
        <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag
            matTooltip="Estimate based on similar Make and Model, Version, Year and Mileage. Ex. VAT.">
          Est Sale Price IE net VAT
          <mat-icon
            class="info-icon"
          >info
          </mat-icon
          >
        </th>
        <td mat-cell *matCellDef="let element">
          @if (element.priceEstExVat) {
            {{ element.priceEstExVat | currency: "EUR" : "symbol" : "1.0-0" : "en" }}
            @if (element.priceEstDisclaimer) {
              <strong [matTooltip]="element.priceEstDisclaimer">*</strong>
            }
          }
        </td>
      </ng-container>
      <!-- New Est. Sale Price IE Column -->
      <ng-container matColumnDef="priceEst">
        <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag
            matTooltip="Estimate based on similar Make and Model, Version, Year and Mileage. Inc. VAT.">
          Est. Sale Price IE
          <mat-icon
            class="info-icon"
          >info
          </mat-icon
          >
        </th>
        <td mat-cell *matCellDef="let element">
          @if (element.priceEst) {
            {{ element.priceEst | currency: "EUR" : "symbol" : "1.0-0" : "en" }}
            @if (element.priceEstDisclaimer) {
              <strong [matTooltip]="element.priceEstDisclaimer">*</strong>
            }
          }
        </td>
      </ng-container>

      <ng-container matColumnDef="vrtEuro">
        <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag>VRT</th>
        <td mat-cell class="nowrap" *matCellDef="let element"
            [matTooltip]="element.vrtDisclaimer ? Messages.vrtDisclaimer : ''">
          {{ element.vrtEuro | currency: "EUR" : "symbol" : "1.0-0" : "en" }}
          @if (element.vrtDisclaimer) {
            <strong>*</strong>
          }
        </td>
      </ng-container>
      <ng-container matColumnDef="vat">
        <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag
            matTooltip="VAT here is calculated from the gross Est Sale Price IRE minus VRT divided by 1.23 multiplied by 23%">
          VAT at Sale
          <mat-icon
            class="info-icon"
          >info
          </mat-icon
          >
        </th>
        <td mat-cell *matCellDef="let element">
          @if (element.priceEstExVat) {
            {{ element.vat | currency: "EUR" : "symbol" : "1.0-0" : "en" }}
          }
        </td>
      </ng-container>
      <ng-container matColumnDef="vatImport">
      <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag
          matTooltip="VAT at Import is calculated on the basis of the Net Purchase Price in the UK, plus Shipping, plus Customs, then at 23%. It does not take account any additional VAT at sale. To review the potential VAT at sale and to see a projected P&L please include and review the following columns - ‘Est. Sale Price in IRE’, then ‘Total Cost inc. VAT at Sale’ and ‘Est Profit’.">
        VAT at Import
        <mat-icon
          class="info-icon"
        >info
        </mat-icon
        >
      </th>
      <td mat-cell *matCellDef="let element">
          {{ element.importVat | currency: "EUR" : "symbol" : "1.0-0" : "en" }}
      </td>
      </ng-container>
      <ng-container matColumnDef="customsDuty">
        <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag>Customs</th>
        <td mat-cell class="nowrap" *matCellDef="let element"
            [matTooltip]="element.customsDisclaimer ? Messages.customsDisclaimer : ''"
            [ngClass]="{ customsRateAboutToChange: element.customsRateAboutToChange }"
        >
          {{ element.customsDuty | currency: "EUR" : "symbol" : "1.0-0" : "en" }}
          @if (element.customsDisclaimer) {
            <strong>*</strong>
          }
          @if (element.customsRateAboutToChange) {
            <small class="version-data">UK reg {{ element.monthOfFirstRegistration | date: "yyyy"}}</small>
          }
        </td>
      </ng-container>
      <ng-container matColumnDef="shippingEur">
        <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag>Delivery</th>
        <td mat-cell *matCellDef="let element">
          {{ element.shippingEur | currency: "EUR" : "symbol" : "1.0-0" : "en" }}
        </td>
        <!-- <td mat-cell *matCellDef="let element">
          {{ element.shippingEur | currency: "EUR" : "symbol" : "1.0-0" : "en" }}
        </td> -->
      </ng-container>
      <ng-container matColumnDef="houseMiscCharge">
        <th mat-header-cell *matHeaderCellDef [matTooltip]="Messages.houseMiscCharge" cdkDrag>
          House Charge
          <mat-icon
            class="info-icon"
          >info
          </mat-icon
          >
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.houseMiscCharge | currency: "EUR" : "symbol" : "1.0-0" : "en" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="profit">
        <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag>Est. Profit</th>
        <td mat-cell *matCellDef="let element">
          {{ element.profit | currency: "EUR" : "symbol" : "1.0-0" : "en" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="totalCosts">
        <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag class="highlight-header">
          Total Cost inc. VAT at Sale
        </th>
        <td mat-cell *matCellDef="let element" class="highlight-cell">
          {{ element.totalCosts | currency: "EUR" : "symbol" : "1.0-0" : "en" }}
        </td>
      </ng-container>
     <ng-container matColumnDef="totalLandedCost">
        <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag class="highlight-header" [matTooltip]="Messages.totalLandedCost">
          Total Landed Cost
          <mat-icon class="info-icon">info</mat-icon>
        </th>
        <td mat-cell *matCellDef="let element" class="highlight-cell">
          {{ element.totalLandedCost | currency: "EUR" : "symbol" : "1.0-0" : "en" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="co2Charges">
        <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag>
          VRT CO2 charge
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.co2Charges | currency: "EUR" : "symbol" : "1.0-0" : "en" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="noxCharge">
        <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag>
          VRT NOx charge
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.noxCharge | currency: "EUR" : "symbol" : "1.0-0" : "en" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="colour">
        <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag>
          Colour
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.colour }}
        </td>
      </ng-container>

      <ng-container matColumnDef="roi">
        <th mat-header-cell *matHeaderCellDef mat-sort-header cdkDrag
            matTooltip="Return On Investment ie Est. Profit as a Percentage of Total Cost to Import">
          ROI
          <mat-icon

            class="info-icon"
          >info
          </mat-icon
          >
        </th>
        <td mat-cell *matCellDef="let element">{{ element.roi | percent: "1.0-0" : "en" }}</td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr
        mat-row
        *matRowDef="let element; columns: displayedColumns"
        (click)="openDialog(element)"
        class="clickable"
        [ngClass]="{ masked: element.masked }"></tr>
    </table>

    <mat-paginator
      #bottomPaginator
      [pageSize]="topPaginator?.pageSize ?? 20"
      [pageSizeOptions]="[20, 50, 100, 250, 500]"
      [length]="total"
      aria-label="Select page"
      (page)="handlePageEvent($event)"
      showFirstLastButtons
      [class.disabled]="loading">
    </mat-paginator>

    <p class="error" *ngIf="errorMsg">{{ errorMsg }}</p>
    <app-disclaimer></app-disclaimer>
  </div>
</section>
