import {CommonModule} from "@angular/common";
import {AfterViewInit, Component, Inject, OnInit, ViewChild} from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from "@angular/material/dialog";
import {MatButton, MatIconButton} from "@angular/material/button";
import {FcfService} from "../../../../services/fcf.service";
import {MatIcon} from "@angular/material/icon";
import {FcfFilterControlNameToLabelPipe} from "../../../../pipes/fcf-filter-control-name-to-label.pipe";
import {MatTableDataSource, MatTableModule} from "@angular/material/table";
import {MatTooltip} from "@angular/material/tooltip";
import {MatSort, MatSortHeader, MatSortModule} from "@angular/material/sort";
interface SearchItem {
  id: string; // The numeric timestamp as a string
  timestamp: number;
  specs: string;
}
@Component({
  selector: "app-load-search-dialog",
  templateUrl: "./load-search-dialog.component.html",
  standalone: true,
  imports: [
    MatDialogContent,
    MatDialogActions,
    CommonModule,
    MatButton,
    MatDialogTitle,
    MatIconButton,
    MatIcon,
    MatTableModule,
    MatTooltip,
    MatSortModule
  ],
  styleUrls: ["./load-search-dialog.component.scss"],
})
export class LoadSearchDialogComponent implements OnInit, AfterViewInit {
  title: string;
  displayedColumns: string[] = ["timestamp", "specs", "actions"];
  dataSource = new MatTableDataSource<any>([]);
  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      items: any;
      title: string;
    },
    public dialogRef: MatDialogRef<LoadSearchDialogComponent>
  ) {
    this.title = data.title;
  }

  ngOnInit() {
    if (this.data?.items) {
      this.dataSource.data = Object.keys(this.data.items).map(key => ({
        id: key,
        timestamp: Number(key),
        specs: this.searchParams(key),
      }));
      console.log(this.dataSource.data);
    }
  }
  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    // Optional: Custom sort function if needed for timestamp comparison
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch(property) {
        case 'timestamp': return item.timestamp;
        default: return item[property];
      }
    };
  }
  /**
   * Closes the dialog with a 'load' action and an associated key.
   *
   * @param {string} key - The key to identify the data or resource to be loaded.
   * @return {void} This method does not return a value.
   */
  load(key: string): void {
    this.dialogRef.close({ action: "load", key });
  }

  /**
   * Deletes a search entry by key and closes the dialog with the specified action and relevant information.
   *
   * @param {string} k - The key of the search to be deleted.
   * @return {void} This method does not return any value.
   */
  public deleteSearch(k: string): void {
    this.dialogRef.close({
      action: "delete",
      key: k,
      title: this.title,
    });
  }

  /**
   * Generates a string representation of the differences between default form control values
   * and the current search parameters associated with a specified key.
   *
   * @param {string} key - The key used to identify the search parameters in the data items.
   * @return {string} A formatted string reflecting the differences between the default values
   * and the search parameters. Returns an empty string if no differences are found or if the key is invalid.
   */
  searchParams(key: string): string {
    if (!key) return "";
    const defaultValues = FcfService.initForm();
    const search = this.data.items[key];

    const differences: string[] = Object.keys(defaultValues.controls)
      .filter(controlName => {
        const defaultValue = defaultValues.get(controlName)?.value;
        const searchValue = search[controlName];

        if (searchValue === undefined || searchValue === null || searchValue === "") {
          return false;
        }

        if (Array.isArray(defaultValue) && Array.isArray(searchValue)) {
          return JSON.stringify(defaultValue) !== JSON.stringify(searchValue);
        }

        return defaultValue !== searchValue;
      })
      .map(controlName => {
        const controlLabel = new FcfFilterControlNameToLabelPipe().transform(controlName);
        const searchValue = search[controlName];

        if (Array.isArray(searchValue)) {
          return `${controlLabel}: [${searchValue.join(", ")}]`;
        }

        return `${controlLabel}: ${searchValue}`;
      });

    return `${differences.join(", ")}`;
  }
}
