import {Injectable} from "@angular/core";
import {ApiService} from "./api.service";

export interface Currency {
  short: string;
  three: string;
  symbol: string;
}

@Injectable({
  providedIn: "root"
})
export class CurrencyService {
  public rates: any = {
    eur: 1, jpy: 161.174, gbp: 0.83, usd: 1.07, cny: 7.7, inr: 89.89, zar: 19.14, cad: 1.46, ts: null // e.g. 2025-03-19T11:59:59+00:00
  };
  public currencies: Currency[] = [{
    short: 'eur', three: 'EUR', symbol: '€'
  }, {
    short: 'gbp', three: 'GBP', symbol: '£'
  }, {
    short: 'cad', three: 'CAD', symbol: '$'
  }, {
    short: 'cny', three: 'CNY', symbol: '¥'
  }, {
    short: 'inr', three: 'INR', symbol: '₹'
  }, {
    short: 'jpy', three: 'JPY', symbol: '¥'
  }, {
    short: 'usd', three: 'USD', symbol: '$'
  }, {
    short: 'zar', three: 'ZAR', symbol: 'R'
  }];

  constructor(private apiService: ApiService) {
    this.getRates()
      .catch(e => console.error(e));
  }
  get eur(): Currency
  {
    return this.currencies[0];
  }
  get gbp(): Currency
  {
    return this.currencies[1];
  }

  /**
   * Return the rate for a currency against EUR
   * @param currency
   */
  public getRate(currency: Currency): number {
    return this.rates[currency.short] ?? 0;
  }
  /**
   * Converts an amount from one currency to another based on exchange rates.
   *
   * @param {Currency} from - The currency to convert from.
   * @param {Currency} to - The currency to convert to.
   * @param {number} amount - The amount to be converted.
   * @param {number} [precision] - Optional precision for rounding the result.
   * @return {number} - The converted amount in the target currency.
   */
  convert(from: Currency, to: Currency, amount: number, precision?: number): number {
    if (!this.rates[from.short]) {
      return 0;
    }

    let val = amount * (this.rates[to.short] / this.rates[from.short]);
    if (precision) {
      val = Math.round(amount * (this.rates[to.short] / this.rates[from.short]) * Math.pow(10, precision)) / Math.pow(10, precision);
    }
    return val;
  }

  /**
   * Fetches and updates the currency rates for the specified date.
   *
   * @param {Date} date The date for which to retrieve the currency rates. Defaults to the current date if not provided.
   * @return {Promise<void>} A promise that resolves once the rates are fetched and updated.
   */
  public async getRates(date: Date = new Date()) {
    const res = await this.apiService.getCurrencyRates(date)
      .catch(e => console.error(e));
    if (res?.success && res.data["rates"]) {
      this.rates = res.data["rates"];
    }
  }
}
